import { DevTool } from "@hookform/devtools";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { usersAPI } from "../../api/usersAPI";
import MainLoader from "../../components/MainLoader";
import ProfileImage from "../../components/ProfileImage";
import { roles } from "../../constants";
import { GlobalContext } from "../../context/Provider";
import { CustomDatePicker, CustomDropdown, CustomForm, CustomInput, CustomTextArea } from "../../devkit/Form/Form";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import { parseDateForMySQL } from "../../functions/utilFunctions/parsingFunctions";
import { validateEmail } from "../../functions/utilFunctions/validations";
import DetailsHeader from "../../layout/DetailsHeader";
import { IUser } from "../../models/interfaces/User";
import useModal from "../../services/CustomHooks/useModal";
import { StoreService } from "../../services/StoreService";
import biometricAPI from "../../api/biometricAPI";

type FormValues = {
    form: IUserDetailsForm;
    linked_users: { value: number; label: string }[];
};

type IUserDetailsForm = {
    first_name: string;
    last_name: string;
    birth_date: Date | null;
    phone: string;
    email: string;
    description: string;
    JMBG: string;
    address: string;
    city: string;
    created_at: Date | null;
    role: number;
    password: string;
    repeat_password: string;
    pin: string;
    is_deactivate?: number;
    is_online?: number;
};

const UserDetails = ({ myProfile }: { myProfile?: boolean }) => {
    const role = StoreService.getStoreProperty("role");
    const { hasPermission } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const { activeModal, toggleModal } = useModal();
    const { params } = useRouteMatch<{ id: string; type: string }>();
    const [loader, setLoader] = useState<boolean>(true);
    const [garminConnectData, setGarminConnectData] = useState<{ [key: string]: any }>({});
    const [userOptions, setUserOptions] = useState<{ value: number; label: string }[]>([]);
    const history = useHistory();
    const [isNew] = useState<boolean>(!params.id);
    const [tempProfileImg, setTempProfileImg] = useState<{ url: string; img: File | null }>({ url: "", img: null });
    const [profileImg, setProfileImg] = useState<string | null>("");

    const getInitialValues = () => {
        return {
            first_name: "",
            last_name: "",
            birth_date: null,
            phone: "",
            email: "",
            address: "",
            city: "",
            JMBG: "",
            description: "",
            role: getType() || role,
            password: "",
            repeat_password: "",
            pin: "",
        };
    };
    const getType = () => {
        if (!myProfile) {
            return params.type === "beneficiaries"
                ? roles.BEN
                : params.type === "friends-and-family"
                    ? roles.FF
                    : roles.MED;
        }
        return false;
    };

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        setError,
    } = useForm<FormValues>({ defaultValues: { form: getInitialValues(), linked_users: [] } });

    const { form } = watch();

    useEffect(() => {
        if (!isNew || myProfile) {
            getUser();
            if ((role === 3 || role === 4) && (getType() === 1 || getType() === 2)) {
                getUserOptions();
            }
        } else {
            getUserOptions();
        }
    }, []);

    const getUser = () => {
        const arrangeData = (data: any) => {
            setValue("form", {
                ...data,
                birth_date: new Date(data.birth_date),
                password: "",
                repeat_password: "",
                pin: "",
            });
            if (data.profile_picture) {
                setProfileImg(data.profile_picture);
            }

            setLoader(false);
            if (getType() === 1) {
                setValue(
                    "linked_users",
                    data?.fandf_users?.map((user: IUser) => ({
                        value: user.id,
                        label: `${user.first_name} ${user.last_name}`,
                    })),
                );
            } else if (getType() === 2) {
                setValue(
                    "linked_users",
                    data?.ben_users?.map((user: IUser) => ({
                        value: user.id,
                        label: `${user.first_name} ${user.last_name}`,
                    })),
                );
            }
        };

        if (myProfile) {
            const myData = StoreService.getStoreProperty("user");
            arrangeData(myData);
        } else {
            usersAPI.get(parseInt(params.id), token).then((res) => {
                if (res && res.success) {
                    arrangeData(res.data);
                }
            });
        }
    };

    const validatePasswordEquality = () => {
        if (form.password || form.repeat_password) {
            if (form.password !== form.repeat_password) {
                toggleModal("passwordNotMatch");
                return false;
            }
            return true;
        }
        return true;
    };

    const handleGarminConnect = async (user: IUser) => {
        if (user && Number(user.role) === 1 && !user.garmin_token) {
            const garminConnectData = await biometricAPI.getAuthUrl(token);

            if (garminConnectData?.success) {
                setGarminConnectData(garminConnectData);
                StoreService.updateStoreProperty("garminToken", garminConnectData.request_token);
                StoreService.updateStoreProperty("garminUserId", user.id);
                StoreService.updateStoreProperty("garminSecureToken", garminConnectData.request_token_secret);

                window.open(garminConnectData?.url, "_blank");
            }
        }
    };

    const handleSave = () => {
        const formData: any = new FormData();

        const registerForm: any = getValues("form");
        delete registerForm.profile_picture;
        if (registerForm.fandf_users) {
            delete registerForm.fandf_users;
        }
        if (registerForm.ben_users) {
            delete registerForm.ben_users;
        }

        if (!registerForm.pin) {
            delete registerForm.pin;
        }
        if (!registerForm.password) {
            delete registerForm.password;
        }

        for (let prop in registerForm) {
            //console.log(prop, registerForm[prop]);
            if (prop === "birth_date") {
                formData.append(prop, parseDateForMySQL(registerForm[prop]));
            } else {
                formData.append(prop, registerForm[prop]);
            }
        }

        if (tempProfileImg.img) {
            formData.append("profile", tempProfileImg.img);
        }

        if (validatePasswordEquality()) {
            delete registerForm.repeat_password;
            if (isNew && !myProfile) {
                usersAPI.storeUser(formData, token).then((res) => {
                    if (res && res.success) {
                        toast.success(t("USER_CREATE_SUCCESS"));

                        if (Number(res.data.user.role) === 1) handleGarminConnect(res.data.user);

                        if ((
                                Number(res.data.user.role) === 1 || Number(res.data.user.role) === 2) &&
                            getValues("linked_users").length
                        ) {
                            linkUsers();
                        }

                        redirectToDetails(res.data.user.id);
                    } else {
                        toast.error(t("USER_CREATE_FAIL"));
                    }
                });
            } else {
                usersAPI.update(formData, token).then((res) => {
                    if (res && res.success) {
                        if (getType() === 1) handleGarminConnect(res.data);

                        if (getType() === 1 || getType() === 2) {
                            linkUsers();
                        }
                        if (myProfile) {
                            StoreService.updateStoreProperty("user", res.data);
                        }
                        toast.success(t("USER_UPDATE_SUCCESS"));
                    } else {
                        toast.error(t("USER_UPDATE_FAIL"));
                    }
                });
            }
        } else {
            setError("form.password", { type: "equality", message: "PASSWORDS_DONT_MATCH" });
            setError("form.repeat_password", { type: "equality", message: "PASSWORDS_DONT_MATCH" });
        }
    };

    const getUserOptions = () => {
        const role = getType() === 1 ? roles.FF : roles.BEN;
        usersAPI.getAll({ limit: 100, offset: 0, role }, token).then((res) => {
            setUserOptions(
                res.data.map((user: IUser) => {
                    return { value: user.id, label: `${user.first_name} ${user.last_name}` };
                }),
            );
        });
    };

    const linkUsers = () => {
        const values = getValues("linked_users");

        if (values) {
            const linkedUsers: number[] = values.map((user) => user.value);

            const ben_id = getType() === roles.BEN ? params.id : JSON.stringify(linkedUsers);
            const fandf_id = getType() === roles.FF ? params.id : JSON.stringify(linkedUsers);

            usersAPI.linkUsers(ben_id, fandf_id, token);
        }
    };

    const handleDeactivate = () => {
        usersAPI.toggleActivate(parseInt(params.id!), token).then((res) => {
            if (res && res.success) {
                if (form.is_deactivate) {
                    toast.success(t("USER_ACTIVATE_SUCCESS"));
                    setValue("form.is_deactivate", 0);
                } else {
                    toast.success(t("USER_DEACTIVATE_SUCCESS"));
                    returnToUsersList();
                }
            } else {
                if (form.is_deactivate) {
                    toast.error(t("USER_ACTIVATE_FAIL"));
                } else {
                    toast.error(t("USER_DEACTIVATE_FAIL"));
                }
            }
        });
    };

    const returnToUsersList = () => {
        history.push(`/users/${params.type}/1`);
    };

    const redirectToDetails = (id: number) => {
        history.push(`/users/${params.type}/user/${id}`);
    };

    const deactivateConfirm = () => {
        if (form.is_deactivate) {
            handleDeactivate();
        } else {
            toggleModal("deactivateConfirm");
        }
    };

    const handleFileChange = (name: string, value: any, drop?: boolean) => {
        let blob = new Blob([value[0]], {
            type: "image/png",
        });
        let imageUrl = URL.createObjectURL(blob);

        setTempProfileImg({ url: imageUrl, img: value[0] });
        toggleModal("");
    };

    const removeImage = () => {
        setProfileImg(null);
        setTempProfileImg({ url: "", img: null });
    };

    return (
        <div className="user-details details-screen">
            {(!isNew || myProfile) && loader ? (
                <MainLoader />
            ) : (
                <>
                    <DetailsHeader
                        title={
                            form.first_name || form.last_name ? `${form.first_name} ${form.last_name}` : t("NEW_USER")
                        }
                        className="mb-50"
                        handleSave={
                            hasPermission("users", "edit") || myProfile ? () => handleSubmit(handleSave)() : undefined
                        }
                        handleDeactivate={!isNew && hasPermission("users", "del") ? deactivateConfirm : undefined}
                        deactivated={isNew ? undefined : !!form.is_deactivate}
                        userFlags={!isNew ? { isOnline: !!form.is_online } : undefined}
                    />
                    <CustomForm handleSubmit={handleSubmit(handleSave)} className="mt-80">
                        {(getType() === 1 || getType() === 2) && !myProfile ? (
                            <div className="pb-45 mb-30 linked-users">
                                <CustomDropdown
                                    name="linked_users"
                                    data={userOptions}
                                    label={getType() === 1 ? "LINKED_FF" : "LINKED_BENEFICIARIES"}
                                    control={control}
                                    // rules={{ required: "INPUT_REQUIRED" }}
                                    error={errors.linked_users?.message}
                                    placeholder="SELECT_LINKED_USERS"
                                    multiple
                                    disabled={!hasPermission("users", "edit")}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="d-flex align-items-center mb-45">
                            <ProfileImage
                                toggleModal={hasPermission("users", "edit") ? toggleModal : () => {
                                }}
                                profileImage={profileImg}
                                tempProfileImage={tempProfileImg.url}
                                modalOpened={activeModal === "imageUploadModal"}
                                handleFileChange={handleFileChange}
                                removeImage={removeImage}
                                className="mr-100 pl-15"
                            />
                            <div className="d-flex flex-column flex-fill">
                                <CustomInput
                                    inputProps={{ ...register("form.first_name", { required: "INPUT_REQUIRED" }) }}
                                    placeholder={t("FIRST_NAME")}
                                    label={t("FIRST_NAME")}
                                    type="text"
                                    disabled={myProfile ? false : !hasPermission("users", "edit")}
                                    className="mb-45"
                                    error={errors.form?.first_name?.message}
                                />
                                <CustomInput
                                    inputProps={{ ...register("form.last_name", { required: "INPUT_REQUIRED" }) }}
                                    placeholder={t("LAST_NAME")}
                                    label={"LAST_NAME"}
                                    type="text"
                                    disabled={myProfile ? false : !hasPermission("users", "edit")}
                                    error={errors.form?.last_name?.message}
                                />
                            </div>
                        </div>

                        <div className="input-group d-flex">
                            <CustomInput
                                inputProps={{
                                    ...register("form.phone", {
                                        required: "INPUT_REQUIRED",
                                    }),
                                }}
                                placeholder={t("PHONE")}
                                label={t("PHONE")}
                                type="number"
                                disabled={myProfile ? false : !hasPermission("users", "edit")}
                                error={errors.form?.phone?.message}
                            />
                            <CustomInput
                                inputProps={{
                                    ...register("form.email", { pattern: validateEmail, required: "INPUT_REQUIRED" }),
                                }}
                                placeholder={t("EMAIL")}
                                label={t("EMAIL")}
                                error={errors.form?.email ? t("EMAIL_INVALID") : ""}
                                type="text"
                                disabled={myProfile ? false : !hasPermission("users", "edit")}
                            />
                        </div>
                        <div className="input-group d-flex">
                            <CustomInput
                                inputProps={{ ...register("form.address") }}
                                placeholder={t("ADDRESS")}
                                label={t("ADDRESS")}
                                type="text"
                                disabled={myProfile ? false : !hasPermission("users", "edit")}
                            />
                            <CustomInput
                                inputProps={{ ...register("form.city") }}
                                placeholder={t("CITY")}
                                label={t("CITY")}
                                type="text"
                                disabled={myProfile ? false : !hasPermission("users", "edit")}
                            />
                        </div>
                        <div className="input-group d-flex">
                            <CustomTextArea
                                inputProps={{ ...register("form.description") }}
                                placeholder={t("DESCRIPTION")}
                                label={t("DESCRIPTION")}
                                id={"description"}
                                disabled={myProfile ? false : !hasPermission("users", "edit")}
                            />
                            <div className="d-flex flex-column">
                                <CustomDatePicker
                                    control={control}
                                    label={t("BIRTH_DATE")}
                                    className="mb-45"
                                    name="form.birth_date"
                                    disabled={myProfile ? false : !hasPermission("users", "edit")}
                                />
                                {role !== 2 && (
                                    <CustomInput
                                        inputProps={{ ...register("form.JMBG") }}
                                        label={t("JMBG")}
                                        placeholder={t("JMBG")}
                                        type="text"
                                        disabled={myProfile ? false : !hasPermission("users", "edit")}
                                    />
                                )}
                            </div>
                        </div>
                        {myProfile || role !== 2 ? (
                            <div className="pass-and-pin-section input-group d-flex">
                                <div className="d-flex flex-column">
                                    <div className="mb-45">
                                        <CustomInput
                                            inputProps={{
                                                ...register("form.password", {
                                                    required: isNew && !myProfile ? "INPUT_REQUIRED" : false,
                                                    minLength: { value: 8, message: "PASSWORD_MIN_LENGTH" },
                                                }),
                                            }}
                                            label={t("PASSWORD")}
                                            placeholder={t("PASSWORD")}
                                            type="password"
                                            error={errors.form?.password?.message}
                                            disabled={myProfile ? false : !hasPermission("users", "edit")}
                                        />
                                    </div>

                                    <CustomInput
                                        inputProps={{
                                            ...register("form.repeat_password", {
                                                required: isNew && !myProfile ? "INPUT_REQUIRED" : false,
                                                minLength: { value: 8, message: "PASSWORD_MIN_LENGTH" },
                                            }),
                                        }}
                                        label={t("REPEAT_PASSWORD")}
                                        placeholder={t("PASSWORD")}
                                        type="password"
                                        error={errors.form?.repeat_password?.message}
                                        disabled={myProfile ? false : !hasPermission("users", "edit")}
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        inputProps={{
                                            ...register("form.pin", {
                                                required: isNew && !myProfile ? "INPUT_REQUIRED" : false,
                                                minLength: { value: 4, message: "PIN_MIN_LENGTH" },
                                                maxLength: { value: 4, message: "PIN_MAX_LENGTH" },
                                                pattern: { value: /^\d+$/, message: "PIN_ONLY_NUMBERS" },
                                            }),
                                        }}
                                        label={t("PIN")}
                                        placeholder={t("PIN")}
                                        type="password"
                                        error={errors.form?.pin?.message}
                                        disabled={myProfile ? false : !hasPermission("users", "edit")}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </CustomForm>
                </>
            )}

            <DevTool control={control} />

            <Modal
                name="deactivateConfirm"
                toggle={toggleModal}
                className={activeModal === "deactivateConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deactivateConfirm"
                    toggle={toggleModal}
                    text={"USER_DEACTIVATE_CONFIRM"}
                    type="warning"
                    title="USER_DEACTIVATE"
                    deleteItem={handleDeactivate}
                    buttonText="DEACTIVATE"
                />
            </Modal>

            <Modal
                name="passwordNotMatch"
                toggle={toggleModal}
                className={activeModal === "passwordNotMatch" ? "visible" : ""}
            >
                <ModalContent
                    name="passwordNotMatch"
                    toggle={toggleModal}
                    text={"PASSWORD_NOT_MATCH"}
                    type="failure"
                    title="ERROR"
                />
            </Modal>
        </div>
    );
};

export default UserDetails;
