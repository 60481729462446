import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomFileInput } from "../devkit/Form/Form";
import Modal from "../devkit/Modal/Modal";
import { FileUploadIcon } from "./icons";

interface IFileUpload {
    toggleModal: (name: string) => void;
    isActive: boolean;
    handleFileChange: (name: string, e: any, drop?: boolean) => void;
}

const FileUpload = ({ toggleModal, isActive, handleFileChange }: IFileUpload) => {
    const { t } = useTranslation();
    const [isOver, setIsOver] = useState<boolean>(false);
    const [file] = useState<File[]>([]);

    const changeBorderType = (isOver: boolean) => {
        setIsOver(isOver);
    };

    return (
        <div className="file-upload">
            <Modal
                name="fileUpload"
                toggle={toggleModal}
                className={isActive ? "visible" : ""}
                wrapperClasses="file-upload-modal"
            >
                <div className="pa-20 h-100-perc">
                    <div
                        onDrop={(e) => {
                            if (isActive) {
                                handleFileChange("fileUpload", e, true);
                            } else {
                                // handleFolderDrop("folder", e);
                            }
                        }}
                        className={`drop-image d-flex justify-content-center align-items-center flex-column h-100-perc ${
                            isOver ? "dotted" : ""
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            changeBorderType(true);
                        }}
                        onDragLeave={() => changeBorderType(false)}
                    >
                        <div className="text d-flex flex-column justify-content-center align-items-center">
                            <span className="d-block mb-10">
                                <FileUploadIcon width={40} className="upload-icon" />
                            </span>
                            <span className="d-block mb-10 f-s-20 f-w-400">{t("DRAG_AND_DROP")}</span>
                            <span className=" d-block mb-20 f-s-14 f-w-400">{t("OR")}</span>
                        </div>
                        <CustomFileInput
                            name="file"
                            handleInput={handleFileChange}
                            value={file}
                            label={"CHOOSE_FILE"}
                            multiple
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FileUpload;
