import React, { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { medicalRecordsAPI } from "../../api/medicalRecordsAPI";
import DetailsHeader from "../../layout/DetailsHeader";
import { usersAPI } from "../../api/usersAPI";
import { toast } from "react-toastify";
import { CustomDropdown, CustomForm, CustomInput, CustomTextArea } from "../../devkit/Form/Form";
import { parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import { DevTool } from "@hookform/devtools";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import useModal from "../../services/CustomHooks/useModal";
import MainLoader from "../../components/MainLoader";
import { filesAPI } from "../../api/filesAPI";
import { UtilService } from "../../services/UtilService";
import FilesList from "../../components/FilesList";
import { StoreService } from "../../services/StoreService";
import { GlobalContext } from "../../context/Provider";

interface IMedicalRecordMatchParams {
    id: string;
}

interface IMedicalRecord {
    id: number;
    title: string;
    description: string;
    ben_id: number;
    autors: any[];
    files: any[];
    ben_user: any;
    created_at: string;
}

interface IFile {
    id: number;
    path: string;
    created_at: Date | string;
    update_at: Date | string;
}

type FormValues = {
    form: {
        id: number | null;
        title: string;
        description: string;
    };
};

const MedicalRecordDetails = () => {
    const token = StoreService.getStoreProperty("token");
    const { hasPermission } = useContext(GlobalContext);
    const { t } = useTranslation();
    const history = useHistory();
    const routeMatch = useRouteMatch<IMedicalRecordMatchParams>();
    const [dropdownData, setDropdownData] = useState<any[]>([]);
    const [isCreateNew] = useState<boolean>(!routeMatch.params.id);
    const [currentRecord, setCurrentRecord] = useState<IMedicalRecord | null>(null);
    const [benName, setBenName] = useState<string>("");
    const { activeModal, toggleModal } = useModal();
    const [loader, setLoader] = useState<boolean>(true);
    const [pendingFiles, setPendingFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<IFile[]>([]);
    const [fileToDelete, setFileToDelete] = useState<{ file: number; type: string }>({ file: 0, type: "server" });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
    } = useForm<FormValues>({ defaultValues: { form: { id: null, description: "", title: "" } } });
    const {
        form: { id },
    } = watch();
    useEffect(() => {
        const getUsersForDropdown = () => {
            usersAPI.getAll({ limit: 10000, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    const users = res.data;
                    const usersDropdown = users.map((ben: { id: number; first_name: string; last_name: string }) => {
                        return {
                            value: ben.id,
                            label: `${ben.first_name} ${ben.last_name}`,
                        };
                    });
                    setDropdownData(usersDropdown);
                }
            });
        };
        getUsersForDropdown();
        if (isCreateNew) {
            setLoader(false);
        } else {
            medicalRecordsAPI.get(parseInt(routeMatch.params.id), token).then((response) => {
                if (response && response.success) {
                    setValue("form.id", response.data.id);
                    setValue("form.title", response.data.title);
                    setValue("form.description", response.data.description);
                    setCurrentRecord(response.data);
                    setFiles(response.data.files);
                    setLoader(false);
                }
            });
        }
    }, [routeMatch.params.id, isCreateNew, setValue, token]);

    const handleDelete = () => {
        medicalRecordsAPI.delete(parseInt(routeMatch.params.id), token).then((res) => {
            if (res && res.success) {
                toast.success(t("MEDICAL_RECORD_DELETE_SUCCESS"));
                history.push("/medical-records/1");
            } else {
                toast.error(t("MEDICAL_RECORD_DELETE_FAIL"));
            }
        });
    };

    const handleSave = () => {
        if (isCreateNew) {
            // console.log(getValues("form.id"), getValues("form.title"), getValues("form.description"));
            medicalRecordsAPI
                .create(getValues("form.id")!, getValues("form.title"), getValues("form.description"), token)
                .then((res) => {
                    if (res && res.success) {
                        const data = res.data;
                        toast.success(t("MEDICAL_RECORD_CREATE_SUCCESS"));
                        if (pendingFiles.length) {
                            uploadFiles(res.data[res.data.length - 1].id);
                        } else {
                            history.push(`/medical-records/record/${data[data.length - 1].id}`);
                        }
                    } else {
                        toast.error(t("MEDICAL_RECORD_CREATE_FAIL"));
                    }
                });
        } else {
            // console.log(routeMatch.params.id, getValues("form.title"), getValues("form.description"));
            medicalRecordsAPI
                .update(parseInt(routeMatch.params.id), getValues("form.title"), getValues("form.description"), token)
                .then((res) => {
                    if (res && res.success) {
                        toast.success(t("MEDICAL_RECORD_UPDATE_SUCCESS"));
                        if (pendingFiles.length) {
                            uploadFiles(parseInt(routeMatch.params.id));
                        }
                    } else {
                        toast.error(t("MEDICAL_RECORD_UPDATE_FAIL"));
                    }
                });
        }
    };
    useEffect(() => {
        setBenName(
            dropdownData.find((u) => u.value === getValues("form.id"))
                ? dropdownData.find((u) => u.value === getValues("form.id")).label
                : "",
        );
    }, [id, dropdownData, setBenName]);

    const uploadFiles = (id: number) => {
        const formData = new FormData();
        for (let i = 0; i < pendingFiles.length; i++) {
            formData.append("files[]", pendingFiles[i]);
        }

        formData.append("id", id.toString());
        formData.append("type", "medical_record");

        filesAPI.upload(formData, token).then((res) => {
            if (res && res.success) {
                history.push(`/medical-records/record/${id}`);
            }
        });
    };
    const handleFileChange = (name: string, value: any, drop?: boolean) => {
        setPendingFiles((pendingFiles) => [...pendingFiles, ...UtilService.handleAddFile(name, value, drop)]);
        toggleModal("");
    };

    const deletePendingFile = () => {
        setPendingFiles((pendingFiles) => pendingFiles.filter((file, i) => i !== fileToDelete.file));
        toggleModal("");
    };

    const deleteFile = () => {
        filesAPI.delete(fileToDelete.file, token).then((res) => {
            if (res && res.success) {
                toast.success(t("FILE_DELETE_SUCCESS"));
                setFiles((files) => files.filter((file) => file.id !== fileToDelete.file));
                toggleModal("");
            } else {
                toast.error(t("FILE_DELETE_FAIL"));
            }
        });
    };

    const downloadFile = () => {
    };
    return (
        <div className={"medical-record details-screen"}>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <DetailsHeader
                        title={watch("form.title") ? watch("form.title") : t("TITLE")}
                        className="mb-50"
                        handleSave={hasPermission("records", "edit") ? () => handleSubmit(handleSave)() : undefined}
                        handleDelete={
                            hasPermission("records", "del")
                                ? isCreateNew
                                    ? undefined
                                    : () => {
                                        toggleModal("deleteConfirm");
                                    }
                                : undefined
                        }
                        subHeader={
                            isCreateNew
                                ? benName
                                : `${currentRecord?.ben_user.first_name} ${currentRecord?.ben_user.last_name}`
                        }
                    />
                    <CustomForm>
                        <section className="mb-45">
                            <div className={"top-container"}>
                                <div className={"mr-45"}>
                                    <CustomInput
                                        inputProps={{ ...register("form.title", { required: "INPUT_REQUIRED" }) }}
                                        type="text"
                                        label={t("TITLE")}
                                        placeholder={t("TITLE")}
                                        className={"mb-45"}
                                        error={errors.form?.title?.message}
                                        disabled={!hasPermission("records", "edit")}
                                    />
                                </div>
                                <div>
                                    {isCreateNew && (
                                        <CustomDropdown
                                            name="form.id"
                                            data={dropdownData}
                                            label="BENEFICIARY"
                                            control={control}
                                            rules={{ required: "INPUT_REQUIRED" }}
                                            error={errors.form?.id?.message}
                                            placeholder="SELECT_BEN"
                                            className={"mb-45"}
                                            disabled={!hasPermission("records", "edit")}
                                        />
                                    )}
                                    {!isCreateNew && (
                                        <div className="report-authors mb-45">
                                            <h2>{t("AUTHORS")}:</h2>
                                            <div className="name-list">
                                                {currentRecord?.autors.map((a) => (
                                                    <span>
                                                        {a.first_name} {a.last_name}
                                                        <span>{", "}</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={"text-area-container w-100-perc mr-45"}>
                                <CustomTextArea
                                    inputProps={{ ...register("form.description", { required: "INPUT_REQUIRED" }) }}
                                    placeholder={t("DESCRIPTION")}
                                    label={t("DESCRIPTION")}
                                    id={"description"}
                                    className={"mb-45"}
                                    error={errors.form?.description?.message}
                                    resizable
                                    disabled={!hasPermission("records", "edit")}
                                />
                            </div>
                            {!isCreateNew && (
                                <div className="mb-10 created-at">
                                    <span>{t("CREATED_AT")}:</span>
                                    <span>{currentRecord && parseDateWithoutSeconds(currentRecord?.created_at)}</span>
                                </div>
                            )}
                        </section>
                    </CustomForm>

                    <FilesList
                        handleFileChange={handleFileChange}
                        toggleModal={toggleModal}
                        activeModal={activeModal}
                        deleteFile={(id) => {
                            setFileToDelete({ file: id, type: "server" });
                            toggleModal("deleteConfirmFile");
                        }}
                        deletePendingFile={(index) => {
                            setFileToDelete({ file: index, type: "pending" });
                            toggleModal("deleteConfirmFile");
                        }}
                        pendingFiles={pendingFiles}
                        files={files}
                        canEdit={hasPermission("records", "edit")}
                        downloadFile={downloadFile}
                    />
                    <Modal
                        name="deleteConfirm"
                        toggle={toggleModal}
                        className={activeModal === "deleteConfirm" ? "visible" : ""}
                    >
                        <ModalContent
                            name="deleteConfirm"
                            toggle={toggleModal}
                            text="DELETE_MEDICAL_RECORD_CONFIRM"
                            type="warning"
                            title="CONFIRM"
                            deleteItem={handleDelete}
                        />
                    </Modal>
                    <Modal
                        name="deleteConfirmFile"
                        toggle={toggleModal}
                        className={activeModal === "deleteConfirmFile" ? "visible" : ""}
                    >
                        <ModalContent
                            name="deleteConfirmFile"
                            toggle={toggleModal}
                            text="FILE_DELETE_CONFIRM"
                            type="warning"
                            title="CONFIRM"
                            deleteItem={fileToDelete.type === "server" ? deleteFile : deletePendingFile}
                        ></ModalContent>
                    </Modal>
                </>
            )}

            <DevTool control={control} />
        </div>
    );
};
export default MedicalRecordDetails;
