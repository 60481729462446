import { StoreService } from "../../services/StoreService";
import { UNLOCK_APP } from "../../constants/actionTypes";
import { usersAPI } from "../../api/usersAPI";
import { FirebaseService } from "../../services/FirebaseService";
// import { FirebaseService } from "../../services/FirebaseService";

const unlockApp = () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const token = StoreService.getStoreProperty("token");
//console.log("unlockApp action");

    usersAPI.getOnlineUsers(token).then((response) => {
        if (response && response.success) {
            dispatch({
                type: UNLOCK_APP,
                payload: response.data,
            });

            FirebaseService.sendOnlineStatusNotif(true);

            StoreService.updateStoreProperty("isPinValid", new Date());
        }
    });
};

export default unlockApp;
