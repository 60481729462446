import Translate from "../../../functions/utilFunctions/translate";
import React from "react";
import DatePicker from "react-date-picker";
import { ArrowDownIcon } from "../../../components/icons";
import { Controller } from "react-hook-form";

interface IDatePicker {
    name: string;
    errors?: Array<string>;
    className?: string;
    disabled?: boolean;
    label?: string;
    labelClass?: string;
    minDate?: Date;
    maxDate?: Date;
    isOpen?: boolean;
    control: any;
    rules?: any;
}

const _dp: React.FC<IDatePicker> = ({
    name,
    className,
    errors,
    disabled,
    label,
    labelClass,
    minDate,
    maxDate,
    isOpen,
    control,
    rules,
}) => {
    return (
        <div className={`customDatePicker p-r`}>
            <label className={`${labelClass ? labelClass : "f-s-16"} `}>
                <Translate text={label} />
            </label>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <DatePicker
                        onChange={onChange}
                        locale={"sr-latn-SR"}
                        className={className}
                        disabled={disabled}
                        format="dd/MM/yyyy"
                        maxDetail="month"
                        minDetail="month"
                        nextLabel={<ArrowDownIcon width={14} className="calendar_next_icon_right" />}
                        prevLabel={<ArrowDownIcon width={14} className="calendar_next_icon_left" />}
                        minDate={minDate}
                        maxDate={maxDate}
                        isOpen={isOpen}
                        value={value}
                    />
                )}
            ></Controller>

            {errors?.map((e, i) => (
                <span className={`error f-s-12 is-warning danger`} key={i}>
                    <Translate text={e} />
                </span>
            ))}
        </div>
    );
};
export default _dp;
