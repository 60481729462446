import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../devkit/Form/Form";
import Modal from "../devkit/Modal/Modal";
import { IUser } from "../models/interfaces/User";
import { WarningIcon } from "./icons";

interface SOSModal {
    toggle: () => void;
    active: boolean;
    confirmSOS: () => void;
    sosUser: IUser | undefined;
}

const SOSModal = ({ toggle, active, confirmSOS, sosUser }: SOSModal) => {
    const { t } = useTranslation();

    useEffect(() => {
        const alarmTrack = new Audio("/sos-alarm-mp3.mp3");

        alarmTrack.loop = true;

        const playAlarm = () => {
            if (active) {
                alarmTrack.play();
            }
        };

        playAlarm();

        alarmTrack.addEventListener("ended", playAlarm);

        return () => {
            alarmTrack.removeEventListener("ended", playAlarm);
            alarmTrack.pause();
        };
    }, [active]);

    return (
        <Modal
            toggle={toggle}
            className={`${active ? "visible" : ""}`}
            wrapperClasses={`sos-modal d-flex align-items-center flex-column`}
            name="sosModal"
            preventDefaultClosing
        >
            <div className="top d-flex align-items-center justify-content-center">
                <div className="attention-message d-flex align-items-center">
                    <span>{t("SOS_SIGNAL").toUpperCase()}</span>
                    <WarningIcon width={60} className="warning-icon" />
                </div>
            </div>
            <div className="bottom d-flex align-items-center justify-content-center flex-column">
                <div className="sos-user d-flex align-items-center">
                    <span>
                        {t("BENEFICIARY")}
                        {":"}
                    </span>
                    <span>{`${sosUser?.first_name} ${sosUser?.last_name}`}</span>
                </div>
                <CustomButton onClick={confirmSOS} className="btn btn-primary">
                    <span>{t("CONFIRM")}</span>
                </CustomButton>
            </div>

            {/* <audio loop autoPlay>
                <source src="audio/sos-alarm-wav.wav" type="audio/wav"></source>
                <source src={alarm} type="audio/mpeg"></source>
            </audio> */}
        </Modal>
    );
};

export default SOSModal;
