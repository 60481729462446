const mainReducer = (state: any, action: { type: any; payload: any }) => {
    switch (action.type) {
        case "INIT_STORE":
            return {
                ...state,
                ...action.payload,
            };
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                data: action.payload,
                errors: "",
                loading: false,
                isPinValid: new Date(),
            };
        case "LOGOUT_USER":
            return {
                ...state,
                isLoggedIn: false,
                data: {},
                errors: "",
                isPinValid: false,
            };
        case "LOGIN_FAIL":
            return {
                ...state,
                isLoggedIn: false,
                errors: action.payload,
                loading: false,
                isPinValid: false,
            };
        case "LOGIN_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "VALIDATE_TOKEN":
            return {
                ...state,
                isLoggedIn: true,
                isPinValid: true,
                data: action.payload,
            };
        case "UPDATE_DATA":
            return {
                ...state,
                data: action.payload,
            };
        case "LOGIN_PIN":
            return {
                ...state,
                isLoggedIn: true,
                data: action.payload,
                errors: "",
                isPinValid: new Date(),
            };
        case "LOCK_APP":
            return {
                ...state,
                isPinValid: false,
            };
        case "UNLOCK_APP":
            return {
                ...state,
                data: { ...state.data, online_users: action.payload },
                isPinValid: new Date(),
            };
        case "GET_ONLINE_USERS":
            return {
                ...state,
                data: { ...state.data, online_users: action.payload },
            };
        default:
            return state;
    }
};
export default mainReducer;
