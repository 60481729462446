import React, { useContext, useEffect, useState } from "react";
import { CustomForm, CustomButton, CustomInput, CustomDropdown } from "../../devkit/Form/Form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { MNEFlag } from "../../components/icons/mneFlag";
import { UKFlag } from "../../components/icons/ukFlag";
import { SRFlag } from "../../components/icons/srFlag";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { validateEmail } from "../../functions/utilFunctions/validations";
import loginUser from "../../context/actions/loginUser";
import { GlobalContext } from "../../context/Provider";
import { roleOptions } from "../../constants";
import useMount from "../../services/CustomHooks/useMount";
import { UtilService } from "../../services/UtilService";
import { useHistory } from "react-router";

type ILoginForm = {
    username: string;
    password: string;
    role: number;
};

const Login = () => {
    const { t } = useTranslation();
    const isMount = useMount();
    const history = useHistory();

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        watch,
    } = useForm<ILoginForm>();
    const [emailOrPhonePattern, setEmailOrPhonePattern] = useState<{ value: RegExp; message: string }>({
        value: validateEmail,
        message: "EMAIL_INVALID",
    });
    const { storeDispatch } = useContext(GlobalContext);
    const { username } = watch();

    const login = () => {
        const data = {
            username: getValues("username"),
            password: getValues("password"),
            role: getValues("role"),
        };

        loginUser(data)(storeDispatch);

        history.push("/dashboard");
    };

    const [lang, setLang] = useState(localStorage.getItem("defaultLang"));

    const toggleLanguage = (lang: string) => {
        i18next.changeLanguage(lang);
        localStorage.setItem("defaultLang", lang);
        setLang(lang);
    };

    useEffect(() => {
        if (!isMount) {
            UtilService.handleDebouncing(
                "email-patern-debounce",
                () => {
                    if (/^[0-9]+$/.test(username)) {
                        setEmailOrPhonePattern({ value: /^[0-9]+$/, message: "PHONE_NUMBER_INVALID" });
                    } else {
                        setEmailOrPhonePattern({ value: validateEmail, message: "EMAIL_INVALID" });
                    }
                },
                1000,
            );
        }
    }, [username]);

    return (
        <div className={"login d-flex h-100-perc min-h-vh-100 w-100-perc"}>
            <div className="login-background w-50-perc" />
            <div className="form-container d-flex w-50-perc justify-content-center align-items-center">
                <CustomForm className="w-100-perc d-flex flex-column align-items-center">
                    <img
                        className="telecareLogo"
                        src={"/square_logo.png"}
                        alt={""}
                    />
                    <h2 className="f-s-60 bold pb-40 text-color-primary">Telecare</h2>
                    <div>
                        <div className="pb-30">
                            <CustomInput
                                placeholder="EMAIL_OR_PHONE"
                                label="EMAIL_OR_PHONE"
                                inputProps={{
                                    ...register("username", {
                                        pattern: emailOrPhonePattern,
                                        required: "INPUT_REQUIRED",
                                    }),
                                }}
                                error={errors.username?.message}
                                type="text"
                            />
                        </div>
                        <div className="pb-30">
                            <CustomInput
                                placeholder="PASSWORD"
                                label="PASSWORD"
                                inputProps={{ ...register("password", { required: "INPUT_REQUIRED" }) }}
                                error={errors.password?.message}
                                type="password"
                            />
                        </div>
                        <div className="pb-30">
                            <CustomDropdown
                                name="role"
                                data={roleOptions.map((ro) => ({ value: ro.value, label: t(ro.label) }))}
                                label="ROLE"
                                control={control}
                                rules={{ required: "INPUT_REQUIRED" }}
                                error={errors.role?.message}
                                placeholder="SELECT_ROLE"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="buttons-container d-flex pb-20">
                            <div
                                onClick={() => toggleLanguage("me")}
                                className={`d-flex  pr-10 lang ${lang === "me" ? "lang-selected" : ""}`}
                            >
                                <MNEFlag width={30} />
                            </div>
                            <div
                                onClick={() => toggleLanguage("en")}
                                className={`d-flex  pr-10 lang ${lang === "en" ? "lang-selected" : ""}`}
                            >
                                <UKFlag width={30} />
                            </div>
                            <div
                                onClick={() => toggleLanguage("sr")}
                                className={`d-flex lang ${lang === "sr" ? "lang-selected" : ""}`}
                            >
                                <SRFlag width={30} />
                            </div>
                        </div>
                        <CustomButton onClick={() => handleSubmit(login)()} type="button" className="btn-save">
                            {t("LOGIN")}
                        </CustomButton>
                    </div>
                    <div className={"bottomLogoContainer"}>
                        <img
                            className="bottomLogo"
                            src={"/montenegro.png"}
                            alt={""}
                        />
                        <img
                            className="bottomLogo"
                            src={"/eu.png"}
                            alt={""}
                        />
                        <img
                            className="bottomLogo"
                            src={"/serbia.png"}
                            alt={""}
                        />
                    </div>
                </CustomForm>
            </div>
            <DevTool control={control} />
        </div>
    );
};

export default Login;
