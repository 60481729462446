import React from "react";
import { IIcon } from ".";

export const CallIcon = ({ className, width, height, color }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 58 58">
            <g fill="none" fillRule="evenodd">
                <g fill={`${color ? color : "#000"}`} fillRule="nonzero" transform="translate(-1)">
                    <path d="M25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29a1.988 1.988 0 01.571-1.664L23 14.551a2 2 0 00.282-2.475L16.149 1a2 2 0 00-2.591-.729L2.107 5.664a1.989 1.989 0 00-1.1 1.987c.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855a1.989 1.989 0 001.987-1.1l5.393-11.451A2 2 0 0058 42.851L46.924 35.72a2 2 0 00-2.475.28l-4.478 4.48c-.436.439-1.05.65-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z" />
                    <path d="M47 31a2 2 0 01-2-2c-.01-8.28-6.72-14.99-15-15a2 2 0 110-4c10.489.012 18.988 8.511 19 19a2 2 0 01-2 2z" />
                    <path d="M57 31a2 2 0 01-2-2C54.985 15.2 43.8 4.015 30 4a2 2 0 110-4c16.009.018 28.982 12.991 29 29a2 2 0 01-2 2z" />
                </g>
            </g>
        </svg>
    );
};
export const RejectedCallIcon = ({ className, width, height, color }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 58 58">
            <g fill="none" fillRule="evenodd">
                <g fill={`${color ? color : "#000"}`} fillRule="nonzero" transform="translate(-1 -1)">
                    <path d="M25.017 34.983c-5.536-5.536-6.786-11.072-7.068-13.29a1.988 1.988 0 01.571-1.664L23 15.551a2 2 0 00.282-2.475L16.149 2a2 2 0 00-2.591-.729L2.107 6.664a1.989 1.989 0 00-1.1 1.987c.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855a1.989 1.989 0 001.987-1.1l5.393-11.451A2 2 0 0058 43.851L46.924 36.72a2 2 0 00-2.475.28l-4.478 4.48c-.436.439-1.05.65-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z" />
                    <path d="M54.159 4.427c-5.544-4.817-13.87-4.525-19.063.669-5.194 5.193-5.486 13.519-.669 19.063zM55.573 5.841L35.841 25.573c5.544 4.817 13.87 4.525 19.063-.669s5.486-13.519.669-19.063z" />
                </g>
            </g>
        </svg>
    );
};
export const MissedCallIcon = ({ className, width, height, color }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 58 58">
            <g fill="none" fillRule="evenodd">
                <g fill={`${color ? color : "#000"}`} fillRule="nonzero" transform="translate(-1)">
                    <path d="M25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29a1.988 1.988 0 01.571-1.664L23 14.551a2 2 0 00.282-2.475L16.149 1a2 2 0 00-2.591-.729L2.107 5.664a1.989 1.989 0 00-1.1 1.987c.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855a1.989 1.989 0 001.987-1.1l5.393-11.451A2 2 0 0058 42.851L46.924 35.72a2 2 0 00-2.475.28l-4.478 4.48c-.436.439-1.05.65-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z" />
                    <path d="M39 2v9a4 4 0 004 4h3a1 1 0 001-1v-3.727a1 1 0 011.676-.737l9.52 8.727a1 1 0 010 1.474l-9.52 8.727A1 1 0 0147 27.727V24a1 1 0 00-1-1h-5c-5.523 0-10-4.477-10-10V2a2 2 0 012-2h4a2 2 0 012 2z" />
                </g>
            </g>
        </svg>
    );
};
export const AnswerCallIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 25.625 25.625"
            version="1.1"
            viewBox="0 0 25.625 25.625"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={`${className} answer-call-icon`}
        >
            <path d="M22.079 17.835c-1.548-1.324-3.119-2.126-4.648-.804l-.913.799c-.668.58-1.91 3.29-6.712-2.234-4.801-5.517-1.944-6.376-1.275-6.951l.918-.8c1.521-1.325.947-2.993-.15-4.71l-.662-1.04C7.535.382 6.335-.743 4.81.58l-.824.72c-.674.491-2.558 2.087-3.015 5.119-.55 3.638 1.185 7.804 5.16 12.375 3.97 4.573 7.857 6.87 11.539 6.83 3.06-.033 4.908-1.675 5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995l-.931-.801z"></path>
        </svg>
    );
};
export const RejectCallIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 25.625 25.625"
            version="1.1"
            viewBox="0 0 25.625 25.625"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={`${className} reject-call-icon`}
        >
            <path d="M22.079 17.835c-1.548-1.324-3.119-2.126-4.648-.804l-.913.799c-.668.58-1.91 3.29-6.712-2.234-4.801-5.517-1.944-6.376-1.275-6.951l.918-.8c1.521-1.325.947-2.993-.15-4.71l-.662-1.04C7.535.382 6.335-.743 4.81.58l-.824.72c-.674.491-2.558 2.087-3.015 5.119-.55 3.638 1.185 7.804 5.16 12.375 3.97 4.573 7.857 6.87 11.539 6.83 3.06-.033 4.908-1.675 5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995l-.931-.801z"></path>
        </svg>
    );
};
export const MuteIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 96.666 96.666"
            version="1.1"
            viewBox="0 0 96.666 96.666"
            xmlSpace="preserve"
        >
            <path d="M65.595 56.035V43.349L38.639 70.307a17.16 17.16 0 009.695 2.989c9.518 0 17.261-7.743 17.261-17.261z"></path>
            <path d="M76.078 45.715H72.64a2 2 0 00-2 2v7.029c0 12.3-10.007 22.308-22.308 22.308a22.186 22.186 0 01-12.559-3.882l-5.245 5.245c4.037 3.084 8.856 5.177 14.086 5.835v4.98h-15.35a2 2 0 00-2 2v3.436a2 2 0 002 2h38.138a2 2 0 002-2V91.23a2 2 0 00-2-2H52.051v-4.98c14.594-1.838 26.026-14.799 26.026-29.506v-7.029c.001-1.105-.895-2-1.999-2zM85.972 7.694a5.5 5.5 0 00-7.777 0l-12.6 12.6v-3.032C65.595 7.743 57.852 0 48.333 0S31.071 7.743 31.071 17.262v37.554l-4.552 4.552a22.233 22.233 0 01-.494-4.624v-7.029a2 2 0 00-2-2h-3.437a2 2 0 00-2 2v7.029c0 3.67.726 7.227 2.022 10.533l-9.917 9.916a5.5 5.5 0 007.778 7.779l67.5-67.5a5.497 5.497 0 00.001-7.778z"></path>
        </svg>
    );
};
export const MicrophoneIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 475.085 475.085"
            version="1.1"
            viewBox="0 0 475.085 475.085"
            xmlSpace="preserve"
        >
            <path d="M237.541 328.897c25.128 0 46.632-8.946 64.523-26.83 17.888-17.884 26.833-39.399 26.833-64.525V91.365c0-25.126-8.938-46.632-26.833-64.525C284.173 8.951 262.669 0 237.541 0c-25.125 0-46.632 8.951-64.524 26.84-17.893 17.89-26.838 39.399-26.838 64.525v146.177c0 25.125 8.949 46.641 26.838 64.525 17.889 17.884 39.399 26.83 64.524 26.83z"></path>
            <path d="M396.563 188.15c-3.606-3.617-7.898-5.426-12.847-5.426-4.944 0-9.226 1.809-12.847 5.426-3.613 3.616-5.421 7.898-5.421 12.845v36.547c0 35.214-12.518 65.333-37.548 90.362-25.022 25.03-55.145 37.545-90.36 37.545-35.214 0-65.334-12.515-90.365-37.545-25.028-25.022-37.541-55.147-37.541-90.362v-36.547c0-4.947-1.809-9.229-5.424-12.845-3.617-3.617-7.895-5.426-12.847-5.426s-9.235 1.809-12.85 5.426c-3.618 3.616-5.426 7.898-5.426 12.845v36.547c0 42.065 14.04 78.659 42.112 109.776 28.073 31.118 62.762 48.961 104.068 53.526v37.691h-73.089c-4.949 0-9.231 1.811-12.847 5.428-3.617 3.614-5.426 7.898-5.426 12.847 0 4.941 1.809 9.233 5.426 12.847 3.616 3.614 7.898 5.428 12.847 5.428h182.719c4.948 0 9.236-1.813 12.847-5.428 3.621-3.613 5.431-7.905 5.431-12.847 0-4.948-1.81-9.232-5.431-12.847-3.61-3.617-7.898-5.428-12.847-5.428h-73.08v-37.691c41.299-4.565 75.985-22.408 104.061-53.526 28.076-31.117 42.12-67.711 42.12-109.776v-36.547c0-4.946-1.813-9.225-5.435-12.845z"></path>
        </svg>
    );
};
export const TurnOffVideoIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 484.5 484.5"
            version="1.1"
            viewBox="0 0 484.5 484.5"
            xmlSpace="preserve"
        >
            <path d="M484.5 114.75l-102 102V127.5c0-15.3-10.2-25.5-25.5-25.5H198.9l285.6 285.6V114.75zM33.15 0L0 33.15 68.85 102H51c-15.3 0-25.5 10.2-25.5 25.5v255c0 15.3 10.2 25.5 25.5 25.5h306c5.1 0 10.2-2.55 12.75-5.1l81.6 81.6 33.15-33.15L33.15 0z"></path>
        </svg>
    );
};
export const CameraIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 -87 432 432"
        >
            <path d="M278.906 0H30C13.437.02.02 13.438 0 30v197.422c.02 16.562 13.438 29.98 30 30h248.906c16.559-.02 29.98-13.438 30-30V30c-.02-16.563-13.441-29.98-30-30zm0 0M328.906 169.8L432 226.087V31.98L328.906 88.266zm0 0"></path>
        </svg>
    );
};
