import React from "react";

export interface IIcon {
    className?: string;
    width?: number;
    height?: number;
    color?: string;
    onClick?: any;
}

export const CheckmarkIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            width={width}
            className={className}
            height={height}
        >
            <path
                d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z" />
        </svg>
    );
};
export const Avatar = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={width}
            height={height}
            className={className}
            enableBackground="new 0 0 53 53"
            version="1.1"
            viewBox="0 0 53 53"
            xmlSpace="preserve"
        >
            <path
                fill="#E7ECED"
                d="M18.613 41.552l-7.907 4.313a7.106 7.106 0 00-1.269.903A26.377 26.377 0 0026.5 53c6.454 0 12.367-2.31 16.964-6.144a7.015 7.015 0 00-1.394-.934l-8.467-4.233a3.229 3.229 0 01-1.785-2.888v-3.322c.238-.271.51-.619.801-1.03a19.482 19.482 0 002.632-5.304c1.086-.335 1.886-1.338 1.886-2.53v-3.546c0-.78-.347-1.477-.886-1.965v-5.126s1.053-7.977-9.75-7.977-9.75 7.977-9.75 7.977v5.126a2.644 2.644 0 00-.886 1.965v3.546c0 .934.491 1.756 1.226 2.231.886 3.857 3.206 6.633 3.206 6.633v3.24a3.232 3.232 0 01-1.684 2.833z"
            />
            <path
                fill="#98afe3"
                d="M26.953.004C12.32-.246.254 11.414.004 26.047-.138 34.344 3.56 41.801 9.448 46.76a7.041 7.041 0 011.257-.894l7.907-4.313a3.23 3.23 0 001.683-2.835v-3.24s-2.321-2.776-3.206-6.633a2.66 2.66 0 01-1.226-2.231v-3.546c0-.78.347-1.477.886-1.965v-5.126S15.696 8 26.499 8s9.75 7.977 9.75 7.977v5.126c.54.488.886 1.185.886 1.965v3.546c0 1.192-.8 2.195-1.886 2.53a19.482 19.482 0 01-2.632 5.304c-.291.411-.563.759-.801 1.03V38.8c0 1.223.691 2.342 1.785 2.888l8.467 4.233a7.05 7.05 0 011.39.932c5.71-4.762 9.399-11.882 9.536-19.9C53.246 12.32 41.587.254 26.953.004z"
            />
        </svg>
    );
};
export const UploadIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512 512"
        >
            <g>
                <path
                    d="M182.461 155.48L232 105.941V368a24 24 0 0048 0V105.941l49.539 49.539a24 24 0 1033.941-33.941l-90.509-90.51a24 24 0 00-33.942 0l-90.509 90.51a24 24 0 1033.941 33.941z" />
                <path
                    d="M464 232a24 24 0 00-24 24v184H72V256a24 24 0 00-48 0v192a40 40 0 0040 40h384a40 40 0 0040-40V256a24 24 0 00-24-24z" />
            </g>
        </svg>
    );
};
export const BackIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 492 492"
            version="1.1"
            viewBox="0 0 492 492"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={className}
        >
            <path
                d="M464.344 207.418l.768.168H135.888l103.496-103.724c5.068-5.064 7.848-11.924 7.848-19.124 0-7.2-2.78-14.012-7.848-19.088L223.28 49.538c-5.064-5.064-11.812-7.864-19.008-7.864-7.2 0-13.952 2.78-19.016 7.844L7.844 226.914C2.76 231.998-.02 238.77 0 245.974c-.02 7.244 2.76 14.02 7.844 19.096l177.412 177.412c5.064 5.06 11.812 7.844 19.016 7.844 7.196 0 13.944-2.788 19.008-7.844l16.104-16.112c5.068-5.056 7.848-11.808 7.848-19.008 0-7.196-2.78-13.592-7.848-18.652L134.72 284.406h329.992c14.828 0 27.288-12.78 27.288-27.6v-22.788c0-14.82-12.828-26.6-27.656-26.6z" />
        </svg>
    );
};
export const CameraIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 307.308 307.308"
            version="1.1"
            viewBox="0 0 307.308 307.308"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={className}
        >
            <g>
                <path
                    d="M284.909 66.146h-81.345l-16.426-27.595a8.908 8.908 0 00-7.654-4.351h-51.662a8.907 8.907 0 00-7.654 4.351l-16.426 27.595H77.049v-6.082a8.907 8.907 0 00-8.907-8.907H35.185a8.907 8.907 0 00-8.907 8.907v6.082h-3.88C10.027 66.146 0 76.174 0 88.543v162.166c0 12.37 10.027 22.398 22.397 22.398h262.512c12.37 0 22.398-10.028 22.398-22.398V88.543c.001-12.369-10.028-22.397-22.398-22.397zM153.653 233.379c-35.21 0-63.753-28.543-63.753-63.754 0-35.209 28.543-63.753 63.753-63.753s63.753 28.544 63.753 63.753c0 35.211-28.543 63.754-63.753 63.754zm117.282-121.057h-27.91a8.907 8.907 0 01-8.907-8.908 8.907 8.907 0 018.907-8.908h27.91a8.907 8.907 0 018.908 8.908 8.908 8.908 0 01-8.908 8.908z" />
                <circle cx="153.653" cy="169.625" r="44.538" />
            </g>
        </svg>
    );
};
export const TrashIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            className={className}
            height={height}
            viewBox="0 0 512 512"
        >
            <path
                d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 00-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 00-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z" />
        </svg>
    );
};
export const RefreshIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            className={className}
            height={height}
            x="0"
            y="0"
            enableBackground="new 0 0 438.529 438.528"
            version="1.1"
            viewBox="0 0 438.529 438.528"
            xmlSpace="preserve"
        >
            <path
                d="M433.109 23.694c-3.614-3.612-7.898-5.424-12.848-5.424-4.948 0-9.226 1.812-12.847 5.424l-37.113 36.835c-20.365-19.226-43.684-34.123-69.948-44.684C274.091 5.283 247.056.003 219.266.003c-52.344 0-98.022 15.843-137.042 47.536-39.021 31.689-64.715 73.035-77.087 124.048v1.997c0 2.474.903 4.617 2.712 6.423 1.809 1.809 3.949 2.712 6.423 2.712h56.814c4.189 0 7.042-2.19 8.566-6.565 7.993-19.032 13.035-30.166 15.131-33.403 13.322-21.698 31.023-38.734 53.103-51.106 22.082-12.371 45.873-18.559 71.376-18.559 38.261 0 71.473 13.039 99.645 39.115l-39.406 39.397c-3.607 3.617-5.421 7.902-5.421 12.851 0 4.948 1.813 9.231 5.421 12.847 3.621 3.617 7.905 5.424 12.854 5.424h127.906c4.949 0 9.233-1.807 12.848-5.424 3.613-3.616 5.42-7.898 5.42-12.847V36.542c0-4.949-1.796-9.23-5.42-12.848zM422.253 255.813h-54.816c-4.188 0-7.043 2.187-8.562 6.566-7.99 19.034-13.038 30.163-15.129 33.4-13.326 21.693-31.028 38.735-53.102 51.106-22.083 12.375-45.874 18.556-71.378 18.556-18.461 0-36.259-3.423-53.387-10.273-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613 5.424-7.901 5.424-12.847 0-4.948-1.809-9.236-5.424-12.847-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952 0-9.235 1.811-12.851 5.431C1.807 264.844 0 269.132 0 274.08v127.907c0 4.945 1.807 9.232 5.424 12.847 3.619 3.61 7.902 5.428 12.851 5.428 4.948 0 9.229-1.817 12.847-5.428l36.829-36.833c20.367 19.41 43.542 34.355 69.523 44.823 25.981 10.472 52.866 15.701 80.653 15.701 52.155 0 97.643-15.845 136.471-47.534 38.828-31.688 64.333-73.042 76.52-124.05.191-.38.281-1.047.281-1.995 0-2.478-.907-4.612-2.715-6.427-1.81-1.799-3.953-2.706-6.431-2.706z" />
        </svg>
    );
};
export const ArrowDownIcon = ({ className, width, height, color }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            className={className}
            width={width}
            height={height}
            fill={color}
        >
            <path
                d="M505.183 123.179c-9.087-9.087-23.824-9.089-32.912.002l-216.266 216.27L39.729 123.179c-9.087-9.087-23.824-9.089-32.912.002-9.089 9.089-9.089 23.824 0 32.912L239.55 388.82a23.271 23.271 0 0032.91-.001l232.721-232.727c9.091-9.088 9.091-23.824.002-32.913z" />
        </svg>
    );
};
export const AtSignIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path
                d="M226.735 1.636C111.259 14.518 17.472 106.202 2.285 221.396c-21.518 163.24 111.938 302.116 273.589 289.849 59.464-4.513 115.124-31.086 158.736-71.759l3.746-3.492c4.309-4.018 4.544-10.768.526-15.076l-14.555-15.607c-4.018-4.309-10.767-4.544-15.076-.526l-7.803 7.277c-48.51 45.232-115.104 65.582-182.284 54.141-95.215-16.216-168.411-97.052-175.846-193.352-9.831-127.343 92.889-233.758 219.006-230.093 116.288 3.381 207.009 102.616 207.009 218.953v15.622c0 24.934-21.486 44.932-46.921 42.46C400.246 317.637 384 297.714 384 275.442v-94.108c0-5.892-4.776-10.667-10.667-10.667H352c-4.283 0-7.921 2.56-9.618 6.203-21.465-23.413-52.19-38.203-86.382-38.203-64.698 0-117.333 52.635-117.333 117.333S191.302 373.334 256 373.334c40.755 0 76.691-20.909 97.732-52.54 15.842 26.475 45.276 43.922 78.78 41.677 45.303-3.035 79.488-42.746 79.488-88.15V256C512 105.202 380.931-15.566 226.735 1.636zM256 330.667c-41.167 0-74.667-33.5-74.667-74.667s33.5-74.667 74.667-74.667 74.667 33.5 74.667 74.667-33.5 74.667-74.667 74.667z" />
        </svg>
    );
};
export const AwardIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24" className={className} width={width} height={height}>
            <g transform="translate(-4)">
                <circle className="a" cx="7" cy="7" r="7" transform="translate(5 1)" />
                <path className="a" d="M8.21,13.89,7,23l5-3,5,3-1.21-9.12" />
            </g>
        </svg>
    );
};
export const PhoneIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512.021 512.021"
        >
            <path
                d="M367.988 512.021c-16.528 0-32.916-2.922-48.941-8.744-70.598-25.646-136.128-67.416-189.508-120.795S34.389 263.572 8.744 192.974c-8.241-22.688-10.673-46.108-7.226-69.612 3.229-22.016 11.757-43.389 24.663-61.809 12.963-18.501 30.245-33.889 49.977-44.5C97.2 5.738 120.167 0 144.423 0a16.002 16.002 0 0115.645 12.647l25.114 117.199a16.004 16.004 0 01-4.331 14.667l-42.913 42.912c40.482 80.486 106.17 146.174 186.656 186.656l42.912-42.913a16.01 16.01 0 0114.667-4.331l117.199 25.114a16 16 0 0112.647 15.645c0 24.256-5.738 47.224-17.054 68.266-10.611 19.732-25.999 37.014-44.5 49.977-18.419 12.906-39.792 21.434-61.809 24.663a142.318 142.318 0 01-20.668 1.519zM131.639 32.7c-31.995 3.532-60.393 20.302-79.251 47.217-21.206 30.265-26.151 67.49-13.567 102.132C88.125 317.775 194.246 423.896 329.972 473.2c34.641 12.584 71.866 7.64 102.132-13.567 26.915-18.858 43.685-47.256 47.217-79.251l-95.341-20.43-44.816 44.816a15.998 15.998 0 01-18.117 3.168c-95.19-44.72-172.242-121.772-216.962-216.962a15.999 15.999 0 013.168-18.117l44.816-44.816z" />
        </svg>
    );
};
export const FilterIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512 512"
        >
            <path
                d="M420.041 0H91.959C41.251 0 0 41.251 0 91.959V420.04C0 470.749 41.251 512 91.959 512h328.082C470.749 512 512 470.749 512 420.041V91.959C512 41.251 470.749 0 420.041 0zm12.649 399.561H268.599c-14.422 42.69-75.006 42.755-89.449 0H79.31c-19.922 0-19.927-30.118 0-30.118h99.84c14.421-42.69 75.006-42.755 89.449 0H432.69c19.922 0 19.928 30.118 0 30.118zm0-128.502h-35.589c-14.422 42.69-75.006 42.755-89.45 0H79.31c-19.922 0-19.927-30.118 0-30.118h228.342c14.421-42.69 75.006-42.755 89.45 0h35.589c19.921 0 19.927 30.118-.001 30.118zm0-128.502H204.348c-14.422 42.69-75.006 42.755-89.449 0H79.31c-19.922 0-19.927-30.118 0-30.118h35.589c14.421-42.69 75.006-42.755 89.449 0H432.69c19.922 0 19.928 30.118 0 30.118z" />
            <path
                d="M223.875 367.435c-22.581 0-22.569 34.133 0 34.133 22.58.001 22.568-34.133 0-34.133zM159.624 110.431c-22.581 0-22.569 34.133 0 34.133 22.58.001 22.568-34.133 0-34.133zM352.376 238.933c-22.581 0-22.569 34.133 0 34.133 22.581.001 22.569-34.133 0-34.133z" />
        </svg>
    );
};
export const ShowPasswordIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 511.999 511.999"
            version="1.1"
            viewBox="0 0 511.999 511.999"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={className}
        >
            <path
                d="M508.745 246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818 239.784 3.249 246.035a16.896 16.896 0 000 19.923c4.569 6.257 113.557 153.206 252.748 153.206s248.174-146.95 252.748-153.201a16.875 16.875 0 000-19.922zM255.997 385.406c-102.529 0-191.33-97.533-217.617-129.418 26.253-31.913 114.868-129.395 217.617-129.395 102.524 0 191.319 97.516 217.617 129.418-26.253 31.912-114.868 129.395-217.617 129.395z" />
            <path
                d="M255.997 154.725c-55.842 0-101.275 45.433-101.275 101.275s45.433 101.275 101.275 101.275S357.272 311.842 357.272 256s-45.433-101.275-101.275-101.275zm0 168.791c-37.23 0-67.516-30.287-67.516-67.516s30.287-67.516 67.516-67.516 67.516 30.287 67.516 67.516-30.286 67.516-67.516 67.516z" />
        </svg>
    );
};
export const SendArrow = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            enableBackground="new 0 0 448.011 448.011"
            version="1.1"
            viewBox="0 0 448.011 448.011"
            xmlSpace="preserve"
            fill="#ff4a49"
        >
            <path
                d="M438.731 209.463l-416-192c-6.624-3.008-14.528-1.216-19.136 4.48a15.911 15.911 0 00-.384 19.648l136.8 182.4-136.8 182.4c-4.416 5.856-4.256 13.984.352 19.648 3.104 3.872 7.744 5.952 12.448 5.952 2.272 0 4.544-.48 6.688-1.472l416-192c5.696-2.624 9.312-8.288 9.312-14.528s-3.616-11.904-9.28-14.528z" />
        </svg>
    );
};
export const WarningIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 512.209 512.209"
            version="1.1"
            viewBox="0 0 512.209 512.209"
            xmlSpace="preserve"
        >
            <path
                d="M507.345 439.683L288.084 37.688c-3.237-5.899-7.71-10.564-13.429-13.988-5.705-3.427-11.893-5.142-18.554-5.142s-12.85 1.718-18.558 5.142c-5.708 3.424-10.184 8.089-13.418 13.988L4.859 439.683c-6.663 11.998-6.473 23.989.57 35.98 3.239 5.517 7.664 9.897 13.278 13.128 5.618 3.237 11.66 4.859 18.132 4.859h438.529c6.479 0 12.519-1.622 18.134-4.859 5.62-3.23 10.038-7.611 13.278-13.128 7.043-11.998 7.235-23.982.565-35.98zm-214.69-28.551c0 2.662-.91 4.897-2.71 6.704-1.807 1.811-3.949 2.71-6.427 2.71h-54.816c-2.474 0-4.616-.899-6.423-2.71-1.809-1.807-2.713-4.042-2.713-6.704v-54.248c0-2.662.905-4.897 2.713-6.704 1.807-1.811 3.946-2.71 6.423-2.71h54.812c2.479 0 4.62.899 6.428 2.71 1.803 1.807 2.71 4.042 2.71 6.704v54.248h.003zm-.567-106.775c-.198 1.902-1.198 3.47-3.001 4.709-1.811 1.238-4.046 1.854-6.711 1.854h-52.82c-2.663 0-4.947-.62-6.849-1.854-1.908-1.243-2.858-2.807-2.858-4.716l-4.853-130.47c0-2.667.953-4.665 2.856-5.996 2.474-2.093 4.758-3.14 6.854-3.14h62.809c2.098 0 4.38 1.043 6.854 3.14 1.902 1.331 2.851 3.14 2.851 5.424l-5.132 131.049z" />
        </svg>
    );
};

export const SuccessIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={`${className} successIcon`}
        >
            <path
                d="M369.164 174.77c7.813 7.812 7.813 20.476 0 28.285L234.992 337.23c-7.812 7.81-20.472 7.81-28.285 0l-63.871-63.875c-7.813-7.808-7.813-20.472 0-28.28 7.809-7.813 20.473-7.813 28.281 0l49.73 49.73L340.88 174.77c7.812-7.81 20.476-7.81 28.285 0zM512 256c0 141.504-114.516 256-256 256C114.496 512 0 397.484 0 256 0 114.496 114.516 0 256 0c141.504 0 256 114.516 256 256zm-40 0c0-119.395-96.621-216-216-216-119.395 0-216 96.621-216 216 0 119.395 96.621 216 216 216 119.395 0 216-96.621 216-216zm0 0" />
        </svg>
    );
};
export const YellowQuestionMarkIcon = ({ className }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="80pt"
            viewBox="0 0 512 512"
            width="80pt"
            className={`${className} yellowQuestionMarkIcon`}
        >
            <path
                d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                fill="#ffc107"
            />
            <path
                d="m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"
                fill="#eceff1"
            />
            <path
                d="m289.769531 269.695312c-7.550781 3.476563-12.4375 11.09375-12.4375 19.394532v9.578125c0 11.773437-9.535156 21.332031-21.332031 21.332031s-21.332031-9.558594-21.332031-21.332031v-9.578125c0-24.898438 14.632812-47.722656 37.226562-58.15625 21.738281-10.003906 37.4375-36.566406 37.4375-49.601563 0-29.394531-23.914062-53.332031-53.332031-53.332031s-53.332031 23.9375-53.332031 53.332031c0 11.777344-9.539063 21.335938-21.335938 21.335938s-21.332031-9.558594-21.332031-21.335938c0-52.925781 43.070312-96 96-96s96 43.074219 96 96c0 28.824219-25.003906 71.191407-62.230469 88.363281zm0 0"
                fill="#fafafa"
            />
        </svg>
    );
};
export const ShowMoreIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            x="0"
            y="0"
            enableBackground="new 0 0 408 408"
            version="1.1"
            viewBox="0 0 408 408"
            xmlSpace="preserve"
        >
            <path
                d="M51 153c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51zm306 0c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51zm-153 0c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51z" />
        </svg>
    );
};
export const DashboardIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} className={className}>
            <path
                d="M9.25 0h-7.5C.785 0 0 .785 0 1.75v4.5C0 7.215.785 8 1.75 8h7.5C10.215 8 11 7.215 11 6.25v-4.5C11 .785 10.215 0 9.25 0zM9.25 10h-7.5C.785 10 0 10.785 0 11.75v10.5C0 23.215.785 24 1.75 24h7.5c.965 0 1.75-.785 1.75-1.75v-10.5c0-.965-.785-1.75-1.75-1.75zM22.25 16h-7.5c-.965 0-1.75.785-1.75 1.75v4.5c0 .965.785 1.75 1.75 1.75h7.5c.965 0 1.75-.785 1.75-1.75v-4.5c0-.965-.785-1.75-1.75-1.75zM22.25 0h-7.5C13.785 0 13 .785 13 1.75v10.5c0 .965.785 1.75 1.75 1.75h7.5c.965 0 1.75-.785 1.75-1.75V1.75C24 .785 23.215 0 22.25 0z" />
        </svg>
    );
};
export const GroupIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 511.999 511.999"
            version="1.1"
            viewBox="0 0 511.999 511.999"
            xmlSpace="preserve"
        >
            <path
                d="M438.09 273.32h-39.596c4.036 11.05 6.241 22.975 6.241 35.404v149.65c0 5.182-.902 10.156-2.543 14.782h65.461c24.453 0 44.346-19.894 44.346-44.346v-81.581c.001-40.753-33.155-73.909-73.909-73.909zM107.265 308.725c0-12.43 2.205-24.354 6.241-35.404H73.91c-40.754 0-73.91 33.156-73.91 73.91v81.581c0 24.452 19.893 44.346 44.346 44.346h65.462a44.144 44.144 0 01-2.543-14.783v-149.65zM301.261 234.815h-90.522c-40.754 0-73.91 33.156-73.91 73.91v149.65c0 8.163 6.618 14.782 14.782 14.782h208.778c8.164 0 14.782-6.618 14.782-14.782v-149.65c0-40.754-33.156-73.91-73.91-73.91zM256 38.84c-49.012 0-88.886 39.874-88.886 88.887 0 33.245 18.349 62.28 45.447 77.524 12.853 7.23 27.671 11.362 43.439 11.362s30.586-4.132 43.439-11.362c27.099-15.244 45.447-44.28 45.447-77.524 0-49.012-39.874-88.887-88.886-88.887zM99.918 121.689c-36.655 0-66.475 29.82-66.475 66.475 0 36.655 29.82 66.475 66.475 66.475a66.095 66.095 0 0026.195-5.388c13.906-5.987 25.372-16.585 32.467-29.86a66.05 66.05 0 007.813-31.227c0-36.654-29.82-66.475-66.475-66.475zM412.082 121.689c-36.655 0-66.475 29.82-66.475 66.475a66.045 66.045 0 007.813 31.227c7.095 13.276 18.561 23.874 32.467 29.86a66.095 66.095 0 0026.195 5.388c36.655 0 66.475-29.82 66.475-66.475 0-36.655-29.82-66.475-66.475-66.475z" />
        </svg>
    );
};
export const ProfileIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 45.532 45.532"
            version="1.1"
            viewBox="0 0 45.532 45.532"
            xmlSpace="preserve"
        >
            <path
                d="M22.766.001C10.194.001 0 10.193 0 22.766s10.193 22.765 22.766 22.765c12.574 0 22.766-10.192 22.766-22.765S35.34.001 22.766.001zm0 6.807a7.53 7.53 0 11.001 15.06 7.53 7.53 0 01-.001-15.06zm-.005 32.771a16.708 16.708 0 01-10.88-4.012 3.209 3.209 0 01-1.126-2.439c0-4.217 3.413-7.592 7.631-7.592h8.762c4.219 0 7.619 3.375 7.619 7.592a3.2 3.2 0 01-1.125 2.438 16.702 16.702 0 01-10.881 4.013z" />
        </svg>
    );
};
export const ChevronRight = ({ className, width, height, onClick }: IIcon) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 492.004 492.004"
            version="1.1"
            viewBox="0 0 492.004 492.004"
            xmlSpace="preserve"
            onClick={onClick}
        >
            <path
                d="M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z" />
        </svg>
    );
};
export const MedicalRecordIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 510 510"
            width={width}
            height={height}
            className={className}
        >
            <path d="M165 0h180v60H165z" />
            <path
                d="M375 30v60H135V30H75v480h360V30zM195 450h-45v-30h45zm0-60h-45v-30h45zm0-60h-45v-30h45zm165 120H225v-30h135zm0-60H225v-30h135zm0-60H225v-30h135zm0-135h-64.189l-21.895 65.684-47.721-79.535L219.27 195H150v-30h50.73l23.074-46.148 42.279 70.465L274.189 165H360z" />
        </svg>
    );
};
export const LockIcon = ({ className, width, height, onClick }: IIcon) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 491.52 491.52"
            version="1.1"
            viewBox="0 0 491.52 491.52"
            xmlSpace="preserve"
            onClick={onClick}
        >
            <path
                d="M399.4 203.578H379.594v-69.744C379.593 60.039 319.556 0 245.759 0c-73.793 0-133.832 60.039-133.832 133.835v69.744H92.122c-15.656 0-28.35 12.692-28.35 28.349V463.17c0 15.658 12.693 28.35 28.35 28.35H399.4c15.656 0 28.348-12.692 28.348-28.35V231.928c0-15.658-12.692-28.35-28.348-28.35zm-123.465 203.07h-60.349l10.801-51.467c-10.101-6.428-16.848-17.652-16.848-30.512 0-20.003 16.218-36.219 36.221-36.219 20.004 0 36.219 16.216 36.219 36.219 0 12.86-6.745 24.084-16.845 30.51l10.801 51.469zm55.905-203.07H159.68v-69.744c0-47.469 38.613-86.081 86.08-86.081 47.469 0 86.08 38.613 86.08 86.081v69.744z" />
        </svg>
    );
};
export const ReportIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="-66 -21 682 682.667"
        >
            <path d="M422.555 128.418h113.699L416.586 10.883v111.574c0 3.285 2.676 5.961 5.969 5.961zm0 0" />
            <path
                d="M422.555 165.918c-23.965 0-43.465-19.496-43.465-43.461V0H67.293C28.715 0-2.668 31.383-2.668 69.96v500.08c0 38.577 31.383 69.96 69.961 69.96h410.078c38.582 0 69.961-31.383 69.961-69.96V165.917zM379.695 485H138.191c-10.355 0-18.75-8.395-18.75-18.75s8.395-18.75 18.75-18.75h241.504c10.36 0 18.75 8.395 18.75 18.75s-8.39 18.75-18.75 18.75zm-260.254-93.75c0-10.355 8.395-18.75 18.75-18.75H353.7c10.36 0 18.75 8.395 18.75 18.75S364.051 410 353.7 410H138.191c-10.355 0-18.75-8.395-18.75-18.75zM402.496 335H138.191c-10.355 0-18.75-8.395-18.75-18.75s8.395-18.75 18.75-18.75h264.305c10.356 0 18.75 8.395 18.75 18.75S412.852 335 402.496 335zm0 0" />
        </svg>
    );
};
export const PlusIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512 512"
        >
            <path
                d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm112 277.332h-90.668V368c0 11.777-9.555 21.332-21.332 21.332s-21.332-9.555-21.332-21.332v-90.668H144c-11.777 0-21.332-9.555-21.332-21.332s9.555-21.332 21.332-21.332h90.668V144c0-11.777 9.555-21.332 21.332-21.332s21.332 9.555 21.332 21.332v90.668H368c11.777 0 21.332 9.555 21.332 21.332s-9.555 21.332-21.332 21.332zm0 0" />
        </svg>
    );
};
export const EditIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 477.873 477.873"
            version="1.1"
            viewBox="0 0 477.873 477.873"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={className}
        >
            <path
                d="M392.533 238.937c-9.426 0-17.067 7.641-17.067 17.067V426.67c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V85.337c0-9.426 7.641-17.067 17.067-17.067H256c9.426 0 17.067-7.641 17.067-17.067S265.426 34.137 256 34.137H51.2C22.923 34.137 0 57.06 0 85.337V426.67c0 28.277 22.923 51.2 51.2 51.2h307.2c28.277 0 51.2-22.923 51.2-51.2V256.003c0-9.425-7.641-17.066-17.067-17.066z" />
            <path
                d="M458.742 19.142A65.328 65.328 0 00412.536.004a64.85 64.85 0 00-46.199 19.149L141.534 243.937a17.254 17.254 0 00-4.113 6.673l-34.133 102.4c-2.979 8.943 1.856 18.607 10.799 21.585 1.735.578 3.552.873 5.38.875a17.336 17.336 0 005.393-.87l102.4-34.133c2.515-.84 4.8-2.254 6.673-4.13l224.802-224.802c25.515-25.512 25.518-66.878.007-92.393z" />
        </svg>
    );
};
export const CloseIconCircle = ({ className, width, height, onClick }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 511.76 511.76"
            version="1.1"
            viewBox="0 0 511.76 511.76"
            xmlSpace="preserve"
            width={width}
            height={height}
            className={className}
            onClick={onClick}
        >
            <path
                d="M436.896 74.869c-99.84-99.819-262.208-99.819-362.048 0-99.797 99.819-99.797 262.229 0 362.048 49.92 49.899 115.477 74.837 181.035 74.837s131.093-24.939 181.013-74.837c99.819-99.818 99.819-262.229 0-362.048zm-75.435 256.448c8.341 8.341 8.341 21.824 0 30.165a21.275 21.275 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251l-75.413-75.435-75.392 75.413a21.348 21.348 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251c-8.341-8.341-8.341-21.845 0-30.165l75.392-75.413-75.413-75.413c-8.341-8.341-8.341-21.845 0-30.165 8.32-8.341 21.824-8.341 30.165 0l75.413 75.413 75.413-75.413c8.341-8.341 21.824-8.341 30.165 0 8.341 8.32 8.341 21.824 0 30.165l-75.413 75.413 75.415 75.435z" />
        </svg>
    );
};
export const FileUploadIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 511.999 511.999"
        >
            <path
                fill="#bed8fb"
                d="M422.651 225.765v248.961c0 20.586-16.688 37.273-37.273 37.273H79.172c-20.586 0-37.273-16.688-37.273-37.273V84.723c0-20.591 16.692-37.273 37.273-37.273h165.159z"
            />
            <path
                fill="#ddeafb"
                d="M126.622 464.55c-20.586 0-37.273-16.688-37.273-37.273V37.273C89.348 16.682 106.04 0 126.622 0H291.78C320.175 0 470.1 149.924 470.1 178.316v248.961c0 20.586-16.688 37.273-37.273 37.273z"
            />
            <path
                fill="#bed8fb"
                d="M470.1 178.319v15.767c0-33.195-26.918-60.113-60.113-60.113H373.4c-20.581 0-37.273-16.692-37.273-37.273V60.113C336.127 26.918 309.209 0 276.014 0h15.767c28.39 0 55.627 11.28 75.701 31.355l71.264 71.264a107.07 107.07 0 0131.354 75.7z"
            />
            <g fill="#80b4fb">
                <path
                    d="M242.615 284.564v108.975a8.512 8.512 0 008.512 8.512h57.194a8.512 8.512 0 008.512-8.512V284.564h24.315c7.583 0 11.381-9.168 6.019-14.53l-54.331-54.331c-7.241-7.241-18.982-7.241-26.223 0l-54.331 54.331c-5.362 5.362-1.564 14.53 6.019 14.53zM213.396 185.797h132.656c9.161 0 16.587-7.426 16.587-16.587v-.456c0-9.161-7.426-16.587-16.587-16.587H213.396c-9.161 0-16.587 7.426-16.587 16.587v.456c0 9.16 7.426 16.587 16.587 16.587z" />
            </g>
        </svg>
    );
};
export const SOSIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512 512"
        >
            <ellipse cx="258.351" cy="223.5" rx="35.498" ry="35.498" transform="rotate(-62.1 258.467 223.44)" />
            <path
                d="M477.477 83.386H34.523C15.456 83.386 0 98.842 0 117.909v207.137c0 19.066 15.456 34.523 34.523 34.523h48.863l69.046 69.046 69.046-69.046h256c19.066 0 34.523-15.456 34.523-34.523V117.909c-.001-19.067-15.457-34.523-34.524-34.523zM166.703 254.841c-3.551 19.891-22.452 34.328-44.94 34.328-18.018 0-34.941-7.271-46.431-19.95l22.229-20.146c5.729 6.321 14.776 10.095 24.201 10.095 8.458 0 14.583-4.983 15.408-9.601 1.064-5.961-8.762-10.079-11.798-11.199-18.496-6.993-28.592-11.34-32.484-13.463-11.539-7.522-17.438-20.728-15.394-34.461 2.13-14.31 12.514-25.88 27.149-30.299 10.4-3.589 32.811-5.09 53.571 11.333l-18.611 23.528c-12.325-9.748-23.781-6.891-25.288-6.455-6.592 1.928-9.23 6.17-5.704 10.714 3.87 1.689 15.105 6.516 27.143 10.956 26.92 9.932 33.638 29.561 30.949 44.62zm91.648 34.157c-36.116 0-65.498-29.382-65.498-65.498s29.382-65.498 65.498-65.498 65.498 29.382 65.498 65.498-29.382 65.498-65.498 65.498zm185.114-34.157c-3.551 19.891-22.451 34.328-44.94 34.328-18.018 0-34.941-7.271-46.431-19.95l22.23-20.146c5.729 6.321 14.775 10.095 24.2 10.095 8.458 0 14.583-4.983 15.408-9.601 1.064-5.961-8.763-10.079-11.8-11.199-18.496-6.993-28.591-11.34-32.483-13.463-11.539-7.523-17.438-20.728-15.393-34.461 2.13-14.31 12.513-25.879 27.148-30.298 10.397-3.589 32.81-5.091 53.571 11.333l-18.611 23.528c-12.326-9.75-23.783-6.892-25.288-6.455-6.592 1.926-9.229 6.171-5.704 10.713 3.87 1.689 15.105 6.516 27.143 10.956 26.92 9.931 33.639 29.561 30.95 44.62z" />
        </svg>
    );
};
export const BellIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 512 512"
        >
            <path
                d="M256 512c30.692 0 57.122-18.539 68.719-45H187.281c11.597 26.461 38.028 45 68.719 45zM411 247.862V215c0-69.822-46.411-129.001-110-148.33V45c0-24.813-20.187-45-45-45s-45 20.187-45 45v21.67C147.41 85.999 101 145.177 101 215v32.862c0 61.332-23.378 119.488-65.827 163.756A14.999 14.999 0 0046 437h420a15 15 0 0010.827-25.382C434.378 367.35 411 309.193 411 247.862zM271 60.728c-4.937-.476-9.94-.728-15-.728s-10.063.252-15 .728V45c0-8.271 6.729-15 15-15s15 6.729 15 15zM451 215c0 8.284 6.716 15 15 15s15-6.716 15-15c0-60.1-23.404-116.603-65.901-159.1-5.857-5.857-15.355-5.858-21.213 0s-5.858 15.355 0 21.213C430.717 113.944 451 162.913 451 215zM46 230c8.284 0 15-6.716 15-15 0-52.086 20.284-101.055 57.114-137.886 5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0C54.404 98.398 31 154.9 31 215c0 8.284 6.716 15 15 15z" />
        </svg>
    );
};
export const VideoCallIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 24 24">
            <path
                d="M24 2v15c0 1.1-.9 2-2 2h-4.56a5.241 5.241 0 00-5.19-4.5H12v-.53a5.96 5.96 0 001.5-2.07v2.6h9V2.75c0-.69-.56-1.25-1.25-1.25h-6.5c-.69 0-1.25.56-1.25 1.25v4.38c-.34-.8-.86-1.51-1.5-2.08V2c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2z" />
            <path
                d="M18 8c-.827 0-1.5-.673-1.5-1.5S17.173 5 18 5s1.5.673 1.5 1.5S18.827 8 18 8zM20.25 12h-4.5a.75.75 0 01-.75-.75A2.252 2.252 0 0117.25 9h1.5A2.252 2.252 0 0121 11.25a.75.75 0 01-.75.75zM15.25 24H.75a.75.75 0 01-.75-.75v-3.5A3.754 3.754 0 013.75 16h8.5A3.754 3.754 0 0116 19.75v3.5a.75.75 0 01-.75.75zM3.6 8.6c0-.02 0-.05.01-.07A4.494 4.494 0 018 5c2.16 0 3.97 1.53 4.4 3.56 0 .01.01.03.01.04-.01 0-.03.01-.04.01-1.68.45-2.77-1.38-2.81-1.46a.51.51 0 00-.78-.11C6.66 9.01 3.83 8.63 3.6 8.6z" />
            <path
                d="M11.82 9.68c-1.35 0-2.31-.94-2.79-1.54C6.75 9.93 4.06 9.67 3.51 9.6c-.01.15 0 .29.02.43C3.8 12.26 5.7 14 8 14c2.31 0 4.22-1.75 4.47-4 .02-.13.03-.26.02-.4-.23.06-.45.08-.67.08z" />
        </svg>
    );
};
export const BiometricIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className}
             enableBackground="new 0 0 297 297"
             version="1.1"
             viewBox="0 0 297 297"
             xmlSpace="preserve"
        >
            <path
                d="M277.47 49.459c-13.551-13.78-33.892-21.064-58.824-21.064-30.677 0-57.294 17.727-70.144 43.475-12.85-25.748-39.466-43.475-70.144-43.475-23.853 0-44.62 7.79-58.475 21.934C6.41 64.085-.458 83.648.024 106.902c.273 13.19 3.447 26.465 9.483 39.782h51.16l18.615-55.847a8.194 8.194 0 0115.546 0l33.193 99.58 12.71-38.131a8.194 8.194 0 017.773-5.602H287.38c5.831-12.691 9.37-26.022 9.602-39.815.411-24.51-6.154-43.826-19.512-57.41z" />
            <path
                d="M135.792 218.917a8.194 8.194 0 01-15.546 0l-33.193-99.58-12.71 38.131a8.194 8.194 0 01-7.773 5.602H18.377c12.35 19.664 30.885 39.377 55.545 58.996 35.138 27.955 69.518 44.986 70.965 45.697a8.183 8.183 0 007.27-.019c4.537-2.26 88.196-44.559 126.254-104.674H154.407l-18.615 55.847z" />
        </svg>
    );
};
export const ExportPDFIcon = ({ className, width, height }: IIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className}
             enableBackground="new 0 0 297 297"
             version="1.1"
             xmlSpace="preserve"
             fill="none"
             viewBox="0 0 15 15"
        >
            <path
                // fill="#000"
                d="M3.5 8H3V7h.5a.5.5 0 010 1zM7 10V7h.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H7z"
            />
            <path
                // fill="#000"
                fillRule="evenodd"
                d="M1 1.5A1.5 1.5 0 012.5 0h8.207L14 3.293V13.5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 13.5v-12zM3.5 6H2v5h1V9h.5a1.5 1.5 0 100-3zm4 0H6v5h1.5A1.5 1.5 0 009 9.5v-2A1.5 1.5 0 007.5 6zm2.5 5V6h3v1h-2v1h1v1h-1v2h-1z"
                clipRule="evenodd"
            />
        </svg>
    );
};
