import React, { useRef } from "react";

import Translate from "../../../functions/utilFunctions/translate";

interface ICustomInput {
    name: string;
    value: Array<File> | null;
    handleInput: (name: string, value: Array<File> | null) => void;
    required?: boolean;
    errors?: Array<string>;
    className?: string;
    label?: string | JSX.Element;
    labelClass?: string;
    errorClass?: string;
    index?: number;
    multiple?: boolean;
    folder?: boolean;
    accept?: string;
    disabled?: boolean;
}

const _csfi = ({
    name,
    value,
    handleInput,
    required,
    errors,
    className,
    label,
    labelClass,
    errorClass,
    index,
    multiple,
    folder,
    accept,
    disabled,
}: ICustomInput) => {
    const validateInput = () => {
        if (required && !value) {
            handleInput(name, value);
        }
    };

    const inputRef = useRef<any>();

    const handleOnChange = (name: string, val: any) => {
        handleInput(name, val);
    };

    return (
        <>
            <div>
                <input
                    className={`${className ? className : "f-s-16"} d-none`}
                    id={`${name}${index ? "-" + index : ""}`}
                    onChange={(e) => handleOnChange(name, e.target.files)}
                    onBlur={validateInput}
                    type="file"
                    accept={accept}
                    multiple={multiple}
                    ref={inputRef}
                    disabled={disabled}
                />
                <label
                    htmlFor={`${name}${index ? "-" + index : ""}`}
                    className={`btn btn-primary px-20 py-10 btn-label ${labelClass ? labelClass : "f-s-16"} ${
                        disabled ? "disabled" : ""
                    }`}
                >
                    <Translate text={label} />
                </label>
            </div>
            {errors?.map((e, i) => (
                <span className={`error f-s-12 is-warning danger  ${errorClass ? errorClass : ""}`} key={i}>
                    <Translate text={e} />
                </span>
            ))}
        </>
    );
};

export default _csfi;
