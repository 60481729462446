import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import PageHeader from "../../layout/PageHeader";
import Table from "../../devkit/Table/Table";
import { medical_records } from "../../constants/tableHeads";
import ShowMore from "../../devkit/ShowMore/ShowMore";
import { parseDateForMySQL, parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import { paginationLimit } from "../../constants";
import { medicalRecordsAPI } from "../../api/medicalRecordsAPI";
import MainLoader from "../../components/MainLoader";
import Paginate from "../../devkit/Pagination/Pagination";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import Modal from "../../devkit/Modal/Modal";
import useModal from "../../services/CustomHooks/useModal";
import { toast } from "react-toastify";
import { StoreService } from "../../services/StoreService";
import { GlobalContext } from "../../context/Provider";
import FilterSidebar from "../../components/FilterSidebar";
import { useForm } from "react-hook-form";
import { CustomDatePicker, CustomDropdown, CustomForm, CustomInput } from "../../devkit/Form/Form";
import { usersAPI } from "../../api/usersAPI";
import { UtilService } from "../../services/UtilService";
import { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import ExpandableTableCell from "../../devkit/Table/ExpandableTableCell";
import useMount from "../../services/CustomHooks/useMount";

interface IAutor {
    autor_id: number;
}

interface IFile {
    id: number;
    path: string;
}

interface IMedicalRecord {
    id: number;
    title: string;
    description: string;
    ben_id: number;
    created_at: string;
    updated_at?: string;
    autors: IAutor[];
    files: IFile[];
    ben_user: { first_name: string; last_name: string };
}

interface IFormValues {
    filters: {
        search: string;
        search__people: string;
        human_id: number | undefined;
        from: Date | null;
        to: Date | null;
    };
}

const defaultValues = {
    filters: {
        search: "",
        search__people: "",
        human_id: undefined,
        from: null,
        to: null,
    },
};

const MedicalRecords = () => {
    const isMount = useMount();
    const token = StoreService.getStoreProperty("token");
    const role = StoreService.getStoreProperty("role");
    const { hasPermission } = useContext(GlobalContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { params } = useRouteMatch<{ page: string }>();
    const [medicalRecords, setMedicalRecords] = useState<IMedicalRecord[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [contextId, setContextId] = useState<number>();
    const { activeModal, toggleModal } = useModal();
    const [deleteID, setDeleteID] = useState<number>(0);
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [users, setUsers] = useState<IData[]>([]);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "created_at",
        direction: "desc",
    });
    const [descContext, setDescContext] = useState<{
        id: number | null;
        opened: boolean;
        style: { bottom?: string; top?: string };
    }>({
        id: null,
        opened: false,
        style: {},
    });
    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);


    const resetRef = useRef(false);

    const { watch, register, control, setValue, getValues } = useForm<IFormValues>({ defaultValues: defaultValues });

    const { filters } = watch();

    useEffect(() => {
        const getUsers = () => {
            usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    setUsers([...UtilService.getUserOptions(res.data)]);
                }
            });
        };
        if (role !== 2) {
            usersAPI.getAll({ limit: 100, offset: 0, role: 3 }, token).then((res) => {
                if (res && res.success) {
                    setUsers([...users, ...UtilService.getUserOptions(res.data)]);
                }
            });
            if (role !== 3) {
                usersAPI.getAll({ limit: 100, offset: 0, role: 4 }, token).then((res) => {
                    if (res && res.success) {
                        setUsers([...users, ...UtilService.getUserOptions(res.data)]);
                    }
                });
            }
        }

        getAll();
        getUsers();
    }, [params.page]);

    const getNames = (authors: any[]) => {
        const names: any[] = [];
        authors.forEach((a) =>
            names.push(
                <div className={"one-line-text"}>
                    {a.first_name} {a.last_name}
                </div>,
            ),
        );
        return names;
    };

    const redirectToDetails = (id: number) => {
        history.push(`/medical-records/record/${id}`);
    };

    const handleAdd = () => {
        history.push("/medical-records/record/new-entry");
    };

    const getDataForFilters = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        const data: any = {
            offset,
            sorter: sorter.name,
            sorter_direction: sorter.direction,
            search: filters.search,
            search__people: filters.search__people,
        };

        if (filters.human_id) {
            data["human_id"] = filters.human_id;
        }
        if (filters.from) {
            data["from"] = parseDateForMySQL(filters.from!);
        }
        if (filters.to) {
            data["to"] = parseDateForMySQL(filters.to!);
        }
        return data;
    };
    const getAll = () => {
        medicalRecordsAPI.getAll(getDataForFilters(), token).then((res) => {
            if (res && res.success) {
                setMedicalRecords(res.data);
                setTotal(res.total);
                setLoader(false);
            }
        });
    };

    const onPageChange = (page: number) => {
        history.push(`/medical-records/${page}`);
    };

    const setActiveContext = (id: number) => {
        if (contextId === id) {
            setContextId(0);
        } else {
            setContextId(id);
        }
    };

    const handleDelete = () => {
        medicalRecordsAPI.delete(deleteID, token).then((res) => {
            if (res && res.success) {
                toast.success(t("MEDICAL_RECORD_DELETE_SUCCESS"));
                getAll();
            } else {
                toast.error(t("MEDICAL_RECORD_DELETE_FAIL"));
            }
            toggleModal("deleteConfirm");
        });
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getAll();
        }
    }, [sorter]);

    const closeSidebar = () => {
        setSidebarOpened(false);
    };

    const resetFilters = () => {
        setValue("filters", { search: "", search__people: "", human_id: undefined, from: null, to: null });
        setReRenderSidebar(!reRenderSidebar);
        resetRef.current = true;
    };

    useEffect(() => {
        if (resetRef.current && !isMount) {
            getAll();
        }
    }, [resetRef.current]);

    const expandDescription = (e: any, id: number) => {
        e.stopPropagation();
        let style: any = {};
        if (e.pageY > e.view.innerHeight / 2) {
            style.bottom = "5px";
        } else {
            style.top = "5px";
        }

        setDescContext({ id, opened: true, style });
    };

    const closeDescription = (e: any) => {
        e.stopPropagation();

        setDescContext({ id: null, opened: false, style: {} });
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleExportPDF = () => {
        medicalRecordsAPI.exportPDF(getDataForFilters(), token).then(
            (res) => {
                if (res) {
                    let file = new Blob([res]);
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    link.download = "report.pdf";
                    document.body.append(link);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);

                    res && toast.success(t("PDF_EXPORT_SUCCESS"));

                }
            },
        ).catch((e) => {
                toast.error(t("PDF_EXPORT_FAILED"));
                console.log(e);
            },
        );
    };

    return (
        <div className="medical_records">
            <PageHeader
                title={t("MEDICAL_RECORDS")}
                className="mb-30"
                handleAdd={hasPermission("records", "add") ? handleAdd : undefined}
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
                handleRefresh={handleRefresh}
                handleExportPDF={handleExportPDF}
            />
            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                forceReRender={reRenderSidebar}
                submitFilters={getAll}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH"
                            label="SEARCH"
                            inputProps={{ ...register("filters.search") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH_PEOPLE"
                            label="SEARCH_PEOPLE"
                            inputProps={{ ...register("filters.search__people") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.from" label={t("FROM")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.to" label={t("TO")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`mrc-${getValues("filters.human_id")}-id`}

                        />
                    </div>
                </CustomForm>
            </FilterSidebar>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={medical_records} handleSort={handleSorters}>
                        {medicalRecords.map((mr: IMedicalRecord) => {
                            return (
                                <tr onClick={() => redirectToDetails(mr.id)}>
                                    <td className={"one-line-text"}>{mr.title}</td>
                                    <td className={"one-line-text"}>{parseDateWithoutSeconds(mr.created_at)}</td>
                                    <ExpandableTableCell
                                        description={mr.description}
                                        id={mr.id}
                                        closeDescription={closeDescription}
                                        expandDescription={expandDescription}
                                        ctxStatus={descContext}
                                    />
                                    <td
                                        className={"one-line-text"}
                                    >{`${mr.ben_user.first_name} ${mr.ben_user.last_name} `}</td>
                                    <td>{getNames(mr.autors)}</td>
                                    <td>
                                        <ShowMore
                                            multiple
                                            setStatus={() => setActiveContext(mr.id)}
                                            status={contextId === mr.id}
                                        >
                                            {hasPermission("records", "del") && (
                                                <div
                                                    className="delete"
                                                    onClick={() => {
                                                        toggleModal("deleteConfirm");
                                                        setDeleteID(mr.id);
                                                    }}
                                                >
                                                    {t("DELETE")}
                                                </div>
                                            )}

                                            <div onClick={() => redirectToDetails(mr.id)}>{t("DETAILS")}</div>
                                        </ShowMore>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    {medicalRecords && medicalRecords[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}
            <Modal
                name="deleteConfirm"
                toggle={toggleModal}
                className={activeModal === "deleteConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirm"
                    toggle={toggleModal}
                    text="DELETE_MEDICAL_RECORD_CONFIRM"
                    type="warning"
                    title="CONFIRM"
                    deleteItem={handleDelete}
                />
            </Modal>
        </div>
    );
};

export default MedicalRecords;
