import { useRef } from "react";

const CustomHooks = {
    useConstructor: (callBack = () => {}) => {
        const hasBeenCalled = useRef(false);
        if (hasBeenCalled.current) return;
        callBack();
        hasBeenCalled.current = true;
    },
};

export default CustomHooks;
