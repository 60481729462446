import React from "react";
import { useTranslation } from "react-i18next";
import { IReminder } from "../models/interfaces/Reminder";

const DashboardReminder = ({ reminder, onClick }: { reminder: IReminder; onClick: (reminder: IReminder) => void }) => {
    const { t } = useTranslation();

    const printFrequency = () => {
        switch (reminder.frequency) {
            case 1:
                return t("EVERY_DAY");
            case 2:
                return t("EVERY_WEEK");
            case 3:
                return t("EVERY_MONTH");
            case 4:
                return t("EVERY_YEAR");
        }
    };

    return (
        <div className="dashboard-reminder d-flex flex-column" onClick={() => onClick(reminder)}>
            <div className="d-flex justify-content-between">
                <span className="name">
                    {reminder.ben_user.first_name} {reminder.ben_user.last_name}
                </span>
                <span className="frequency">
                    <span>
                        {t("FREQUENCY")}
                        {": "}
                    </span>
                    {printFrequency()}
                </span>
            </div>
            <div className="title one-line-text">{reminder.title}</div>
        </div>
    );
};

export default DashboardReminder;
