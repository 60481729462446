import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../CustomForm/CustomForm";

interface ICustomInput {
    className?: string;
    label?: string;
    labelClass?: string;
    placeholder?: string;
    textAreaClassName?: string;
    inputProps: any;
    maxLength?: number;
    id: string;
    error?: string | undefined;
    resizable?: boolean;
    disabled?: boolean;
}

const _cta: React.FC<ICustomInput> = ({
    label,
    labelClass,
    placeholder,
    className,
    error,
    textAreaClassName,
    inputProps,
    maxLength,
    id,
    resizable,
    disabled,
}: // handleFile,
ICustomInput) => {
    const { t } = useTranslation();
    const isForm = useFormContext();
    const [characters, setCharacters] = useState<number>(0);

    if (!isForm) {
        throw Error("You can't use CustomTextArea outside of CustomForm component!");
    }

    const characterCount = (e: any) => {
        setCharacters(e.target.value.length);
    };

    return (
        <div className="textAreaComponent inputComponent">
            <div className={`inputWrapper textArea hasText ${className ? className : ""}`}>
                <label className={`${labelClass ? labelClass : ""}`}>{label && t(label)}</label>
                <textarea
                    placeholder={placeholder || ""}
                    className={` ${textAreaClassName ? textAreaClassName : ""} p-10 d-flex flex-column ${
                        resizable ? "" : "non-resizable"
                    } w-100perc`}
                    {...inputProps}
                    maxLength={maxLength}
                    onChange={characterCount}
                    disabled={disabled}
                />
                <span className="count f-s-10 px-5 mr-15">
                    {t("CHARACTER_COUNT")}:{" "}
                    <b id={`${id}`}>
                        {characters}
                        {maxLength ? `/${maxLength}` : ""}
                    </b>
                </span>
                {error && <span className="error f-s-13 is-warning danger mt-5">{t(error)}</span>}
            </div>
        </div>
    );
};

export default _cta;
