import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import remindersAPI from "../../api/remindersAPI";
import { usersAPI } from "../../api/usersAPI";
import FilterSidebar from "../../components/FilterSidebar";
import MainLoader from "../../components/MainLoader";
import { frequencyOptions, paginationLimit } from "../../constants";
import { remindersTHeads } from "../../constants/tableHeads";
import { GlobalContext } from "../../context/Provider";
import { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import { CustomDropdown, CustomForm, CustomInput } from "../../devkit/Form/Form";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import Paginate from "../../devkit/Pagination/Pagination";
import ShowMore from "../../devkit/ShowMore/ShowMore";
import ExpandableTableCell from "../../devkit/Table/ExpandableTableCell";
import Table from "../../devkit/Table/Table";
import { parseDate, parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import PageHeader from "../../layout/PageHeader";
import { IReminder } from "../../models/interfaces/Reminder";
import useModal from "../../services/CustomHooks/useModal";
import useMount from "../../services/CustomHooks/useMount";
import { StoreService } from "../../services/StoreService";
import { UtilService } from "../../services/UtilService";

interface IFormValues {
    filters: {
        search: string;
        human_id: number | undefined;
        search__people: string;
    };
}

const defaultValues = {
    filters: {
        search: "",
        human_id: undefined,
        search__people: "",
    },
};

const Reminders = () => {
    const isMount = useMount();
    const { hasPermission } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const { activeModal, toggleModal } = useModal();
    const { params } = useRouteMatch<{ page: string }>();
    const history = useHistory();

    const [reminders, setReminders] = useState<IReminder[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<number>(0);
    const [contextId, setContextId] = useState<number>();
    const [loader, setLoader] = useState<boolean>(true);

    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [users, setUsers] = useState<IData[]>([]);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "created_at",
        direction: "desc",
    });
    const [descContext, setDescContext] = useState<{
        id: number | null;
        opened: boolean;
        style: { bottom?: string; top?: string };
    }>({
        id: null,
        opened: false,
        style: {},
    });

    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);

    const resetRef = useRef(false);

    const { watch, register, control, setValue, getValues } = useForm<IFormValues>({ defaultValues: defaultValues });

    const { filters } = watch();

    const getReminders = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        const data: any = {
            offset,
            sorter: sorter.name,
            sorter_direction: sorter.direction,
            search: filters.search,
            search__people: filters.search__people,
        };

        if (filters.human_id) {
            data["human_id"] = filters.human_id;
        }

        remindersAPI.getAll(data, token).then((res) => {
            if (res && res.success) {
                setReminders(res.data);
                setTotal(res.total);
                setLoader(false);
            }
        });
    };

    useEffect(() => {
        const getUsers = () => {
            usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    setUsers(UtilService.getUserOptions(res.data));
                }
            });
            // usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
            //     if (res && res.success) {
            //         const beneficiaries = res.data;
            //         usersAPI.getAll({ limit: 100, offset: 0, role: 4 }, token).then((res) => {
            //             if (res && res.success) {
            //                 const admins = res.data;
            //                 usersAPI.getAll({ limit: 100, offset: 0, role: 3 }, token).then((res) => {
            //                     if (res && res.success) {
            //                         setUsers([
            //                             ...UtilService.getUserOptions(res.data),
            //                             ...UtilService.getUserOptions(admins),
            //                             ...UtilService.getUserOptions(beneficiaries),
            //                         ]);
            //                     }
            //                 });
            //             }
            //         });
            //     }
            // });
        };
        getUsers();
        getReminders();
    }, []);

    const redirectToDetails = (id: number) => {
        const path = history.location.pathname.split("/");
        path.pop();
        history.push(path.join("/") + `/reminder/${id}`);
    };

    const onPageChange = (page: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/${page}`);
    };

    const addReminder = () => {
        const path = history.location.pathname.split("/");
        path.pop();
        history.push(path.join("/") + `/reminder/new-entry`);
    };

    const handleDelete = () => {
        remindersAPI.delete(deleteId, token).then((res) => {
            if (res && res.success) {
                toast.success(t("REMINDER_DELETE_SUCCESS"));
                toggleModal("");
                getReminders();
            } else {
                toast.error(t("REMINDER_DELETE_FAIL"));
            }
        });
    };

    const setActiveContext = (id: number) => {
        if (contextId === id) {
            setContextId(0);
        } else {
            setContextId(id);
        }
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getReminders();
        }
    }, [sorter]);

    const closeSidebar = () => {
        setSidebarOpened(false);
    };

    const resetFilters = () => {
        setValue("filters", { search: "", human_id: undefined, search__people: "" });
        setReRenderSidebar(!reRenderSidebar);
        resetRef.current = true;
    };

    useEffect(() => {
        if (resetRef.current && !isMount) {
            getReminders();
        }
    }, [resetRef.current]);

    const expandDescription = (e: any, id: number) => {
        e.stopPropagation();
        let style: any = {};
        if (e.pageY > e.view.innerHeight / 2) {
            style.bottom = "5px";
        } else {
            style.top = "5px";
        }

        setDescContext({ id, opened: true, style });
    };

    const closeDescription = (e: any) => {
        e.stopPropagation();
        setDescContext({ id: null, opened: false, style: {} });
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="reminders">
            <PageHeader
                title={t("REMINDERS")}
                handleAdd={hasPermission("reminders", "add") ? addReminder : undefined}
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
                handleRefresh={handleRefresh}
            />
            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                submitFilters={getReminders}
                forceReRender={reRenderSidebar}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH"
                            label="SEARCH"
                            inputProps={{ ...register("filters.search") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH_PEOPLE"
                            label="SEARCH_PEOPLE"
                            inputProps={{ ...register("filters.search__people") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`rem-${getValues("filters.human_id")}-id`}

                        />
                    </div>
                </CustomForm>
            </FilterSidebar>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={remindersTHeads} handleSort={handleSorters}>
                        {reminders.map((reminder: IReminder) => {
                            return (
                                <tr
                                    onClick={() => {
                                        redirectToDetails(reminder.id);
                                    }}
                                >
                                    <td>{reminder.title}</td>
                                    <td>{`${reminder.ben_user.first_name} ${reminder.ben_user.last_name}`}</td>
                                    <td>{parseDateWithoutSeconds(reminder.scheduled_at)}</td>
                                    <td>{parseDate(reminder.created_at)}</td>
                                    <ExpandableTableCell
                                        description={reminder.description}
                                        id={reminder.id}
                                        closeDescription={closeDescription}
                                        expandDescription={expandDescription}
                                        ctxStatus={descContext}
                                    />
                                    <td>{t(frequencyOptions.find((f) => f.value === reminder.frequency)!.label)}</td>
                                    <td>{`${reminder.autor_user.first_name} ${reminder.autor_user.last_name}`}</td>
                                    {/* TODO ovdje da se koristi neka funkcija da prikaze tacno vrijeme */}

                                    <td>
                                        <ShowMore
                                            multiple
                                            setStatus={() => setActiveContext(reminder.id)}
                                            status={contextId === reminder.id}
                                        >
                                            <div
                                                className="delete"
                                                onClick={() => {
                                                    toggleModal("deleteConfirm");
                                                    setDeleteId(reminder.id);
                                                }}
                                            >
                                                {t("DELETE")}
                                            </div>

                                            <div onClick={() => redirectToDetails(reminder.id)}>{t("UPDATE")}</div>
                                        </ShowMore>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    {reminders && reminders[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}

            <Modal
                name="deleteConfirm"
                toggle={toggleModal}
                className={activeModal === "deleteConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirm"
                    toggle={toggleModal}
                    text="REMINDER_DELETE_CONFIRM"
                    type="warning"
                    title="CONFIRM"
                    deleteItem={handleDelete}
                />
            </Modal>
        </div>
    );
};

export default Reminders;
