import { DevTool } from "@hookform/devtools";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import medReportsAPI from "../../api/medReportsAPI";
import { usersAPI } from "../../api/usersAPI";
import FilterSidebar from "../../components/FilterSidebar";
import MainLoader from "../../components/MainLoader";
import { paginationLimit } from "../../constants";
import { medicalReportsTHeads } from "../../constants/tableHeads";
import { GlobalContext } from "../../context/Provider";
import CustomDropdown, { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import { CustomDatePicker, CustomForm, CustomInput } from "../../devkit/Form/Form";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import Paginate from "../../devkit/Pagination/Pagination";
import ShowMore from "../../devkit/ShowMore/ShowMore";
import ExpandableTableCell from "../../devkit/Table/ExpandableTableCell";
import Table from "../../devkit/Table/Table";
import { parseDate, parseDateForMySQL } from "../../functions/utilFunctions/parsingFunctions";
import PageHeader from "../../layout/PageHeader";
import { IReport } from "../../models/interfaces/MedReport";
import useModal from "../../services/CustomHooks/useModal";
import useMount from "../../services/CustomHooks/useMount";
import { StoreService } from "../../services/StoreService";
import { UtilService } from "../../services/UtilService";

interface IFormValues {
    filters: {
        search: string;
        search__people: string;
        human_id: number | undefined;
        from: Date | null;
        to: Date | null;
    };
}

const defaultValues = {
    filters: {
        search: "",
        search__people: "",
        human_id: undefined,
        from: null,
        to: null,
    },
};

const MedicalReports = () => {
    const isMount = useMount();
    const { hasPermission } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const { activeModal, toggleModal } = useModal();
    const { params } = useRouteMatch<{ page: string }>();
    const history = useHistory();

    const [reports, setReports] = useState<IReport[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<number>(0);
    const [contextId, setContextId] = useState<number>();
    const [loader, setLoader] = useState<boolean>(true);
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [users, setUsers] = useState<IData[]>([]);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "created_at",
        direction: "desc",
    });
    const [descContext, setDescContext] = useState<{
        id: number | null;
        opened: boolean;
        style: { bottom?: string; top?: string };
    }>({
        id: null,
        opened: false,
        style: {},
    });
    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);


    const resetRef = useRef(false);

    const { watch, register, control, setValue, getValues } = useForm<IFormValues>({ defaultValues: defaultValues });

    const { filters } = watch();

    const getDataForFilters = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        const data: any = {
            offset,
            sorter: sorter.name,
            sorter_direction: sorter.direction,
            search: filters.search,
            search__people: filters.search__people,
        };

        if (filters.human_id) {
            data["human_id"] = filters.human_id;
        }
        if (filters.from) {
            data["from"] = parseDateForMySQL(filters.from!);
        }
        if (filters.to) {
            data["to"] = parseDateForMySQL(filters.to!);
        }
        return data;
    };

    const getReports = () => {
        medReportsAPI.getAll(getDataForFilters(), token).then((res) => {
            if (res && res.success) {
                setReports(res.data);
                setTotal(res.total);
                setLoader(false);
                if (resetRef.current) {
                    resetRef.current = false;
                }
            }
        });
    };

    useEffect(() => {
        const getUsers = () => {
            usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    const beneficiaries = res.data;

                    setUsers([...UtilService.getUserOptions(beneficiaries)]);
                }
            });
        };

        getUsers();
        getReports();
    }, [params.page]);

    const redirectToDetails = (id: number) => {
        const path = history.location.pathname.split("/");
        path.pop();
        history.push(path.join("/") + `/report/${id}`);
    };

    const onPageChange = (page: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/${page}`);
    };

    const addReport = () => {
        const path = history.location.pathname.split("/");
        path.pop();
        history.push(path.join("/") + `/report/new-report`);
    };

    const handleDelete = () => {
        medReportsAPI.delete(deleteId, token).then((res) => {
            if (res && res.success) {
                toast.success(t("REPORT_DELETE_SUCCESS"));
                toggleModal("");
                getReports();
            } else {
                toast.error(t("REPORT_DELETE_FAIL"));
            }
        });
    };

    const setActiveContext = (id: number) => {
        if (contextId === id) {
            setContextId(0);
        } else {
            setContextId(id);
        }
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getReports();
        }
    }, [sorter]);

    const closeSidebar = () => {
        setSidebarOpened(false);
    };

    const resetFilters = () => {
        setValue("filters", { search: "", search__people: "", human_id: undefined, from: null, to: null });
        setReRenderSidebar(!reRenderSidebar);
        resetRef.current = true;
    };

    useEffect(() => {
        if (resetRef.current) {
            getReports();
        }
    }, [resetRef.current]);

    const expandDescription = (e: any, id: number) => {
        e.stopPropagation();
        let style: any = {};
        if (e.pageY > e.view.innerHeight / 2) {
            style.bottom = "5px";
        } else {
            style.top = "5px";
        }

        setDescContext({ id, opened: true, style });
    };

    const closeDescription = (e: any) => {
        e.stopPropagation();

        setDescContext({ id: null, opened: false, style: {} });
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleExportPDF = () => {
        medReportsAPI.exportPDF(getDataForFilters(), token).then(
            (res) => {
                if (res) {
                    let file = new Blob([res]);
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    link.download = "report.pdf";
                    document.body.append(link);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);

                    res && toast.success(t("PDF_EXPORT_SUCCESS"));

                }
            },
        ).catch((e) => {
                toast.error(t("PDF_EXPORT_FAILED"));
                console.log(e);
            },
        );
    };

    return (
        <div className="medical-reports">
            <PageHeader
                title={t("REPORTS")}
                handleAdd={hasPermission("reports", "add") ? addReport : undefined}
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
                handleRefresh={handleRefresh}
                handleExportPDF={handleExportPDF}
            />
            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                forceReRender={reRenderSidebar}
                submitFilters={getReports}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH"
                            label="SEARCH"
                            inputProps={{ ...register("filters.search") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH_PEOPLE"
                            label="SEARCH_PEOPLE"
                            inputProps={{ ...register("filters.search__people") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.from" label={t("FROM")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.to" label={t("TO")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`mrd-${getValues("filters.human_id")}-id`}

                        />
                    </div>
                </CustomForm>
            </FilterSidebar>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={medicalReportsTHeads} handleSort={handleSorters}>
                        {reports.map((report: IReport) => {
                            return (
                                <tr onClick={() => redirectToDetails(report.id)}>
                                    <td>{report.title}</td>
                                    <td>{parseDate(report.created_at)}</td>
                                    <ExpandableTableCell
                                        description={report.description}
                                        id={report.id}
                                        closeDescription={closeDescription}
                                        expandDescription={expandDescription}
                                        ctxStatus={descContext}
                                    />
                                    <td>
                                        {report.autors[0] && (
                                            <span>
                                                {report.autors[0].first_name} {report.autors[0].last_name}
                                                {report.autors.length > 1 && " ..."}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {report.ben_users[0] && (
                                            <span>
                                                {report.ben_users[0].first_name} {report.ben_users[0].last_name}
                                                {report.ben_users.length > 1 && " ..."}
                                            </span>
                                        )}
                                    </td>
                                    <td>{report.files.length}</td>
                                    <td>
                                        <ShowMore
                                            multiple
                                            setStatus={() => setActiveContext(report.id)}
                                            status={contextId === report.id}
                                        >
                                            {hasPermission("reports", "del") && (
                                                <div
                                                    className="delete"
                                                    onClick={() => {
                                                        toggleModal("deleteConfirm");
                                                        setDeleteId(report.id);
                                                    }}
                                                >
                                                    {t("DELETE")}
                                                </div>
                                            )}

                                            <div onClick={() => redirectToDetails(report.id)}>{t("DETAILS")}</div>
                                        </ShowMore>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    {reports && reports[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}

            <Modal
                name="deleteConfirm"
                toggle={toggleModal}
                className={activeModal === "deleteConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirm"
                    toggle={toggleModal}
                    text="REPORT_DELETE_CONFIRM"
                    type="warning"
                    title="REPORT_DELETE"
                    deleteItem={handleDelete}
                />
            </Modal>
            <DevTool control={control} />
        </div>
    );
};

export default MedicalReports;
