import { DevTool } from "@hookform/devtools";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import remindersAPI from "../../api/remindersAPI";
import { usersAPI } from "../../api/usersAPI";
import MainLoader from "../../components/MainLoader";
import { frequencyOptions } from "../../constants";
import { GlobalContext } from "../../context/Provider";
import { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import { CustomDateTimePicker, CustomDropdown, CustomForm, CustomInput, CustomTextArea } from "../../devkit/Form/Form";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import DetailsHeader from "../../layout/DetailsHeader";
import { IReminder } from "../../models/interfaces/Reminder";
import { IUser } from "../../models/interfaces/User";
import useModal from "../../services/CustomHooks/useModal";
import { StoreService } from "../../services/StoreService";

interface IFormValues {
    title: string;
    description: string;
    scheduled_at: Date | string | null;
    ben_id: number | null;
    frequency: number;
    id?: number;
}

const ReminderDetails = () => {
    const { hasPermission } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { toggleModal, activeModal } = useModal();
    const history = useHistory();
    const { params } = useRouteMatch<{ id: string }>();
    const { t } = useTranslation();
    const [reminder, setReminder] = useState<IReminder>();
    const [userOptions, setUserOptions] = useState<IData[]>([]);
    const [isNew] = useState<boolean>(!params.id);
    const [loader, setLoader] = useState<boolean>(true);

    // const toastId = useRef<any>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
    } = useForm<IFormValues>({
        defaultValues: {
            title: "",
            description: "",
            scheduled_at: null,
            ben_id: null,
            frequency: 1,
        },
    });

    const getBenUsers = () => {
        const token = StoreService.getStoreProperty("token");
        usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
            if (res && res.success) {
                setUserOptions(
                    res.data.map((user: IUser) => {
                        return { value: user.id, label: `${user.first_name} ${user.last_name}` };
                    }),
                );
            }
        });
    };

    useEffect(() => {
        const getReminder = () => {
            remindersAPI.get(parseInt(params.id), token).then((res) => {
                if (res && res.success) {
                    setReminder(res.data);
                    setValue("title", res.data.title);
                    setValue("description", res.data.description);
                    setValue("scheduled_at", new Date(res.data.scheduled_at));
                    setValue("frequency", res.data.frequency);
                    setValue("ben_id", res.data.ben_id);
                    setLoader(false);
                }
            });
        };

        if (!isNew) {
            getReminder();
        } else {
            setLoader(false);
            getBenUsers();
        }
    }, []);

    const handleSave = () => {
        const data: any = {
            title: getValues("title"),
            description: getValues("description"),
            ben_id: getValues("ben_id"),
            frequency: getValues("frequency"),
            scheduled_at: getValues("scheduled_at"),
        };

        if (isNew) {
            console.log(data);
            remindersAPI.create(data, token).then((res) => {
                if (res && res.success) {
                    toast.success(t("REMINDER_CREATE_SUCCESS"));
                    redirectToDetails(res.data.id);
                } else {
                    toast.error(t("REMINDER_CREATE_FAIL"));
                }
            });
        } else {
            data["id"] = parseInt(params.id);
            remindersAPI.update(data, token).then((res) => {
                if (res && res.success) {
                    toast.success(t("REMINDER_UPDATE_SUCCESS"));
                } else {
                    toast.error(t("REMINDER_UPDATE_FAIL"));
                }
            });
        }
    };

    const redirectToDetails = (id: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/${id}`);
    };

    const handleDelete = () => {
        remindersAPI.delete(parseInt(params.id), token).then((res) => {
            if (res && res.success) {
                toast.success(t("REMINDER_DELETE_SUCCESS"));
                returnToReminderList();
            } else {
                toast.error(t("REMINDER_DELETE_FAIL"));
            }
        });
    };

    const returnToReminderList = () => {
        history.push(`/reminders/1`);
    };

    return (
        <div className="reminder-details details-screen">
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <DetailsHeader
                        title={isNew ? `${watch("title")}` || t("TITLE") : `${reminder?.title}`}
                        handleDelete={
                            hasPermission("reminders", "del")
                                ? isNew
                                    ? undefined
                                    : () => toggleModal("deleteConfirm")
                                : undefined
                        }
                        handleSave={hasPermission("reminders", "edit") ? () => handleSubmit(handleSave)() : undefined}
                        subHeader={
                            isNew ? undefined : `${reminder?.ben_user?.first_name} ${reminder?.ben_user?.last_name}`
                        }
                    />
                    <CustomForm handleSubmit={handleSubmit(handleSave)} className="mt-80 d-flex">
                        <div className="d-flex flex-column">
                            <CustomInput
                                inputProps={{ ...register("title", { required: "INPUT_REQUIRED" }) }}
                                placeholder={t("TITLE")}
                                label={t("TITLE")}
                                type="text"
                                className="mb-45"
                                error={errors.title?.message}
                            ></CustomInput>
                            <CustomTextArea
                                inputProps={{ ...register("description", { required: "INPUT_REQUIRED" }) }}
                                placeholder={t("DESCRIPTION")}
                                label={t("DESCRIPTION")}
                                id={"description"}
                                error={errors.description?.message}
                            ></CustomTextArea>
                        </div>
                        <div className="d-flex flex-column">
                            {isNew && (
                                <div className="mb-45">
                                    <CustomDropdown
                                        name="ben_id"
                                        data={userOptions}
                                        label="BENEFICIARY"
                                        control={control}
                                        rules={{ required: "INPUT_REQUIRED" }}
                                        error={errors.ben_id?.message}
                                        placeholder="SELECT_BENEFICIARY"
                                    />
                                </div>
                            )}
                            <div className="mb-45">
                                <CustomDropdown
                                    name="frequency"
                                    data={frequencyOptions.map((fo) => ({ value: fo.value, label: t(fo.label) }))}
                                    label="FREQUENCY"
                                    control={control}
                                    rules={{ required: "INPUT_REQUIRED" }}
                                    error={errors.frequency?.message}
                                    placeholder="SELECT_FREQUENCY"
                                />
                            </div>
                            <CustomDateTimePicker
                                control={control}
                                label={t("SCHEDULED_AT")}
                                className="mb-45"
                                name="scheduled_at"
                                rules={{ required: "INPUT_REQUIRED" }}
                            ></CustomDateTimePicker>
                        </div>
                    </CustomForm>
                    {!isNew && (
                        <div className="mt-30 author">
                            <span>{t("AUTHOR")}:</span>
                            <span>
                                {reminder && `${reminder.autor_user.first_name} ${reminder.autor_user.last_name}`}
                            </span>
                        </div>
                    )}
                </>
            )}
            <DevTool control={control} />

            <Modal
                name="deleteConfirm"
                toggle={toggleModal}
                className={activeModal === "deleteConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirm"
                    toggle={toggleModal}
                    text="REMINDER_DELETE_CONFIRM"
                    type="warning"
                    title="CONFIRM"
                    deleteItem={handleDelete}
                ></ModalContent>
            </Modal>
        </div>
    );
};

export default ReminderDetails;
