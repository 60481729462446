import { LOGOUT_USER, VALIDATE_TOKEN } from "../../constants/actionTypes";
import authApi from "../../api/authAPI";
import { StoreService } from "../../services/StoreService";
import { usersAPI } from "../../api/usersAPI";
import { FirebaseService } from "../../services/FirebaseService";

const validateToken = () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
        type: VALIDATE_TOKEN,
    });
    const token = StoreService.getStoreProperty("token");

    try {
        authApi.validateToken(token).then((response: any) => {
            if (response && response.success) {
                usersAPI.getOnlineUsers(token).then((res) => {
                    dispatch({
                        type: VALIDATE_TOKEN,
                        payload: { ...response.data, online_users: res.data },
                    });

                    FirebaseService.initializeFirebase();

                    StoreService.updateStoreProperty("isLoggedIn", true);
                    StoreService.updateStoreProperty("id", response.data.user.id);
                    StoreService.updateStoreProperty("user", response.data.user);
                    // TODO Rjesiti slucaj sa id-em kod myprofile kad se osvjezi strana
                });
            } else {
                dispatch({
                    type: LOGOUT_USER,
                });
                StoreService.updateStoreProperty("token", "");
                StoreService.updateStoreProperty("isLoggedIn", false);
                StoreService.updateStoreProperty("isPinValid", false);
                StoreService.updateStoreProperty("id", "");
                StoreService.updateStoreProperty("user", {});
            }
        });
    } catch (e) {
        dispatch({
            type: LOGOUT_USER,
        });
        StoreService.updateStoreProperty("token", "");
        StoreService.updateStoreProperty("isLoggedIn", false);
        StoreService.updateStoreProperty("isPinValid", false);
        StoreService.updateStoreProperty("id", "");
        StoreService.updateStoreProperty("user", {});
    }
};

export default validateToken;
