import React, { createContext, useEffect, useReducer } from "react";
import auth from "./reducers/mainReducer";
import authInitialState from "./initialStates/store";
import { permissions } from "../constants";
import initStore from "./actions/init";
import CustomHooks from "../services/CustomHooks";
import { StoreService } from "../services/StoreService";

export const GlobalContext = createContext({} as any);

const GlobalProvider = ({ children }: any) => {
    const [store, storeDispatch] = useReducer(auth, authInitialState);

    CustomHooks.useConstructor(() => {
        StoreService.initialize();
        initStore()(storeDispatch);
    });

    const getFullName = (id: number) => {
        const users =
            store.data && store.data.dashboard_data
                ? [
                      ...store.data.dashboard_data.med_users.list,
                      ...store.data.dashboard_data.ben_users.list,
                      ...store.data.dashboard_data.fandf_users.list,
                  ]
                : [];
        const user = users.find((user: any) => user.id === id);
        return user && `${user.first_name} ${user.last_name}`;
    };

    const hasPermission = (entity: string, action: string, userType: number) => {
        const role: number = store.data?.user?.role;
        if (role) {
            if (role === 4) {
                return true;
            } else if (entity === "users" && action === "read" && (role === 2 || role === 3) && userType) {
                return permissions[role][entity][action][userType];
            } else {
                return permissions[role][entity][action];
            }
        }
    };

    const canCall = (notificationId: string) => {
        return (
            store.data?.online_users?.map((onlineUser: any) => onlineUser.notification_id).includes(notificationId) &&
            notificationId
        );
    };

    return (
        <GlobalContext.Provider value={{ store, storeDispatch, getFullName, hasPermission, canCall }}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
