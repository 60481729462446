import React from "react";
import { useTranslation } from "react-i18next";
import "./UserFlag.scss";

interface IUserFlag {
    type: string;
    status: boolean;
    className?: string;
}

const UserFlag = ({ type, status, className }: IUserFlag) => {
    const { t } = useTranslation();

    const getText = () => {
        if (type === "isOnline") {
            return status ? t("ONLINE") : t("OFFLINE");
        } else {
            return t("DEACTIVATED");
        }
    };
    const getCircleColor = () => {
        if (type === "isOnline") {
            switch (status) {
                case true:
                    return "green";
                case false:
                    return "grey";
            }
        } else {
            return "red";
        }
    };

    return (
        <div
            className={`user-flag d-flex align-items-center ${className ? className : ""}`}
            style={{ borderColor: getCircleColor() }}
        >
            <div className="circle mr-5" style={{ backgroundColor: getCircleColor() }}></div>
            <span className={`${type} name`}>{getText()}</span>
        </div>
    );
};

export default UserFlag;
