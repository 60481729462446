const constDebounceThrottle = {} as any;

export const handleDebouncing = (callbackKey: string | number, callback: any, period: number) => {
    return new Promise(() => {
        function interval(callbackObject: any) {
            clearInterval(callbackObject.interval);
            callbackObject.callback();
        }

        if (constDebounceThrottle[callbackKey]) {
            clearInterval(constDebounceThrottle[callbackKey].interval);
        }

        constDebounceThrottle[callbackKey] = {
            duration: period,
            interval: setInterval(() => {
                interval(constDebounceThrottle[callbackKey]);
            }, period),
            callback: callback,
        };
    });
};

const throttleCallback = {} as any;

export const handleThrottle = (callbackKey: string | number, callback: () => void, period: number) => {
    return new Promise(() => {
        function timeout(callbackKey: string | number) {
            delete throttleCallback[callbackKey];
        }

        if (throttleCallback[callbackKey]) {
            return;
        }

        callback();
        // @ts-ignore
        throttleCallback[callbackKey] = {
            duration: period,
            interval: setTimeout(() => {
                timeout(callbackKey);
            }, period),
            callback: callback,
        };
    });
};
