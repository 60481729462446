import React from "react";
import CustomButton from "../Form/CustomButton/CustomButton";
import { SuccessIcon, WarningIcon, YellowQuestionMarkIcon } from "../../components/icons";
import { useTranslation } from "react-i18next";

interface IModalContent {
    type: "success" | "warning" | "failure" | "question" | "clone" | "iframe";
    name: string;
    item?: any;
    text?: string;
    iframe?: string;
    title: string;
    buttonText?: string;
    toggle: (name: string) => void;
    deleteItem?: (item?: any) => void;
    cancelDelete?: (name: string) => void;
    onConfirm?: () => void;
}

export const ModalContent = ({
    type,
    name,
    title,
    item,
    toggle,
    text,
    iframe,
    buttonText,
    deleteItem,
    cancelDelete,
    onConfirm,
}: IModalContent) => {
    const { t } = useTranslation();
    return (
        <div className={`${type === 'iframe' ? 'iframe-modal' : ''} modal-content d-flex flex-column align-items-center justify-content-between min-h-200`}>
            {!!title && <span className="title text-center w-100perc f-s-18 uppercase py-10">{t(title)}</span>}

            {
                !!text && (<div className="content d-flex f-s-16 px-10 align-items-center">
                    {(type === "warning" || type === "failure") && (
                      <WarningIcon width={70} className="mr-30 fill-important" />
                    )}
                    {type === "success" && <SuccessIcon width={60} className="mr-30" />}
                    {type === "clone" && <WarningIcon width={80} className="mr-30" />}
                    {type === "question" && <YellowQuestionMarkIcon width={80} height={20} className="mr-30" />}
                    <div className="modalText">{t(text)}</div>
                </div>)
            }

            {
                !text && type === 'iframe' && !!iframe && (<div className="content">
                    <iframe src={iframe}/>
                </div>)
            }

            {type === "warning" && (
                <div className="modal-buttons d-flex justify-content-center w-100perc pt-10">
                    <CustomButton
                        className="btn-delete round w-150 mr-10"
                        type="button"
                        onClick={() => deleteItem && deleteItem(item)}
                    >
                        {buttonText ? t(buttonText) : t("DELETE")}
                    </CustomButton>
                    <CustomButton
                        className="btn-cancel"
                        type="button"
                        onClick={() => (cancelDelete ? cancelDelete(name) : toggle(name))}
                    >
                        {t("CANCEL")}
                    </CustomButton>
                </div>
            )}
            {type === "failure" && (
                <div className="modal-buttons d-flex justify-content-center w-100perc pt-10">
                    <CustomButton
                        className="btn-back-details w-150"
                        type="button"
                        onClick={() => toggle && toggle(name)}
                    >
                        {t("OK")}
                    </CustomButton>
                </div>
            )}
            {type === "success" && (
                <div className="modal-buttons d-flex justify-content-center w-100perc pt-10">
                    <CustomButton
                        className="btn btn-ok w-150"
                        type="button"
                        onClick={onConfirm ? onConfirm : () => toggle && toggle(name)}
                    >
                        {t("OK")}
                    </CustomButton>
                </div>
            )}
            {type === "question" && (
                <div className="modal-buttons d-flex justify-content-center w-100perc pt-10">
                    <CustomButton
                        className="btn-primary round w-150 mr-20"
                        type="button"
                        onClick={() => onConfirm && onConfirm()}
                    >
                        {t("YES")}
                    </CustomButton>
                    <CustomButton className="btn-cancel" type="button" onClick={() => toggle && toggle(name)}>
                        {t("NO")}
                    </CustomButton>
                </div>
            )}
        </div>
    );
};
