import React, { useState, useEffect } from "react";

const Timer = () => {
    const [second, setSecond] = useState<string>("00");
    const [minute, setMinute] = useState<string>("00");
    const [isActive, setIsActive] = useState<boolean>(true);
    const [counter, setCounter] = useState<number>(0);

    useEffect(() => {
        let intervalId: any;

        if (isActive) {
            intervalId = setInterval(() => {
                const secondCounter: number = counter % 60;
                const minuteCounter: number = Math.floor(counter / 60);

                const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
                const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

                setSecond(computedSecond.toString());
                setMinute(computedMinute.toString());

                setCounter((counter) => counter + 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isActive, counter]);

    return (
        <div className="timer">
            <div className="time">
                <span className="minute">{minute}</span>
                <span>:</span>
                <span className="second">{second}</span>
            </div>
        </div>
    );
};

export default Timer;
