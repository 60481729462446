import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface MainLayoutProps {
    children: JSX.Element[] | JSX.Element;
}

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <div className="layout">
            <Header></Header>
            <Sidebar></Sidebar>
            <main>{children}</main>
        </div>
    );
};

export default MainLayout;
