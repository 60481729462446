import React, { useState, useContext, useEffect } from "react";
import { CustomForm, CustomButton, PinCode } from "../../devkit/Form/Form";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/Provider";
import logoutUser from "../../context/actions/logoutUser";
import Translate from "../../functions/utilFunctions/translate";
import pinLogin from "../../context/actions/pinLogin";

const Pin = () => {
    const [pin, setPin] = useState<any>(["", "", "", ""]);
    const { t } = useTranslation();

    const { storeDispatch } = useContext(GlobalContext);

    const handleInput = (pin: string[]) => {
        setPin(pin);
    };

    useEffect(() => {
        const handlePinValidation = () => {
            pinLogin({ pin })(storeDispatch);
            setPin(["", "", "", ""]);
        };

        const newArray = pin.filter((num: string) => num !== "");
        if (newArray.length === 4) {
            handlePinValidation();
        }
    }, [pin]);

    return (
        <div className={"pin d-flex h-100-perc min-h-vh-100 w-100-perc"}>
            <div className={"pin-background w-50-perc"}></div>
            <div className={"form-container d-flex w-50-perc justify-content-center align-items-center"}>
                <CustomForm className={"d-flex flex-column align-items-center w-100-perc"}>
                    <span className={"f-s-60 bold pb-40 text-color-primary"}>Telecare</span>
                    <span className={"f-s-15 pb-10"}>
                        {" "}
                        <Translate text="ENTER_PIN" />
                    </span>
                    <PinCode value={pin} handleInput={handleInput}></PinCode>
                    <div className={"d-flex flex-column align-items-center justify-content-center pt-20"}>
                        <CustomButton
                            onClick={() => logoutUser()(storeDispatch)}
                            type={"button"}
                            className={"btn-save"}
                        >
                            {t("BACK_TO_LOGIN")}
                        </CustomButton>
                    </div>
                </CustomForm>
            </div>
        </div>
    );
};

export default Pin;
