import { LOGOUT_USER } from "../../constants/actionTypes";
import authApi from "../../api/authAPI";
import { StoreService } from "../../services/StoreService";
import { FirebaseService } from "../../services/FirebaseService";

const logoutUser = () => async (dispatch: (arg0: { type: string }) => void) => {
    const token = StoreService.getStoreProperty("token");
    console.log("logout");
    await FirebaseService.sendOnlineStatusNotif(false);

    authApi.logout(token).then(() => {
        StoreService.updateStoreProperty("isLoggedIn", false);
        StoreService.updateStoreProperty("role", "");
        StoreService.updateStoreProperty("id", "");
        StoreService.updateStoreProperty("isPinValid", false);
        StoreService.updateStoreProperty("user", {});
        StoreService.updateStoreProperty("token", "");

        dispatch({
            type: LOGOUT_USER,
        });
    });
};
export default logoutUser;
