import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../layout/PageHeader";
import MainLoader from "../../components/MainLoader";
import { StoreService } from "../../services/StoreService";
import FilterSidebar from "../../components/FilterSidebar";
import { useForm } from "react-hook-form";
import { CustomDatePicker, CustomDropdown, CustomForm } from "../../devkit/Form/Form";
import { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import biometricAPI from "../../api/biometricAPI";
import { Line } from "react-chartjs-2";
import { IWebViewData } from "../../App";
import { DevTool } from "@hookform/devtools";
import { usersAPI } from "../../api/usersAPI";

type BiometricItem = {
    [key: string]: number
}

interface IBiometric {
    OxPulseData: {
        [key: string]: any
    },
    HeartRateData: {
        [key: string]: any
    },
    MovementData: {
        [key: string]: any
    },
    RespirationData: {
        [key: string]: any
    },
    StressData: BiometricItem,
    EnergyLevelData: {
        [key: string]: any
    }
}

interface IFormValues {
    filters: {
        human_id: number | string | null;
        from: Date | null;
        to: Date | null;
        tab: string | null;
    };
}


const BiometricDashboard = ({ webViewData }: { webViewData?: IWebViewData }) => {
    const token = webViewData?.token ? webViewData.token : StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const biometricItemTabs = [
        {
            label: t("BiometricHeartRateData"),
            value: "HeartRateData",
        },
        {
            label: t("BiometricRespirationData"),
            value: "RespirationData",
        },
        {
            label: t("BiometricOxPulseData"),
            value: "OxPulseData",
        },
        {
            label: t("BiometricStressData"),
            value: "StressData",
        },
        {
            label: t("BiometricEnergyLevelData"),
            value: "EnergyLevelData",
        },
    ];
    const [biometrics, setBiometrics] = useState<{ [key: number]: IBiometric }>({});
    const [chartData, setChartData] = useState<any>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [users, setUsers] = useState<IData[]>([]);
    const [pickedUser, setPickedUser] = useState<IData | null>(null);
    const [pickedTab, setPickedTab] = useState<IData | null>(null);
    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);


    const resetRef = useRef(false);

    const defaultValues = {
        filters: {
            human_id: webViewData ? webViewData.ben_id : null,
            from: null,
            to: null,
            tab: null,
        },
    };

    const {
        watch,
        control,
        reset,
        getValues,
    } = useForm<IFormValues>({ defaultValues: defaultValues });
    const { filters } = watch();

    const generateGraphData = (data: BiometricItem) => {
        const backgroundColor = "#3d69ca";
        let labels: string[] = [];
        let points: number[] = [];
        let backgrounds: string[] = [];
        let borders: string[] = [];
        let filteredData: BiometricItem = {};

        if (!data || !pickedTab?.value) return (<div />);

        for (let key in data) {
            if (data[key] > 0) filteredData[key] = data[key];
        }

        const chartLabel = t("Biometric" + pickedTab?.value);
        const dateStrings: string[] = Object.keys(filteredData);
        const numberOfLabels = 5;
        const labelOffset = Math.floor(dateStrings.length / numberOfLabels);
        let currentLabelNumber = 1;
        let currentLabelDateString = "";

        dateStrings.forEach((dateString: string, index: number) => {
            if (
                labels.indexOf(dateString) < 0 && Number(filteredData[dateString]) > 0
            ) {
                const date = dateString.split(" ")[0].replace("-", "/");
                const hours = dateString.split(" ")[1];

                points.push(filteredData[dateString]);
                backgrounds.push(backgroundColor + "66");
                borders.push(backgroundColor);

                if (index === labelOffset * currentLabelNumber) {
                    currentLabelNumber++;
                }
                if (date !== currentLabelDateString) {
                    currentLabelDateString = date;

                    labels.push(date + " " + hours);
                } else {
                    labels.push(hours);
                }
            }
        });

        return {
            labels: labels,
            datasets: [{
                label: chartLabel,
                data: points,
                backgroundColor: backgrounds,
                borderColor: borders,
                borderWidth: 1,
            }],
        };
    };

    const getBiometrics = () => {
        setLoader(true)
        const getTodayDate = () => new Date();
        const todayDate = startDate ? startDate : getTodayDate();
        // We need all the date until the end of the day
        todayDate.setHours(23);
        todayDate.setMinutes(59);

        const yesterdayMilliseconds = todayDate.valueOf() - (1000 * 60 * 60 * 24);
        const yesterdayDate = new Date(yesterdayMilliseconds);

        biometricAPI.getBiometrics(
            token,
            `${yesterdayDate.getUTCFullYear()}-${
                yesterdayDate.getUTCMonth() + 1 < 10 ?
                    "0" + (yesterdayDate.getUTCMonth() + 1) :
                    yesterdayDate.getUTCMonth() + 1
            }-${
                yesterdayDate.getUTCDate() < 10 ?
                    "0" + yesterdayDate.getUTCDate() :
                    yesterdayDate.getUTCDate()
            }`,
            `${todayDate.getUTCFullYear()}-${
                todayDate.getUTCMonth() + 1 < 10 ?
                    "0" + (todayDate.getUTCMonth() + 1) :
                    todayDate.getUTCMonth() + 1
            }-${
                todayDate.getUTCDate() < 10 ?
                    "0" + todayDate.getUTCDate() :
                    todayDate.getUTCDate()
            }`,
        // selected userId
            pickedUser?.value.toString()
        ).then((res) => {
            if (res?.success && res?.biometrics) {
                // We want to group biometric data by BEN user
                // Each BEN user might have biometric data for multiple days
                const newBiometrics: { [key: number]: any } = {};

                // We loop through every row in biometrics' array
                // One row contains data for one BEN for one day
                // So here we might have multiple BENs' data for multiple days
                res.biometrics.forEach((data: any) => {
                    // Parsed data holds all the data of one BEN for chosen period of time
                    const parsedData: IBiometric = {
                        OxPulseData: {},
                        HeartRateData: {},
                        MovementData: {},
                        RespirationData: {},
                        StressData: {},
                        EnergyLevelData: {},
                    };

                    // We map the Respiration data to this BEN user, if it exists in this biometric data
                    if (data?.respiration?.length) {
                        data.respiration.forEach((respirationData: any) => {
                            const datestamp = respirationData?.startTimeInSeconds ?
                                new Date(respirationData.startTimeInSeconds * 1000) : null;

                            if (datestamp && respirationData?.timeOffsetEpochToBreaths) {
                                const dateString = `${datestamp.getUTCMonth() + 1}-${datestamp.getUTCDate()}`;

                                Object.keys(respirationData?.timeOffsetEpochToBreaths).forEach((respirationOffset: any) => {
                                    const hoursOffset = Math.floor((respirationOffset / 60) / 60);
                                    const minutesOffset = Math.floor((respirationOffset / 60) % 60);
                                    const timeString = `${hoursOffset}:${minutesOffset}`;
                                    const copiedDatestamp = new Date(datestamp.getTime());

                                    copiedDatestamp.setHours(hoursOffset);
                                    copiedDatestamp.setMinutes(minutesOffset);

                                    parsedData.RespirationData[`${dateString} ${timeString}`] =
                                        respirationData.timeOffsetEpochToBreaths[respirationOffset];
                                });
                            }
                        });
//console.log('parsedData.RespirationData', parsedData.RespirationData)
                    }
                    // We map the PulseOx(SpO2%) data to this BEN user, if it exists in this biometric data
                    if (data?.pulseOx?.length) {
                        data.pulseOx.forEach((oxData: any) => {
                            const datestamp = oxData?.startTimeInSeconds ? new Date(oxData.startTimeInSeconds * 1000) : null;

                            if (datestamp && oxData?.timeOffsetSpo2Values) {
                                const dateString = `${datestamp.getUTCMonth() + 1}-${datestamp.getUTCDate()}`;

                                Object.keys(oxData?.timeOffsetSpo2Values).forEach((oxOffset: any) => {
                                    const hoursOffset = Math.floor((oxOffset / 60) / 60);
                                    const minutesOffset = Math.floor((oxOffset / 60) % 60);
                                    const timeString = `${hoursOffset}:${minutesOffset}`;
                                    const copiedDatestamp = new Date(datestamp.getTime());

                                    copiedDatestamp.setHours(hoursOffset);
                                    copiedDatestamp.setMinutes(minutesOffset);

                                    parsedData.OxPulseData[`${dateString} ${timeString}`] = oxData.timeOffsetSpo2Values[oxOffset];
                                });
                            }
                        });
//console.log('parsedData.OxPulseData', parsedData.OxPulseData)
                    }
                    // We map the Stress and Energy level data to this BEN user, if it exists in this biometric data
                    if (data?.stress?.length) {
                        data.stress.forEach((stressData: any) => {
                            const datestamp = stressData?.startTimeInSeconds ? new Date(stressData.startTimeInSeconds * 1000) : null;

                            // Parsing Stress level data
                            if (datestamp && stressData?.timeOffsetStressLevelValues) {
                                const dateString = `${datestamp.getUTCMonth() + 1}-${datestamp.getUTCDate()}`;

                                Object.keys(stressData?.timeOffsetStressLevelValues).forEach((stressOffset: any) => {
                                    const hoursOffset = Math.floor((stressOffset / 60) / 60);
                                    const minutesOffset = Math.floor((stressOffset / 60) % 60);
                                    const timeString = `${hoursOffset}:${minutesOffset}`;

                                    parsedData.StressData[`${dateString} ${timeString}`] = stressData.timeOffsetStressLevelValues[stressOffset];
                                });
                            }
                            // Parsing Energy level data
                            if (datestamp && stressData?.timeOffsetBodyBatteryValues) {
                                const dateString = `${datestamp.getUTCMonth() + 1}-${datestamp.getUTCDate()}`;

                                Object.keys(stressData?.timeOffsetBodyBatteryValues).forEach((stressOffset: any) => {
                                    const hoursOffset = Math.floor((stressOffset / 60) / 60);
                                    const minutesOffset = Math.floor((stressOffset / 60) % 60);
                                    const timeString = `${hoursOffset}:${minutesOffset}`;

                                    parsedData.EnergyLevelData[`${dateString} ${timeString}`] = stressData.timeOffsetBodyBatteryValues[stressOffset];
                                });
                            }
                        });
//console.log('parsedData.StressData', parsedData.StressData)
//console.log('parsedData.EnergyLevelData', parsedData.EnergyLevelData)
                    }
                    // We map the Movement and Heart rate data to this BEN user, if it exists in this biometric data
                    if (data?.healthDailies?.length) {
                        data.healthDailies.forEach((dailyData: any) => {
                            const datestamp = dailyData?.startTimeInSeconds ? new Date(dailyData.startTimeInSeconds * 1000) : null;

                            // Parsing Heart rate data
                            if (datestamp && dailyData?.timeOffsetHeartRateSamples) {
                                const dateString = `${datestamp.getUTCMonth() + 1}-${datestamp.getUTCDate()}`;

                                Object.keys(dailyData?.timeOffsetHeartRateSamples).forEach((offset: any) => {
                                    const hoursOffset = Math.floor((offset / 60) / 60);
                                    const minutesOffset = Math.floor((offset / 60) % 60);
                                    const timeString = `${hoursOffset}:${minutesOffset}`;

                                    parsedData.HeartRateData[`${dateString} ${timeString}`] = dailyData.timeOffsetHeartRateSamples[offset];
                                });
                            }
                        });
                    }

                    // If BEN doesn't have his ID mapped inside the new biometric data map, create it
                    if (!newBiometrics[data?.ben_id]) {
                        newBiometrics[Number(data?.ben_id)] = parsedData;
                        // console.log("if");
                    }
                    // If BEN has his ID mapped inside the new biometric data map, update it with the new data
                    else {
                        // console.log("else");

                        newBiometrics[Number(data?.ben_id)].OxPulseData = {
                            ...newBiometrics[Number(data?.ben_id)].OxPulseData,
                            ...parsedData.OxPulseData,
                        };

                        newBiometrics[Number(data?.ben_id)].StressData = {
                            ...newBiometrics[Number(data?.ben_id)].StressData,
                            ...parsedData.StressData,
                        };

                        newBiometrics[Number(data?.ben_id)].EnergyLevelData = {
                            ...newBiometrics[Number(data?.ben_id)].EnergyLevelData,
                            ...parsedData.EnergyLevelData,
                        };
                        newBiometrics[Number(data?.ben_id)].HeartRateData = {
                            ...newBiometrics[Number(data?.ben_id)].HeartRateData,
                            ...parsedData.HeartRateData,
                        };
                        newBiometrics[Number(data?.ben_id)].RespirationData = {
                            ...newBiometrics[Number(data?.ben_id)].RespirationData,
                            ...parsedData.RespirationData,
                        };
                    }
//console.log('benData', benData);
                });
//console.log('newBiometrics', newBiometrics);
                setBiometrics(newBiometrics);
                setLoader(false);

                if (pickedUser?.value && pickedTab?.value && Object.keys(newBiometrics).length) {
                    //!TODO check why code breaks on webview but not on website if we don't check for Object.keys(newBiometrics).length
                    setChartData(generateGraphData(newBiometrics[Number(pickedUser?.value)][pickedTab?.value]));
                }
            } else {
                setLoader(false);
            }
        });
    };
    const closeSidebar = () => {
        setSidebarOpened(false);
    };
    const resetFilters = () => {
        reset(defaultValues);
        resetRef.current = true;
        setReRenderSidebar(!reRenderSidebar);
    };
    const getPickedUserName = () => {
        const pickedUserData = usersData.filter((user: any) => {
            return user.id == pickedUser?.value;
        })[0];

        if (pickedUserData) {
            return " > " + pickedUserData.first_name + " " + pickedUserData.last_name;
        }

        return "";
    };

    const getAll = () => {
        usersAPI
            .getAll(
                {
                    limit: 1000,
                    offset: 0,
                    role: 1,
                    search: "",
                    sorter: "last_name",
                    sorter_direction: "asc",
                },
                token,
            )
            .then((res) => {
                if (res && res.success) {
                    setUsersData(res.data);

                }
            });
    };

    useEffect(() => {
        if (typeof webViewData === "undefined") {
            getAll();
        } else {
            getBiometrics();
        }
    }, []);

    useEffect(() => {
        if (usersData && typeof webViewData === "undefined") {
            const benUsersDropdownList = usersData.map((user: any) => {
                return {
                    label: user.first_name + " " + user.last_name,
                    value: Number(user.id),
                };
            });
            setUsers(benUsersDropdownList);
        }
    }, [usersData]);

    useEffect(() => {
        if (pickedUser?.value && pickedTab?.value && biometrics[Number(pickedUser?.value)]) {
            // @ts-ignore
            setChartData(generateGraphData(biometrics[Number(pickedUser?.value)][pickedTab?.value]));
        } else if (!biometrics[Number(pickedUser?.value)]) {
            setChartData(null);
        }
    }, [pickedTab, pickedUser]);


    useEffect(() => {
        startDate && pickedUser && getBiometrics();
    }, [startDate,pickedUser]);
    // *** web view start ***
    useEffect(() => {
        webViewData && setPickedUser({ value: webViewData?.ben_id, label: "" });
    }, []);
    // *** web view end ***

    const submitFilters = () => {
        if (filters?.tab && filters?.human_id) {
            // Change graph without API call if user only changes parameter type, because we already have that data stored
            if(filters.human_id === pickedUser?.value && filters.from === startDate ){
                setPickedTab({
                    value: filters.tab,
                    label: "",
                });
            } else {
                setPickedUser({
                    value: filters.human_id,
                    label: "",
                });
                setPickedTab({
                    value: filters.tab,
                    label: "",
                });

                if (filters?.from) {
                    setStartDate(filters.from);
                }
            }
            setSidebarOpened(!sidebarOpened);
        }
    }
    return (
        <div className="biometric-dashboard">
            <PageHeader
                title={
                    !pickedUser?.value ? t("BIOMETRIC") :
                        `${t("BIOMETRIC")} ${getPickedUserName()}`
                }
                className="mb-30"
                handleAdd={undefined}
                handleRefresh={undefined}
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
            />

            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                forceReRender={reRenderSidebar}
                submitFilters={submitFilters}
                disableButton={!(watch('filters.tab') && watch('filters.from') && (webViewData ? true : watch('filters.human_id')))}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomDatePicker name="filters.from" label={t("FROM")} control={control}
                        />
                    </div>
                    {/*<div className="filter-container">
            <CustomDatePicker name="filters.to" label={t("TO")} control={control}/>
          </div>*/}
                    {!webViewData ? <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`bio-${getValues("filters.human_id")}-id`}
                        />
                    </div> : <></>}

                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.tab"
                            data={biometricItemTabs}
                            label="BIOMETRIC_TAB"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="BIOMETRIC_TAB"
                            key={`bio-${getValues("filters.tab")}-tab`}
                        />
                    </div>
                </CustomForm>
            </FilterSidebar>

            {loader ? (
                <MainLoader />
            ) : (
                <div className="biometric-dashboard-data">
                    {
                        chartData ? (<div className="biometric-chart-wrapper">
                                <Line data={chartData} />
                            </div>) :
                            pickedUser?.value ? (<div>
                                {t("NO_BIOMETRIC_DATA")}
                            </div>) : (<div className={"f-s-15"}>
                                {t("CHOOSE_BIOMETRIC_PARAMS")}
                            </div>)
                    }
                </div>
            )}
            <DevTool control={control} />
        </div>

    );
};

export default BiometricDashboard;
