import React from "react";

interface IModal {
    name: string;
    toggle: (name: string) => void;
    className?: string;
    wrapperClasses?: string;
    children: JSX.Element | JSX.Element[];
    preventDefaultClosing?: boolean;
}

const Modal = ({ name, toggle, className, wrapperClasses, children, preventDefaultClosing }: IModal) => {
    return (
        <div
            onClick={() => (preventDefaultClosing ? {} : toggle(name))}
            className={`modal ${className} d-flex align-items-center justify-content-center`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
        >
            <div className={`modalWrapper ${wrapperClasses}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
