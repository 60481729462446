import axios from "axios";
import { ServerURL, GarminRedirectPath } from "../constants";

const biometricAPI = {
    getAuthUrl: (token: string) => {
        return axios({
            url: `${ServerURL}/garminRequestToken`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                url: document.location.origin + '/' + GarminRedirectPath
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    sendVerifier: (token: string, data: {
        verifier: string;
        id: number | string;
        request_token: string;
        request_token_secret : string;
    }) => {
        return axios({
            url: `${ServerURL}/garminAccessToken`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
          .then((res) => res.data)
          .catch((e) => console.log(e));
    },
    getBiometrics: (token: string, start: string, end: string, ben_id?: string) => {
        const data = new FormData();

        data.append('start', start);
        data.append('end', end);
        ben_id && data.append('ben_id', ben_id)

        return axios({
            url: `${ServerURL}/biometrics/getAllBiometrics`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
          .then((res) => res.data)
          .catch((e) => console.log(e));
    }
};

export default biometricAPI;
