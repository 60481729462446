import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

export interface IData {
    value: string | number;
    label: any;
}
// TODO tipove za control i rules, ne samo ovdje nego u svakoj komponenti forme
interface ICustomDropdown {
    data: Array<IData>;
    name: string;
    placeholder?: string;
    className?: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    multiple?: boolean;
    label?: string;
    labelClass?: string;
    filter?: boolean;
    maxMenuHeight?: number;
    control: any;
    rules?: any;
}

const _cdd: React.FC<ICustomDropdown> = ({
    data,
    placeholder,
    name,
    className,
    error,
    disabled,
    label,
    labelClass,
    multiple,
    filter,
    maxMenuHeight,
    control,
    rules,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`customDropdown p-r  ${disabled ? "disabled" : ""} ${multiple ? "multiple" : ""} ${
                filter ? "filter" : ""
            }
            `}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                        className={`basic-single ${className ? className : ""} ${disabled ? "disabled" : ""}`}
                        classNamePrefix="select"
                        isSearchable={true}
                        name={name}
                        options={data}
                        noDataRenderer={"Nema rezultata"}
                        placeholder={placeholder && t(placeholder)}
                        menuPlacement="auto"
                        isDisabled={disabled}
                        isMulti={multiple}
                        maxMenuHeight={maxMenuHeight}
                        inputRef={ref}
                        value={multiple ? value : data.find((c) => c.value === value)}
                        onChange={(val: any) => onChange(multiple ? val : val.value)}
                    />
                )}
            />

            {label && (
                <label className={`${labelClass ? labelClass : "f-s-16"} ${disabled ? "disabled" : ""} top`}>
                    {t(label)}
                </label>
            )}
            {error && <span className="error f-s-12 is-warning danger p-10">{t(error)}</span>}
        </div>
    );
};

export default _cdd;
