import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import {
    getFirestore,
    collection,
    doc,
    query,
    where,
    getDocs,
    setDoc,
    deleteDoc,
    orderBy,
    onSnapshot,
    Timestamp,
} from "firebase/firestore";
import { initializeAnalytics } from "firebase/analytics";
import { FirebaseConstants, StorageURL } from "../constants";
import { usersAPI } from "../api/usersAPI";
import { StoreService } from "./StoreService";
import { IUser } from "../models/interfaces/User";

let FIREBASE_APP: FirebaseApp;

export const FirebaseService = {
    initialize: async (
        config: object,
        firebasePassword: string,
        getCurrentUser: any,
        updateCurrentUser: any,
        updateChatProfiles: any,
        userObjectStructure: any,
        imagesUrlPrefix?: string,
        handleError?: any,
    ) => {
        //console.log("Attempt to initialize Firebase service...");

        FirebaseService.util.getCurrentUser = getCurrentUser;
        FirebaseService.util.updateCurrentUser = updateCurrentUser;
        FirebaseService.util.updateChatProfiles = updateChatProfiles;
        FirebaseService.util.handleError = handleError;
        FirebaseService.util.firebasePassword = firebasePassword;
        FirebaseService.util.imagesUrlPrefix = imagesUrlPrefix ? imagesUrlPrefix : "";
        FirebaseService.util.userObjectStructure = userObjectStructure;

        const FirebaseApp = await initializeApp(config);

        FIREBASE_APP = FirebaseApp;

        //console.log("Firebase service initialization result - ", FirebaseApp);
        // !!TODO remove on next build
        console.log("Current firebase server: ", FirebaseConstants.CONFIG.projectId);
        initializeAnalytics(FirebaseApp);

        return FirebaseApp;
    },
    authUser: (handleDeleteMessage: any, handleDisplayMessage: any) => {
        FirebaseService.util.handleDeleteMessage = handleDeleteMessage;
        FirebaseService.util.handleDisplayMessage = handleDisplayMessage;

        let cachedUser: any = FirebaseService.util.getCurrentUser();

        const auth = getAuth();

        // If user already exists log in with his credentials
        return signInWithEmailAndPassword(auth, cachedUser.email, FirebaseService.util.firebasePassword)
            .then((userCredential: any) => {
                const user: any = userCredential && userCredential.user ? userCredential.user : {};

                cachedUser.notification_id = user.uid;
                StoreService.updateStoreProperty("user", cachedUser);
                usersAPI.update(cachedUser, StoreService.getStoreProperty("token"));
                //FirebaseService.getFcmToken();
                return FirebaseService.checkUserProfile(user);
            })
            .catch((error: any) => {
                console.log("Logging in with the current user failed - ", error);
                // If user doesn't exists create a new user with his credentials
                return createUserWithEmailAndPassword(auth, cachedUser.email, FirebaseService.util.firebasePassword)
                    .then((userCredential: any) => {
                        const user: any = userCredential && userCredential.user ? userCredential.user : {};

                        cachedUser.notification_id = user.uid;

                        // FirebaseService.util.updateCurrentUser(cachedUser);
                        //FirebaseService.getFcmToken();

                        usersAPI.update(cachedUser, StoreService.getStoreProperty("token"));
                        StoreService.updateStoreProperty("user", cachedUser);

                        return FirebaseService.checkUserProfile(user);
                    })
                    .catch((error: any) => {
                        console.error("Error while creating Firebase profile for this User -", error);

                        return FirebaseService.util.handleError(error);
                    });
            });
    },
    checkUserProfile: async (user: any) => {
        console.log("CHECK USER PROFILE FIREBASE");
        const listenForUserNotifications = () => {
            FirebaseService.getNotificationsForUser(user.uid, (notifications: any) => {
                if (FirebaseService.util.handleDisplayMessage) FirebaseService.util.handleDisplayMessage(notifications);
            });
        };

        const Firestore = getFirestore(FIREBASE_APP);
        const usersCollection = collection(Firestore, FirebaseConstants.USER_PROFILES);
        const q = query(usersCollection, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        let isProfileCreated = false;

        // Check if user's profile is created in the Firestore DB.
        querySnapshot.forEach((doc: any) => {
            console.log("User's profile found in Firestore DB - ", doc.data());
            isProfileCreated = true;

            listenForUserNotifications();
        });
        // If User's profile is not found in Firestore DB we need to create it.
        if (!isProfileCreated) {
            const cachedUser: any = FirebaseService.util.getCurrentUser();
            const firestoreProfileData = {
                name:
                    cachedUser[FirebaseService.util.userObjectStructure.firstName] +
                    " " +
                    cachedUser[FirebaseService.util.userObjectStructure.lastName],
                profilePicUrl:
                    FirebaseService.util.imagesUrlPrefix && FirebaseService.util.userObjectStructure.profilePicUrl
                        ? FirebaseService.util.imagesUrlPrefix +
                        cachedUser[FirebaseService.util.userObjectStructure.profilePicUrl]
                        : cachedUser[FirebaseService.util.userObjectStructure.profilePicUrl],
                email: cachedUser[FirebaseService.util.userObjectStructure.email],
                phone: cachedUser[FirebaseService.util.userObjectStructure.phone],
                created: Timestamp.now(),
                uid: user.uid,
                userId: cachedUser[FirebaseService.util.userObjectStructure.userId],
                role: cachedUser[FirebaseService.util.userObjectStructure.role],
                online: true,
            };

            await setDoc(doc(usersCollection, user.uid), firestoreProfileData);

            listenForUserNotifications();

            return cachedUser;
        }

        return user;
    },
    deleteUser: () => {
        const auth = getAuth();
        const user = auth.currentUser;
        console.log(user);
        user!.delete().then(
            function() {
                console.log("USER DELETED");
            },
            function(error) {
                console.log("USER COULDNT BE DELETED", error);
            },
        );
    },
    sendNotification: async (
        action: string,
        item: string | boolean | number,
        users?: any,
        username = "",
        photoUrl = "",
        table = "",
        link = "",
    ) => {
        const Firestore = getFirestore(FIREBASE_APP);
        const notificationsCollection = collection(Firestore, FirebaseConstants.NOTIFICATIONS);
        const userData: any = FirebaseService.util.getCurrentUser();
        const timestamp = new Date();
        const notificationId = String(timestamp.valueOf());
        const senderUid = userData.uid;

        username = username
            ? username
            : userData[FirebaseService.util.userObjectStructure.firstName] +
            " " +
            userData[FirebaseService.util.userObjectStructure.lastName];
        photoUrl = photoUrl
            ? photoUrl
            : FirebaseService.util.imagesUrlPrefix
                ? FirebaseService.util.imagesUrlPrefix + userData[FirebaseService.util.userObjectStructure.profilePicUrl]
                : userData[FirebaseService.util.userObjectStructure.profilePicUrl];
        users = Object.values(users)
            .filter((user) => {
                return user !== senderUid;
            })
            .sort();

        const notificationData = {
            action,
            item,
            link,
            table,
            users,
            user: username,
            profilePictureUrl: photoUrl,
            icon: "default",
            docId: notificationId,
            id: notificationId,
            created_at: Timestamp.now(),
        };

        await setDoc(doc(notificationsCollection, notificationId), notificationData);

        return notificationData;
    },
    getNotificationsForUser: async (userId: string | number, callback: any) => {
        // console.log("getNotificationsForUser");
        const Firestore = getFirestore(FIREBASE_APP);
        const notificationsCollection = collection(Firestore, FirebaseConstants.NOTIFICATIONS);
        const q = query(
            query(notificationsCollection, where("users", "array-contains", userId)),
            orderBy("id", "desc"),
        );
        const querySnapshot = await getDocs(q);
        let notifications: any = [];

        querySnapshot.forEach((doc: any) => {
            const data = doc.data();

            notifications.push(data);
            //console.log('**Delete this user notificaion**', data)
            //FirebaseService.deleteNotification(data.id)
        });

        if (callback) {
            callback(notifications);
        }

        return onSnapshot(q, (querySnapshot) => {
            notifications = [];

            querySnapshot.forEach((doc) => {
                notifications.push(doc.data());
            });

            if (callback) {
                callback(notifications);
            }
        });
    },
    deleteNotification: async (notificationId: string | number) => {
        const Firestore = getFirestore(FIREBASE_APP);

        await deleteDoc(doc(Firestore, `/${FirebaseConstants.NOTIFICATIONS}/${notificationId}`));

        return true;
    },
    sendOnlineStatusNotif: async (status: boolean) => {
        const globalWindow: any = window;
        const token = StoreService.getStoreProperty("token");

        if (!globalWindow["firebaseInitialized"]) {
            await FirebaseService.initializeFirebase();
        }

        await usersAPI.getOnlineUsers(token).then((res) => {
            if (res && res.success) {
                if (res.data?.length) {
                    FirebaseService.sendNotification(
                        "online",
                        status,
                        res.data
                            .map((user: IUser) => {
                                return user.notification_id;
                            })
                            .filter((id: string) => id !== StoreService.getStoreProperty("user").notification_id && id),
                    ).then((response) => {
                        const notificationId = response.id;
                        //console.log(response);
                        console.log("SENT ONLINE STATUS NOTIFICATION", res.data);
                        setTimeout(() => {
                            FirebaseService.deleteNotification(notificationId);
                        }, 5000);
                        return Promise.resolve();
                    });
                }
            }
        });
    },
    initializeFirebase: async () => {
        const globalWindow: any = window;
        // const storedUser = StoreService.getStoreProperty("user");
        await FirebaseService.initialize(
            FirebaseConstants.CONFIG,
            "0yk0s.Devel0pmenT",
            () => {
                return StoreService.getStoreProperty("user");
            },
            (cachedUser: any) => {
                StoreService.updateStoreProperty("user", cachedUser);
            },
            (userProfilesData: any, isChange = false) => {
                const userProfile = isChange ? userProfilesData.doc.data() : userProfilesData.data();
                let cachedUserProfiles = StoreService.getStoreProperty("userProfiles");

                if (isChange && userProfilesData.type === "removed") {
                    delete cachedUserProfiles[userProfile.uid];
                } else {
                    cachedUserProfiles[userProfile.uid] = userProfile;
                }

                StoreService.updateStoreProperty("userProfiles", cachedUserProfiles, true);
            },
            {
                firstName: "first_name",
                lastName: "last_name",
                profilePicUrl: "profile_picture",
                email: "email",
                phone: "phone",
                userId: "id",
                role: "role",
            },
            StorageURL,
            (error: any) => {
                console.log("Error initializing the Firebase configuration.", error);
            },
        );

        globalWindow["firebaseInitialized"] = true;
    },
    util: {
        getCurrentUser: () => {
        },
        updateCurrentUser: (user: object) => {
            console.log("Firebase Service > method updateCurrentUser > you didn't pass a function", user);
        },
        updateChatProfiles: (data: any, isChange?: any) => {
            console.log("Firebase Service > method updateChatProfiles > you didn't pass a function", data + isChange);
        },
        handleError: (data: any) => {
            console.log("Firebase Service > method handleError > you didn't pass a function", data);
        },
        handleDeleteMessage: (conversationId: any) => {
            console.log("Firebase Service > method handleDeleteMessage > you didn't pass a function", conversationId);
        },
        handleDisplayMessage: (data: any) => {
            console.log("Firebase Service > method handleDisplayMessage > you didn't pass a function", data);
        },
        firebasePassword: "0yk0s.Devel0pmenT",
        // firebasePassword: "telecare-oykos-password",
        imagesUrlPrefix: "",
        userObjectStructure: {
            firstName: "first_name",
            lastName: "last_name",
            profilePicUrl: "profile_picture",
            email: "email",
            phone: "phone",
            userId: "id",
            role: "role",
        },
    },
};
