import axios from "axios";
import { ServerURL } from "../constants";

const url = `${ServerURL}/users`;

interface GetAllProps {
    limit?: number;
    offset?: number;
    role?: number;
    sorter?: string;
    sorter_direction?: string;
    search?: string;
    is_online?: number;
}

export const usersAPI = {
    get: (id: number, token: string) => {
        return axios({
            url: `${url}/getUser`,
            method: "POST",
            data: {
                id,
            },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${url}/getAllUsers`,
            method: "POST",
            data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    update: (data: any, token: string) => {
        return axios({
            url: `${url}/updateUser`,
            method: "POST",
            data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    storeUser: (data: FormData, token: string) => {
        return axios({
            url: `${ServerURL}/register`,
            method: "POST",
            data,
            headers: { Authorization: `Bearer ${token}`, "Content-type": "multipart/form-data" },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    toggleActivate: (id: number, token: string) => {
        return axios({
            url: `${url}/toggleActivate`,
            method: "POST",
            data: { id },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    linkUsers: (ben_id: number | string, fandf_id: number | string, token: string) => {
        return axios({
            url: `${url}/linkingFandF`,
            method: "POST",
            data: { ben_id, fandf_id },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getOnlineUsers: (token: string) => {
        return axios({
            url: `${url}/onlineUsers`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};
