import axios from "axios";
import { ServerURL } from "../constants";
import { paginationLimit } from "../constants";

interface GetAllProps {
    offset: number;
    sorter: string;
    sorter_direction: string;
    search: string;
    from: Date | string | null;
    to: Date | string | null;
    human_id?: number;
}

const videoCallsAPI = {
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${ServerURL}/videoCalls/getAllVideoCalls`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                limit: paginationLimit,
                ...data,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    create: (target_user_id: number | string, token: string) => {
        return axios({
            url: `${ServerURL}/videoCalls/createVideoCall`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                target_user_id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    edit: (id: number | string, state: number, token: string) => {
        return axios({
            url: `${ServerURL}/videoCalls/editVideoCall`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
                state,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    exportPDF: (data: GetAllProps, token: string) => {
        return axios({
            url: `${ServerURL}/videoCalls/getCallsPDF`,
            method: "POST",
            data: { limit: paginationLimit, ...data },
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default videoCallsAPI;
