export const usersTableHeads = [
    { value: "FIRST_NAME", sortable: true, sortableName: "first_name" },
    { value: "LAST_NAME", sortable: true, sortableName: "last_name" },
    { value: "BIRTH_DATE", sortable: true, sortableName: "birth_date" },
    { value: "CREATED_AT", sortable: true, sortableName: "created_at" },
    { value: "DESCRIPTION", sortable: false, sortableName: "description" },
    { value: "PHONE", sortable: true, sortableName: "phone" },
    { value: "EMAIL", sortable: true, sortableName: "email" },
    { value: "ONLINE_STATUS", sortable: true, sortableName: "is_online " },
    { value: "", sortable: false },
];
export const medical_records = [
    { value: "TITLE", sortable: true, sortableName: "title" },
    { value: "ENTRY_DATE", sortable: true, sortableName: "created_at" },
    { value: "DESCRIPTION", sortable: false },
    { value: "BENEFICIARY", sortable: false },
    { value: "AUTHORS", sortable: false },
    { value: "", sortable: false },
];
export const medicalReportsTHeads = [
    { value: "TITLE", sortable: true, sortableName: "title" },
    { value: "ENTRY_DATE", sortable: true, sortableName: "created_at" },
    { value: "DESCRIPTION", sortable: true, sortableName: "description" },
    { value: "AUTHORS", sortable: false, sortableName: "authors" },
    { value: "LINKED_BENEFICIARIES", sortable: false, sortableName: "ben_users" },
    { value: "FILES", sortable: false, sortableName: "files" },
    { value: "", sortable: false },
];
export const sosTHeads = [
    { value: "BENEFICIARY", sortable: true, sortableName: "source_user_last_name" },
    { value: "RESPONDER", sortable: true, sortableName: "responder_user_last_name" },
    { value: "DATE", sortable: true, sortableName: "created_at" },
    // { value: "", sortable: false },
];
export const videoCallsTHeads = [
    { value: "CALLER", sortable: true, sortableName: "source_user_last_name" },
    { value: "RECIPIENT", sortable: true, sortableName: "target_user_last_name" },
    { value: "DATE", sortable: true, sortableName: "created_at" },
    { value: "STATUS", sortable: false },
    // { value: "", sortable: false },
];
export const remindersTHeads = [
    { value: "TITLE", sortable: true, sortableName: "title" },
    { value: "BENEFICIARY", sortable: false, sortableName: "ben_id" },
    { value: "SCHEDULED_AT", sortable: true, sortableName: "scheduled_at" },
    { value: "CREATED_AT", sortable: true, sortableName: "created_at" },
    { value: "DESCRIPTION", sortable: true, sortableName: "description" },
    { value: "FREQUENCY", sortable: false, sortableName: "frequency" },
    { value: "AUTHORS", sortable: false, sortableName: "authors" },
    { value: "", sortable: false },
];
