import { StoreService } from "../../services/StoreService";
import { LOCK_APP } from "../../constants/actionTypes";
import { usersAPI } from "../../api/usersAPI";
import { FirebaseService } from "../../services/FirebaseService";

const lockApp = () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const token = StoreService.getStoreProperty("token");
    const user = StoreService.getStoreProperty("user");

    usersAPI.update({ is_online: 0, id: user.id }, token).then((res) => {
        if (res?.success) {
            dispatch({
                type: LOCK_APP,
            });

            FirebaseService.sendOnlineStatusNotif(false);

            StoreService.updateStoreProperty("isPinValid", false);
        }
        else {
            StoreService.updateStoreProperty("isPinValid", false);
            StoreService.updateStoreProperty("isLoggedIn", false);
            StoreService.updateStoreProperty("role", "");
            StoreService.updateStoreProperty("id", "");
            StoreService.updateStoreProperty("isPinValid", false);
            StoreService.updateStoreProperty("user", {});
            StoreService.updateStoreProperty("token", "");
        }
    });
};

export default lockApp;
