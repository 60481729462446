import React from "react";
import { useTranslation } from "react-i18next";
import { ExportPDFIcon, FilterIcon, PlusIcon, RefreshIcon } from "../components/icons";
import { CustomButton } from "../devkit/Form/Form";

interface PageHeaderProps {
    title: string;
    options?: any;
    className?: string;
    handleAdd?: () => void;
    handleSidebar?: () => void;
    handleRefresh?: () => void;
    handleExportPDF?: () => void;
    children?: JSX.Element[] | JSX.Element;
}

const PageHeader = ({
                        title,
                        className,
                        handleAdd,
                        handleSidebar,
                        handleRefresh,
                        handleExportPDF,
                        children,
                    }: PageHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div className={`page-header ${className ? className : ""} mb-30`}>
            <div className={`page-header-top d-flex justify-content-between ${children && "mb-10"}`}>
                <h1>{title}</h1>
                <div className="buttons d-flex align-items-center">
                    {handleRefresh && (
                        <CustomButton onClick={handleRefresh} className="btn btn-refresh mr-15">
                            <RefreshIcon width={15} className="btn-icon mr-5" />
                            <span>{t("REFRESH")}</span>
                        </CustomButton>
                    )}
                    {handleExportPDF && (
                        <CustomButton onClick={handleExportPDF} className="btn btn-refresh mr-15">
                            <ExportPDFIcon width={15} className="btn-icon mr-5" />
                            <span>{t("EXPORT")}</span>
                        </CustomButton>
                    )}
                    {handleSidebar && (
                        <CustomButton onClick={handleSidebar} className="btn btn-filters mr-15">
                            <FilterIcon width={15} className="btn-icon mr-5" />
                            <span>{t("FILTERS")}</span>
                        </CustomButton>
                    )}
                    {handleAdd && (
                        <CustomButton onClick={handleAdd} className="btn btn-save">
                            <PlusIcon width={15} className="btn-icon mr-5" />
                            <span>{t("ADD_NEW")}</span>
                        </CustomButton>
                    )}

                </div>
            </div>
            <div className="toolbar">{children}</div>
        </div>
    );
};

export default PageHeader;
