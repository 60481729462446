import { StoreService } from "../../services/StoreService";
import authAPI from "../../api/authAPI";
import { toast } from "react-toastify";
import i18n from "i18next";
import { LOGIN_PIN } from "../../constants/actionTypes";
import { FirebaseService } from "../../services/FirebaseService";
import { usersAPI } from "../../api/usersAPI";

// @ts-ignore
const pinLogin =
    ({ pin }: { pin: any }) =>
        async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
            const token = StoreService.getStoreProperty("token");

            toast.loading(i18n.t("LOADING"), { toastId: "loadingToastPin" });

            authAPI.pin(pin.join(""), token).then((response) => {
                if (response && response.success) {
                    usersAPI.getOnlineUsers(token).then((res) => {
                        if (res && res.success) {
                            dispatch({
                                type: LOGIN_PIN,
                                payload: { ...response.data, online_users: res.data },
                            });
                            StoreService.updateStoreProperty("isLoggedIn", true);
                            StoreService.updateStoreProperty("isPinValid", new Date());
                            toast.update("loadingToastPin", {
                                render: i18n.t("LOGIN_SUCCESS"),
                                type: "success",
                                isLoading: false,
                                autoClose: 2000,
                                closeOnClick: true,
                                closeButton: true,
                            });

                            FirebaseService.sendOnlineStatusNotif(true);

                            StoreService.updateStoreProperty("user", response.data.user);
                        }
                    });
                } else {
                    toast.update("loadingToastPin", {
                        render: i18n.t("WRONG_PIN"),
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        closeOnClick: true,
                        closeButton: true,
                    });
                }
            });
        };
export default pinLogin;
