import axios from "axios";
import { paginationLimit, ServerURL } from "../constants";

interface GetAllProps {
    offset: number;
    sorter: string;
    sorter_direction: string;
    search: string;
    search__people: string;
    from: Date | string | null;
    to: Date | string | null;
    human_id?: number;
}

export const medicalRecordsAPI = {
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/getAllMedicalRecord`,
            method: "POST",
            data: { limit: paginationLimit, ...data },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    get: (id: number, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/getMedicalRecord`,
            method: "POST",
            data: { id },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    create: (ben_id: number, title: string, description: string, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/createMedicalRecord`,
            method: "POST",
            data: { ben_id, title, description },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    update: (id: number, title: string, description: string, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/editMedicalRecord`,
            method: "POST",
            data: { id, title, description },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    delete: (id: number | string, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/deleteMedicalRecord`,
            method: "POST",
            data: { id },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    exportPDF: (data: GetAllProps, token: string) => {
        return axios({
            url: `${ServerURL}/medicalRecords/getMedicalRecordsPDF`,
            method: "POST",
            data: { limit: paginationLimit, ...data },
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },

};
