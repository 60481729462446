import { getIcon } from "../components/icons/fileIcons";
import { permissions } from "../constants";
import { handleDebouncing, handleThrottle } from "../functions/utilFunctions/debounceAndThrottle";
import { IUser } from "../models/interfaces/User";

export const UtilService = {
    loopThroughItems: (items: { [x: string]: any }, callback: (arg0: any, arg1: string) => void) => {
        for (const itemKey in items) {
            callback(items[itemKey], itemKey);
        }
    },
    clone: (obj: any) => {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }
        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }
        if (Array.isArray(obj)) {
            const clonedArr = [] as any;
            obj.forEach(function (element) {
                // @ts-ignore
                clonedArr.push(UtilService.clone(element));
            });
            return clonedArr;
        }
        const clonedObj = new obj.constructor();

        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                clonedObj[prop] = UtilService.clone(obj[prop]);
            }
        }

        return clonedObj;
    },
    replaceSerbianChars: (string: string) => {
        if (!string) return "";

        return string
            .replace(/ć/g, "c")
            .replace(/č/g, "c")
            .replace(/đ/g, "dj")
            .replace(/š/g, "s")
            .replace(/\\n/g, " ")
            .replace(/\\r/g, " ")
            .replace(/ž/g, "z");
    },
    handleDebouncing: (callbackKey: string | number, callback: any, period: number) => {
        return handleDebouncing(callbackKey, callback, period);
    },
    handleThrottle: (callbackKey: string | number, callback: () => void, period: number) => {
        return handleThrottle(callbackKey, callback, period);
    },
    truncateString: (string: string, limit: number) => {
        if (!string || !limit) return "";
        string = String(string);

        return string.length > limit ? string.substr(0, limit - 1) + "..." : string;
    },
    sortArray: (sortItems: any[], sortableName: any, direction: any, type: string) => {
        if (type === "string") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortStringArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortStringArray(a, b, sortableName)).reverse();
            }
        }
        if (type === "date") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortDateArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortDateArray(a, b, sortableName)).reverse();
            }
        }
        if (type === "number") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortNumberArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortNumberArray(a, b, sortableName)).reverse();
            }
        }
    },
    sortStringArray: (curr: { [x: string]: any }, prev: { [x: string]: any }, name: string | number) => {
        const a = (curr[name] || "").toLowerCase();
        const b = (prev[name] || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDateArray: (curr: any, prev: any, name: string | number) => {
        // @ts-ignore
        return new Date(prev[name]) - new Date(curr[name]);
    },
    sortNumberArray: (curr: any, prev: any, name: string | number) => {
        const a = parseInt(curr[name]);
        const b = parseInt(prev[name]);
        return a > b ? -1 : b > a ? 1 : 0;
    },
    handleAddFile: (name: any, value: any, drop?: any) => {
        if (drop) {
            // e.preventDefault();
            if (value.length > 1) {
                alert("only one file allowed");
            } else {
                return value.files[0];
            }
        } else {
            return value;
        }
    },
    isNumber: (n: any) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isString: (s: any) => {
        return typeof s === "string";
    },
    areObjectsEqual: (object1: any, object2: any) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if ((keys1 && !keys2) || (!keys1 && keys2) || (!keys1 && !keys2)) return false;

        if (keys1.length !== keys2.length) {
            return false;
        }

        let equals = true;

        for (let key of keys1) {
            if (typeof object1[key] === "object") {
                const propertyEqual = UtilService.areObjectsEqual(object1[key], object2[key]);

                if (!propertyEqual) equals = false;
            } else if (object1[key] !== object2[key]) {
                equals = false;
            }
        }

        return equals;
    },
    getFilenameFromPath: (filename: string) => {
        return filename.substring(filename.lastIndexOf("/") + 1);
    },

    downloadFilePDF: (blob: any, fileName: string) => {
        const link = document.createElement("a");
        let binaryData = [] as any;
        binaryData.push(blob);
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
        // link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 11000);
    },
    getFileType: (file: any): string => {
        // * You can forward file object which has filename under "path" or "file"
        // * or you can directly forward just a file path as a string
        // const filePath = typeof file === "string" ? file : file.path ? file.path : file.file;

        const fileName = file.name ? file.name : file.path;
        const regEx = /(?:\.([^.]+))?$/;
        let type = regEx.exec(fileName);

        return type && type.length > 0 ? type[1] : "";
    },
    minutesToMiliseconds: (minutes: number) => {
        return minutes * 60000;
    },
    handleIcon: (type: string, width: number) => {
        if (type) {
            if (type.includes("/")) {
                const iconType = type.split("/");

                return getIcon(iconType[1].toLowerCase(), { width: width });
            } else return getIcon(type, { width: width });
        } else {
            return getIcon("", { width: width });
        }
    },
    hasPermission: (entity: string, action: string, userType?: number) => {
        const role: number = JSON.parse(localStorage.getItem("telecare")!).role;

        if (role) {
            if (role === 4) {
                return true;
            } else if (entity === "users" && action === "read" && (role === 2 || role === 3) && userType) {
                return permissions[role][entity][action][userType];
            } else {
                return permissions[role][entity][action];
            }
        }
    },
    getUserOptions: (arr: IUser[]) => {
        return arr.map((user: IUser) => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }));
    },
};
