import axios from "axios";
import { ServerURL } from "../constants";
import { paginationLimit } from "../constants";

interface CreateReminder {
    ben_id: number;
    frequency: number;
    title: string;
    description: string;
    scheduled_at: Date | string;
}

export interface UpdateReminder extends CreateReminder {
    id: number;
}

interface GetAllProps {
    offset: number;
    sorter: string;
    sorter_direction: string;
    search: string;
    human_id?: number;
}

const url = `${ServerURL}/reminders`;

const medReports = {
    get: (id: number, token: string) => {
        return axios({
            url: `${url}/getReminder`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    create: (data: CreateReminder, token: string) => {
        return axios({
            url: `${url}/createReminder`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    delete: (id: number, token: string) => {
        return axios({
            url: `${url}/deleteReminder`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${url}/getAllReminders`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                limit: paginationLimit,
                ...data,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    update: (data: UpdateReminder, token: string) => {
        return axios({
            url: `${url}/editReminder`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default medReports;
