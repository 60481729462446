import React, { useState } from "react";
import { StorageURL } from "../constants";
import { CustomFileInput } from "../devkit/Form/Form";
import Modal from "../devkit/Modal/Modal";
import { Avatar, CameraIcon, TrashIcon, UploadIcon } from "./icons";

interface IProfileImageProps {
    toggleModal: (name: string) => void;
    profileImage: string | null;
    tempProfileImage: string;
    modalOpened: boolean;
    handleFileChange: (name: string, e: any, drop?: boolean) => void;
    removeImage: () => void;
    className?: string;
}

const ProfileImage = ({
    toggleModal,
    profileImage,
    tempProfileImage,
    modalOpened,
    handleFileChange,
    removeImage,
    className,
}: IProfileImageProps) => {
    const [isOver, setIsOver] = useState<boolean>(false);
    const [photo, setPhoto] = useState<any>({});

    const changeBorderType = (isOver: boolean) => {
        setIsOver(isOver);
    };

    const handleFileDrop = (name: string, e: any, drop?: boolean) => {
        // setState({
        //     [name]: UtilService.handleAddFile(name, e, drop),
        // });
    };
    return (
        <div className={`profile-image-component ${className ? className : ""}`}>
            <div className="avatar-container p-r">
                {tempProfileImage || profileImage ? (
                    <img
                        className="image"
                        src={`${tempProfileImage ? tempProfileImage : `${StorageURL}${profileImage}`}`}
                        alt={""}
                    />
                ) : (
                    <Avatar className="image" />
                )}
                <div className="d-flex camera" onClick={() => toggleModal("imageUploadModal")}>
                    <CameraIcon width={24} className="camera-icon" />
                </div>
                <div
                    className="delete-profile-image d-flex align-items-center justify-content-center"
                    onClick={removeImage}
                >
                    <TrashIcon width={25} className="trash-icon" />
                </div>
            </div>
            <Modal
                name="imageUploadModal"
                toggle={toggleModal}
                className={modalOpened ? "visible" : ""}
                wrapperClasses="fileUploadModal"
            >
                <div className="pa-20 h-100-perc">
                    <div
                        onDrop={(e) => handleFileDrop("photo", e, true)}
                        className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                            isOver ? "dotted" : ""
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            changeBorderType(true);
                        }}
                        onDragLeave={() => changeBorderType(false)}
                    >
                        <div className="text d-flex flex-column justify-content-center align-items-center">
                            <span className="d-block mb-10">
                                <UploadIcon width={40} className="uploadIcon" />
                            </span>
                            <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">Prevuci fajl ovdje</span>
                            <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">ili</span>
                        </div>
                        <CustomFileInput
                            name="photo"
                            handleInput={handleFileChange}
                            value={photo.value}
                            label="Izaberi fotografiju"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProfileImage;
