import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShowPasswordIcon } from "../../../components/icons";
import { useFormContext } from "../CustomForm/CustomForm";

interface ICustomInput {
    placeholder?: string;
    error?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    edit?: boolean;
    labelClass?: string;
    errorClass?: string;
    inputProps: any;
    type: string;
}

const _csi = ({
    error,
    className,
    label,
    disabled,
    edit,
    labelClass,
    errorClass,
    placeholder,
    inputProps,
    type,
}: ICustomInput) => {
    const isForm = useFormContext();
    const [showPassword, setShowPassword] = useState(false);
    if (!isForm) {
        throw Error("You can't use CustomInput outside of CustomForm component!");
    }

    const handleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const { t } = useTranslation();
    return (
        <div className="inputComponent p-r">
            <div className={`inputWrapper hasText`}>
                <input
                    {...inputProps}
                    placeholder={placeholder ? t(placeholder) : undefined}
                    className={`  ${className ? className : "f-s-16"}`}
                    disabled={disabled ? !edit : false}
                    type={showPassword ? "text" : type}
                />
                {type === "password" && (
                    <span onClick={handleShowPassword}>
                        <ShowPasswordIcon width={20} className={`showPassword ${showPassword ? "active" : ""}`} />
                    </span>
                )}
                <label className={`${labelClass ? labelClass : ""}`}>{label && t(label)}</label>
            </div>
            {error && (
                <span className={`error f-s-12 is-warning danger  ${errorClass ? errorClass : ""}`}>{t(error)}</span>
            )}
        </div>
    );
};

export default _csi;
