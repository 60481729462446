import React, { ReactChildren } from "react";

interface ICustomButton {
    onClick?: (e: any) => void;
    children: ReactChildren | string | JSX.Element | Array<JSX.Element>;
    className?: string;
    type?: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
}
// TODO Srediti onClick i prilagoditi react-hook-form
const _cb = ({ className, onClick, children, type, disabled }: ICustomButton) => {
    return (
        <button
            className={`${className} btn`}
            onClick={onClick ? (e: any) => onClick(e) : () => {}}
            type={type}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
export default _cb;
