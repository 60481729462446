import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
    BellIcon, BiometricIcon,
    ChevronRight,
    DashboardIcon,
    GroupIcon,
    MedicalRecordIcon,
    ProfileIcon,
    ReportIcon,
    SOSIcon,
    VideoCallIcon,
} from "../components/icons";
import { roles } from "../constants";
import { GlobalContext } from "../context/Provider";

const Sidebar = () => {
    const [usersMenu, setUsersMenu] = useState(false);
    const { hasPermission } = useContext(GlobalContext);
    const { t } = useTranslation();
    const location = useLocation();
    const [hover, setHover] = useState<boolean>(false);
    const { store } = useContext(GlobalContext);

    const isTabActive = (path: string) => {
        const pathRoot = location.pathname.split("/")[1];
        if (pathRoot === path) {
            return "active";
        }
        return;
    };

    const isSubTabActive = (path: string) => {
        const pathRoot = location.pathname.split("/")[2];
        if (pathRoot === path) {
            return "active";
        }
        return;
    };

    const onSidebarHover = () => {
        setHover(true);
        if (isTabActive("users")) {
            setUsersMenu(true);
        }
    };
    const onSidebarLeave = () => {
        setHover(false);
        setUsersMenu(false);
    };

    // TODO ROLE STAVITI U LS I HASPERMISSIONS STAVITI U UTIL SERVICE???

    return (
        <div className="layout-sidebar" onMouseEnter={onSidebarHover} onMouseLeave={onSidebarLeave}>
            <h2 className={`${hover && "show"}`}>TELECARE</h2>
            <ul>
                <li className={`${isTabActive("")}`}>
                    <Link to="/">
                        <span className="icon-container">
                            <DashboardIcon className="icon" width={28} />
                        </span>
                        <span>{t("OVERVIEW")}</span>
                    </Link>
                </li>
                <li className={`${isTabActive("users")}`}>
                    <Link to="/users/beneficiaries/1">
                        <span className="icon-container">
                            <GroupIcon className="icon" width={28} />
                        </span>
                        <span>{t("USERS")}</span>
                        <span className="chevron-icon-container p-10" onClick={() => setUsersMenu(!usersMenu)}>
                            <ChevronRight width={12} className={`chevron-icon ${usersMenu ? "active" : ""}`} />
                        </span>
                    </Link>
                    <ul className={`submenu ${hover && usersMenu && "active"}`}>
                        <li className={`${isSubTabActive("beneficiaries")}`}>
                            <Link to="/users/beneficiaries/1">
                                <span>{t("BENEFICIARIES")}</span>
                            </Link>
                        </li>
                        {hasPermission("users", "read", roles.MED) && (
                            <li className={`${isSubTabActive("medical-staff")}`}>
                                <Link to="/users/medical-staff/1">
                                    <span>{t("MEDICAL_WORKERS")}</span>
                                </Link>
                            </li>
                        )}
                        {hasPermission("users", "read", roles.FF) && (
                            <li className={`${isSubTabActive("friends-and-family")}`}>
                                <Link to="/users/friends-and-family/1">
                                    <span>{t("FRIENDS_FAMILY")}</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </li>

                <li className={`${isTabActive("medical-records")}`}>
                    <Link to="/medical-records/1">
                        <span className="icon-container">
                            <MedicalRecordIcon className="icon" width={28} />
                        </span>
                        <span>{t("MEDICAL_RECORDS")}</span>
                    </Link>
                </li>

                <li className={`${isTabActive("biometric")}`}>
                    <Link to="/biometric">
                        <span className="icon-container">
                            <BiometricIcon className="icon" width={28} />
                        </span>
                        <span>{t("BIOMETRIC")}</span>
                    </Link>
                </li>

                {hasPermission("reports", "read") && (
                    <li className={`${isTabActive("medical-reports")}`}>
                        <Link to="/medical-reports/1">
                            <span className="icon-container">
                                <ReportIcon className="icon" width={28} />
                            </span>
                            <span>{t("REPORTS")}</span>
                        </Link>
                    </li>
                )}
                <li className={`${isTabActive("sos")}`}>
                    <Link to="/sos/1">
                        <span className="icon-container">
                            <SOSIcon className="icon" width={28} />
                        </span>
                        <span>{t("SOS")}</span>
                    </Link>
                </li>
                <li className={`${isTabActive("reminders")}`}>
                    <Link to="/reminders/1">
                        <span className="icon-container">
                            <BellIcon className="icon" width={28} />
                        </span>
                        <span>{t("REMINDERS")}</span>
                    </Link>
                </li>
                <li className={`${isTabActive("video-calls")}`}>
                    <Link to="/video-calls/1">
                        <span className="icon-container">
                            <VideoCallIcon className="icon" width={28} />
                        </span>
                        <span>{t("VIDEO_CALLS")}</span>
                    </Link>
                </li>
                <li className={`${isTabActive("my-profile")} my-profile-tab`}>
                    <Link to="/my-profile">
                        <span className="icon-container">
                            <ProfileIcon className="icon" width={30} />
                        </span>
                        <span>{t("MY_PROFILE")}</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
