import React from "react";
// @ts-ignore
import DateTimePicker from "react-datetime-picker";
import { ArrowDownIcon } from "../../../components/icons";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

interface IDatePicker {
    name: string;
    error?: string;
    className?: string;
    disabled?: boolean;
    label?: string;
    labelClass?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    isOpen?: boolean;
    control: any;
    rules?: any;
}

const _dtp: React.FC<IDatePicker> = ({
    name,
    className,
    error,
    disabled,
    label,
    labelClass,
    minDate,
    maxDate,
    isOpen,
    control,
    rules,
}) => {
    const { t } = useTranslation();
    return (
        <div className={`customDatePicker p-r ${disabled ? "disabled" : ""}`}>
            <label className={`${labelClass ? labelClass : "f-s-16"} ${disabled ? "disabled" : ""}`}>
                {t(label ? label : "")}
            </label>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                        onChange={onChange}
                        value={value}
                        locale={"sr-latn-SR"}
                        className={className}
                        disabled={disabled}
                        format="dd.MM.yyyy. HH:mm"
                        minDetail="month"
                        nextLabel={<ArrowDownIcon width={14} className="calendar_next_icon_right" />}
                        prevLabel={<ArrowDownIcon width={14} className="calendar_next_icon_left" />}
                        minDate={minDate}
                        maxDate={maxDate}
                        isOpen={isOpen}
                    />
                )}
            ></Controller>

            {error && <span className={`error f-s-12 is-warning danger`}>{t(error)}</span>}
        </div>
    );
};
export default _dtp;
