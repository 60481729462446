import React from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../devkit/Form/Form";
import UserFlag from "../pages/UserDetails/CustomComponents/UserFlag";

interface IDetailsHeaderProps {
    title: string;
    handleSave?: any;
    handleDelete?: () => void;
    handleDeactivate?: () => void;
    className?: string;
    subHeader?: string;
    deactivated?: boolean;
    userFlags?: { isOnline: boolean };
}

const DetailsHeader = ({
    title,
    handleSave,
    handleDelete,
    handleDeactivate,
    className,
    subHeader,
    deactivated,
    userFlags,
}: IDetailsHeaderProps) => {
    const { t } = useTranslation();
    return (
        <div className={`details-header ${className ? className : ""}`}>
            <div className="details-header-top d-flex justify-content-between align-items-center">
                <div>
                    <div className="d-flex">
                        <h1>{title}</h1>
                        {userFlags && (
                            <div className="ml-20 d-flex align-items-center mt-5">
                                <UserFlag type="isOnline" status={userFlags.isOnline} className="mr-15"></UserFlag>
                                {deactivated && <UserFlag type="isDeactivated" status={true}></UserFlag>}
                            </div>
                        )}
                    </div>

                    {subHeader && <span className={"subheader"}>{subHeader}</span>}
                </div>
                <div className="buttons d-flex">
                    {handleSave && (
                        <CustomButton onClick={handleSave} className="btn btn-save mr-15">
                            {t("SAVE")}
                        </CustomButton>
                    )}
                    {handleDelete && (
                        <CustomButton onClick={handleDelete} className="btn btn-delete">
                            {t("DELETE")}
                        </CustomButton>
                    )}
                    {handleDeactivate && (
                        <CustomButton onClick={handleDeactivate} className="btn btn-delete">
                            {deactivated ? t("ACTIVATE") : t("DEACTIVATE")}
                        </CustomButton>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetailsHeader;
