export const INIT_STORE = "INIT_STORE";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";
export const LOGOUT_USER = "LOGOUT_USER";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const LOGIN_PIN = "LOGIN_PIN";
export const LOCK_APP = "LOCK_APP";
export const UNLOCK_APP = "UNLOCK_APP";
export const GET_ONLINE_USERS = "GET_ONLINE_USERS";
