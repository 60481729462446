import axios from "axios";
import { ServerURL } from "../constants";
import { paginationLimit } from "../constants";

// const token = JSON.parse(localStorage.getItem("token") as any);

const auth = {
    login: (username: string, password: string, role: number | string) => {
        return axios({
            url: `${ServerURL}/login`,
            method: "POST",
            data: {
                username,
                password,
                role,
                limit: paginationLimit,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    logout: (token: string) => {
        return axios({
            url: `${ServerURL}/logout`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    validateToken: (token: string) => {
        return axios({
            url: `${ServerURL}/validateToken`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                limit: paginationLimit,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    pin: (pin: string, token: string) => {
        return axios({
            url: `${ServerURL}/api`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                pin,
                limit: paginationLimit,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default auth;
