import i18next from "i18next";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, BackIcon, ChevronRight, LockIcon } from "../components/icons";
import { MNEFlag } from "../components/icons/mneFlag";
import { UKFlag } from "../components/icons/ukFlag";
import { SRFlag } from "../components/icons/srFlag";
import logoutUser from "../context/actions/logoutUser";
import { GlobalContext } from "../context/Provider";
import ContextMenu from "../devkit/ContextMenu/ContextMenu";
import { CustomButton } from "../devkit/Form/Form";
import lockApp from "../context/actions/lockApp";
import { useHistory } from "react-router";
import { StorageURL } from "../constants";

const Header = () => {
    const { t } = useTranslation();
    const [lang, setLang] = useState<any>(localStorage.getItem("defaultLang"));
    const [langMenu, setLangMenu] = useState<boolean>(false);
    const [options, setOptions] = useState<boolean>(false);
    const history = useHistory();

    const {
        storeDispatch,
        store: { data },
    } = useContext(GlobalContext);

    const toggleLanguage = (lang: string) => {
        i18next.changeLanguage(lang);
        localStorage.setItem("defaultLang", lang);
        setLang(lang);
        setLangMenu(false);
    };

    const getLang = () => {
        if (lang === "me") {
            return "ME";
        } else if (lang === "en") {
            return "EN";
        } else {
            return "SRB";
        }
    };

    // const logout = async () => {
    //     try {
    //         logoutUser()(storeDispatch);
    //         history.push("/login");
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const togglecontextMenu = () => {
        if (!langMenu) {
            setLangMenu(true);
        }
        return;
    };

    return (
        <div className="layout-header">
            <div
                className="d-flex justify-content-center align-items-center go-back-button"
                onClick={() => history.goBack()}
            >
                <BackIcon width={20} className="back-icon" />
            </div>
            <div className={"headerLogoContainer"}>
                <img
                    className="headerLogo"
                    src={"/montenegro.png"}
                    alt={""}
                />
                <img
                    className="headerLogo"
                    src={"/square_logo.png"}
                    alt={""}
                />
                <img
                    className="headerLogo"
                    src={"/eu.png"}
                    alt={""}
                />
                <img
                    className="headerLogo"
                    src={"/serbia.png"}
                    alt={""}
                />
            </div>
            <div className="header-buttons d-flex">
                <CustomButton
                    onClick={togglecontextMenu}
                    className="btn-header lang-toggle p-r d-flex align-items-center"
                >
                    <span className="mr-5 f-s-16">{getLang()}</span>
                    <ChevronRight width={12} className="chevron-icon" />
                    <ContextMenu visible={langMenu} top={35} left={10} closeMenu={() => setLangMenu(false)}>
                        <div
                            onClick={() => toggleLanguage("me")}
                            className="d-flex justify-content-between align-items-center hover"
                        >
                            <span className="mr-10">{t("langs.MONTENEGRIN")}</span>
                            <MNEFlag width={22} />
                        </div>
                        <div
                            onClick={() => toggleLanguage("en")}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <span className="mr-10">{t("langs.ENGLISH")}</span>
                            <UKFlag width={22} />
                        </div>
                        <div
                            onClick={() => toggleLanguage("sr")}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <span className="mr-10">{t("langs.SERBIAN")}</span>
                            <SRFlag width={22} />
                        </div>
                    </ContextMenu>
                </CustomButton>
                <CustomButton onClick={() => lockApp()(storeDispatch)} className="btn btn-header px-10">
                    <LockIcon width={20} />
                </CustomButton>
                {data?.user ? (
                    <div
                        className="logged-user d-flex align-items-center btn-header pl-20 pr-25"
                        onClick={() => setOptions(!options)}
                    >
                        {data.user.profile_picture ? (
                            <img
                                src={`${StorageURL}${data.user.profile_picture}`}
                                style={{ width: "35px", height: "35px", borderRadius: "50%", marginRight: "20px" }}
                                alt={""}
                            />
                        ) : (
                            <Avatar width={35} className="avatar-icon mr-20" />
                        )}
                        <div className="f-s-15 fullName">
                            {data.user.first_name} {data.user.last_name}
                        </div>
                    </div>
                ) : (
                    <div className="logged-user-shell">
                        <div />
                        <div />
                    </div>
                )}
                <ContextMenu
                    visible={options}
                    top={45}
                    left={10}
                    closeMenu={() => setOptions(false)}
                    className="logged-user-ctx"
                >
                    <div onClick={() => logoutUser()(storeDispatch)}>{t("LOGOUT")}</div>
                    <div
                        onClick={() => {
                            history.push("/my-profile");
                            setOptions(false);
                        }}
                    >
                        {t("MY_PROFILE")}
                    </div>
                </ContextMenu>
            </div>
        </div>
    );
};

export default Header;
