import React from "react";
import { CustomButton } from "../devkit/Form/Form";
import { CloseIconCircle } from "./icons";
import { UtilService } from "../services/UtilService";
import FileUpload from "./FileUpload";
import { useTranslation } from "react-i18next";
import { StorageURL } from "../constants";

interface IFileList {
    handleFileChange: any;
    toggleModal: (name: string) => void;
    activeModal: string;
    deleteFile: (id: number) => void;
    pendingFiles: File[];
    files: any[];
    deletePendingFile: (index: number) => void;
    canEdit?: boolean;
    downloadFile: () => void;
}

const FilesList = ({
    handleFileChange,
    toggleModal,
    activeModal,
    deleteFile,
    pendingFiles,
    files,
    deletePendingFile,
    canEdit,
    downloadFile,
}: IFileList) => {
    const { t } = useTranslation();
    return (
        <div className="report-files">
            <div className="d-flex justify-content-between">
                <h2>{t("FILES")}</h2>
                {canEdit && (
                    <CustomButton onClick={() => toggleModal("fileUpload")} className="btn btn-upload" type="button">
                        <span>{t("UPLOAD_FILE")}</span>
                    </CustomButton>
                )}
            </div>
            {!files.length && !pendingFiles.length ? (
                <div className="empty-message">{t("NO_FILES")}</div>
            ) : (
                <div className="pending-file-list">
                    {files.map((file: any) => {
                        return (
                            <div className={`d-flex flex-column align-items-center file ${!canEdit && "preview"}`}>
                                {canEdit && (
                                    <CloseIconCircle
                                        width={18}
                                        className="file-remove-icon"
                                        onClick={() => deleteFile(file.id)}
                                    />
                                )}
                                <a href={`${StorageURL}${file.path}`} download className="download-btn">
                                    {t("DOWNLOAD")}
                                </a>

                                <span>{UtilService.handleIcon(UtilService.getFileType(file), 50)}</span>
                                <span>{UtilService.getFilenameFromPath(file.path)}</span>
                            </div>
                        );
                    })}
                    {pendingFiles.map((file: any, index: any) => {
                        return (
                            <div className="d-flex flex-column align-items-center file">
                                <CloseIconCircle
                                    width={17}
                                    className="file-remove-icon"
                                    onClick={() => deletePendingFile(index)}
                                />
                                <a href={file.path} download className="download-btn">
                                    {t("DOWNLOAD")}
                                </a>
                                <span>{UtilService.handleIcon(UtilService.getFileType(file), 50)}</span>
                                <span>{file.name}</span>
                            </div>
                        );
                    })}
                </div>
            )}
            <FileUpload
                handleFileChange={handleFileChange}
                toggleModal={toggleModal}
                isActive={activeModal === "fileUpload"}
            />
        </div>
    );
};

export default FilesList;
