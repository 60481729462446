import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import me from "./translations/me.json";
import sr from "./translations/sr.json";

const resources = {
    en: {
        translation: en,
    },
    me: {
        translation: me,
    },
    sr: {
        translation: sr,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: ".",
});

export default i18n;
