import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../devkit/Form/Form";
import { CloseIconCircle } from "./icons";

interface IProps {
    opened: boolean;
    onClose: () => void;
    sidebarClasses?: string;
    wrapperClasses?: string;
    titleMargin?: string;
    submitFilters: (e: React.MouseEvent<HTMLButtonElement>) => void;
    resetFilters: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children: JSX.Element | JSX.Element[];
    forceReRender?: boolean;
    disableButton?: boolean;

}

const FilterSidebar = ({
                           opened,
                           onClose,
                           sidebarClasses,
                           wrapperClasses,
                           titleMargin,
                           submitFilters,
                           resetFilters,
                           children,
                           forceReRender,
                           disableButton
                       }: IProps) => {
    const { t } = useTranslation();

    const [reRender, setRerender] = useState<boolean>(forceReRender ? forceReRender : false);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}
            className={`${wrapperClasses ? wrapperClasses : ""} ${opened ? "open" : ""} filter-sidebar-wrapper`}
        >
            <div
                className={`${sidebarClasses ? sidebarClasses : ""} ${
                    opened ? "open" : ""
                } filter-sidebar d-flex flex-column form-box-shadow`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={`d-flex justify-content-between ${titleMargin ? titleMargin : ""} `}>
                    <h1>{t("FILTERS")}</h1>
                    <div
                        onClick={onClose}
                        className="d-flex align-items-center justify-content-center pointer close-icon"
                    >
                        <CloseIconCircle width={18} />
                    </div>
                </div>
                <div className="main h-100-perc d-flex justify-content-between flex-column">
                    <div className="filterBody mt-20">{children}</div>
                    <div className="mb-30 control-buttons ">
                        <CustomButton
                            className={`w-100-perc ${disableButton ? 'btn-disabled' : 'btn-primary'} color-2 mb-15`}
                            onClick={(e) => submitFilters(e)}
                            disabled={disableButton}
                        >
                            {t("SUBMIT_FILTERS")}
                        </CustomButton>
                        <CustomButton className="w-100-perc btn btn-delete" onClick={(e) => resetFilters(e)}>
                            {t("RESET_FILTERS")}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterSidebar;
