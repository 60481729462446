import "./i18n/i18n";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalProvider from "./context/Provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

let lang = localStorage.getItem("defaultLang");

if (!lang) {
    localStorage.setItem("defaultLang", "me");
    lang = "me";
}

i18next.changeLanguage(lang);

ReactDOM.render(
    <React.StrictMode>
        <GlobalProvider>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                className={"f-s-15"}
            />
            <App />
        </GlobalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
