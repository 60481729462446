interface IFirebaseConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string,
}

export const roles = { BEN: 1, FF: 2, MED: 3, ADM: 4 };

const server = () => {
    let host = window.location.hostname;
    let server = "";
    switch (host) {
        case "telemed.me":
        case "localhost":
            server = "https://api.telemed.me";
            break;
        default:
            break;
    }
    return server;
};
const firebaseConfig = () => {
    let host = window.location.hostname;
    let config: IFirebaseConfig = {
        apiKey: "",
        authDomain: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
    };
    switch (host) {
        case "localhost":
        case "telemed.me":
            config = {
                apiKey: "AIzaSyAyEhNOnfXltvVlBVwmtfK_GUdo2WIZFcA",
                authDomain: "telemed-v3.firebaseapp.com",
                projectId: "telemed-v3",
                storageBucket: "telemed-v3.appspot.com",
                messagingSenderId: "937513222257",
                appId: "1:937513222257:web:5eace80b6c1a65b2e29bd0",
                measurementId: "G-K05QZFP528"
            };
            break;
        default:
            break;
    }
    return config;
};
const SERVER = server();

export const ServerURL = `${SERVER}/api`;
export const StorageURL = `${SERVER}/storage/`;
export const GarminRedirectPath = "garmin-redirect";
export const paginationLimit = 20;
export const roleOptions = [
    { value: 2, label: "FRIENDS_FAMILY" },
    { value: 3, label: "MEDICAL_STAFF" },
    { value: 4, label: "ADMIN" },
];
export const frequencyOptions = [
    { value: 1, label: "ONCE" },
    { value: 2, label: "EVERY_DAY" },
    { value: 3, label: "EVERY_WEEK" },
    { value: 4, label: "EVERY_MONTH" },
    { value: 5, label: "EVERY_YEAR" },
];
export const permissions: { [key: string]: any } = {
    2: {
        users: {
            read: {
                1: true,
                2: false,
                3: true,
            },
            del: false,
            edit: false,
            add: false,
        },
        reports: {
            read: false,
        },
        records: {
            read: true,
            del: false,
            edit: false,
            add: false,
        },
        reminders: {
            read: true,
            del: false,
            edit: true,
            add: true,
        },
    },
    3: {
        users: {
            read: {
                1: true,
                2: true,
                3: false,
            },
            del: false,
            edit: true,
            add: true,
        },
        reports: {
            read: true,
            del: true,
            edit: true,
            add: true,
        },
        records: {
            read: true,
            del: true,
            edit: true,
            add: true,
        },
        reminders: {
            read: true,
            del: true,
            edit: true,
            add: true,
        },
    },
};
export const FirebaseConstants = {
    USER_PROFILES: "profiles",
    FCM_TOKENS: "fcm_tokens",
    MESSAGES: "messages",
    NOTIFICATIONS: "notifications",
    CONFIG: firebaseConfig(),
    EVENT_TYPES: {
        CALL: "call",
        SOS: "sos",
    },
};
