import { LOGIN_FAIL, LOGIN_LOADING, LOGIN_SUCCESS } from "../../constants/actionTypes";
import authApi from "../../api/authAPI";
import { StoreService } from "../../services/StoreService";
import i18n from "i18next";
import { toast } from "react-toastify";
import { usersAPI } from "../../api/usersAPI";
import { FirebaseService } from "../../services/FirebaseService";

const loginUser =
    ({ username, password, role }: { username: string; password: string; role: number }) =>
        (dispatch: (arg0: { type: string; payload?: any }) => void) => {
            dispatch({
                type: LOGIN_LOADING,
            });
            toast.loading(i18n.t("LOADING"), { toastId: "loadingToast" });

            authApi.login(username, password, role).then((response: any) => {
                if (response && response.success) {
                    usersAPI.getOnlineUsers(response.data.token).then((res) => {
                        if (res && res.success) {
                            StoreService.updateStoreProperty("token", response.data.token);
                            StoreService.updateStoreProperty("isLoggedIn", true);
                            StoreService.updateStoreProperty("isPinValid", new Date());
                            StoreService.updateStoreProperty("role", response.data.user.role);
                            StoreService.updateStoreProperty("id", response.data.user.id);
                            StoreService.updateStoreProperty("user", response.data.user);

                            dispatch({
                                type: LOGIN_SUCCESS,
                                payload: { ...response.data, online_users: res.data },
                            });

                            toast.update("loadingToast", {
                                render: i18n.t("LOGIN_SUCCESS"),
                                type: "success",
                                isLoading: false,
                                autoClose: 3000,
                                closeOnClick: true,
                                closeButton: true,
                            });

                            FirebaseService.sendOnlineStatusNotif(true);
                        }
                    });
                } else {
                    // const responseMsg = Array.isArray(response.info) ? response.info[0] : response.info;
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: Array.isArray(response?.message) ? response.message[0] : response?.message,
                    });
                    toast.update("loadingToast", {
                        render: i18n.t("LOGIN_FAILED"),
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        closeOnClick: true,
                        closeButton: true,
                    });
                }
            });
            // .catch((err) => {
            //     dispatch({
            //         type: LOGIN_FAIL,
            //         payload: err.response ? err.response.data : { error: "Something went wrong, try again" },
            //     });
            // });
        };
export default loginUser;
