import React, { ReactChildren, useEffect, useRef } from "react";

interface IContextMenu {
    top: number;
    left: number;
    height?: number;
    visible: boolean;
    children: ReactChildren | string | JSX.Element | Array<JSX.Element>;
    className?: string;
    closeMenu: () => void;
}

const ContextMenu = ({ top, left, height, visible, children, className, closeMenu }: IContextMenu) => {
    // TODO provjeriti kako se tipiziraju refovi
    const ctxRef = useRef<any>();

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ctxRef.current && !ctxRef.current!.contains(e.target)) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ctxRef, closeMenu]);

    return (
        <ul
            className={`contextMenu p-a ${className ? className : ""}`}
            style={{
                top: `${top}px`,
                left: `${left}px`,
                height: `${height ? height + "px" : "auto"}`,
                display: visible ? "block" : "none",
            }}
            onClick={(e) => e.stopPropagation()}
            ref={ctxRef}
        >
            {children}
        </ul>
    );
};

export default ContextMenu;
