import { useState } from "react";

const useModal = () => {
    const [activeModal, setActiveModal] = useState<string>("");

    function toggleModal(name: string) {
        if (activeModal === name) {
            setActiveModal("");
        } else {
            setActiveModal(name);
        }
    }

    return { toggleModal, activeModal };
};

export default useModal;
