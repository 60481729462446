import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CloseIconCircle } from "../../components/icons";
import { UtilService } from "../../services/UtilService";

interface IExpandableTableCell {
    description: string;
    id: number;
    closeDescription: (e: any) => void;
    expandDescription: (e: any, id: number) => void;
    ctxStatus: { id: number | null; opened: boolean; style: { top?: string; bottom?: string } };
}

const ExpandableTableCell = ({
    description,
    id,
    closeDescription,
    expandDescription,
    ctxStatus,
}: IExpandableTableCell) => {
    const { t } = useTranslation();

    const ctxRef = useRef<any>();

    // useEffect(() => {
    //     const handleClick = (e: MouseEvent) => {
    //         if (ctxRef.current && !ctxRef.current!.contains(e.target)) {
    //             closeDescription(e);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClick);

    //     return () => {
    //         document.removeEventListener("mousedown", handleClick);
    //     };
    // }, [ctxRef, closeDescription]);

    //TODO srediti klik spolja da zatvara context

    return (
        <td className="max-width-200 expandable-description min-width-150" ref={ctxRef}>
            {UtilService.truncateString(description, 15)}
            <span
                className={`tooltip-button ${ctxStatus.id === id && ctxStatus.opened ? "disabled" : ""}`}
                onClick={(e) => expandDescription(e, id)}
            >
                {t("EXPAND_DESCRIPTION")}
            </span>
            <div
                className={`expanded-description ${ctxStatus.id === id ? "visible" : ""}`}
                style={ctxStatus.style}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <h2>{t("DESCRIPTION")}</h2>
                    <CloseIconCircle width={18} className="close-icon" onClick={closeDescription} />
                </div>

                <p className="">{description}</p>
            </div>
        </td>
    );
};

export default ExpandableTableCell;
