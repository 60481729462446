import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StorageURL } from "../constants";
import { GlobalContext } from "../context/Provider";
import { IUser } from "../models/interfaces/User";
import { Avatar, EditIcon } from "./icons";
import { CallIcon } from "./icons/callIcons";

const OnlineUser = ({
    user,
    callUser,
    onClick,
}: {
    user: IUser;
    callUser: (user: IUser) => void;
    onClick: (user: IUser) => void;
}) => {
    const { t } = useTranslation();
    const {
        store: { data },
    } = useContext(GlobalContext);

    const printRole = () => {
        switch (user.role) {
            case 1:
                return t("BENEFICIARY");
            case 2:
                return t("MEDICAL_STAFF");
            default:
                t("FRIENDS_FAMILY");
        }
    };

    return (
        <div className="dashboard-user d-flex justify-content-between" onClick={() => onClick(user)}>
            <div className="d-flex align-items-center">
                <div>
                    {user.profile_picture && user.profile_picture !== "null" ? (
                        <img className="avatar" src={`${StorageURL}${user.profile_picture}`} />
                    ) : (
                        <Avatar width={50} className="mr-5 mt-5" />
                    )}
                </div>
                <div className="d-flex flex-column">
                    <span className="name">
                        {user.first_name} {user.last_name}
                    </span>
                    <span className="role">{printRole()}</span>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        callUser(user);
                        console.log("user data", user);
                    }}
                >
                    <CallIcon width={27} className="call-icon" color={"green"} />
                </div>

                {data?.user?.role !== 2 && (
                    <div className="pointer ml-15" onClick={() => onClick(user)}>
                        <EditIcon width={27} className="edit-icon" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default OnlineUser;
