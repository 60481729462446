import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { filesAPI } from "../../api/filesAPI";
import medReportsAPI from "../../api/medReportsAPI";
import { usersAPI } from "../../api/usersAPI";
import { CloseIconCircle, PlusIcon } from "../../components/icons";
import MainLoader from "../../components/MainLoader";
import { CustomForm, CustomInput, CustomTextArea } from "../../devkit/Form/Form";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import { parseDate } from "../../functions/utilFunctions/parsingFunctions";
import DetailsHeader from "../../layout/DetailsHeader";
import { IReport } from "../../models/interfaces/MedReport";
import { IUser } from "../../models/interfaces/User";
import useModal from "../../services/CustomHooks/useModal";
import { StoreService } from "../../services/StoreService";
import { UtilService } from "../../services/UtilService";
import FilesList from "../../components/FilesList";
import { GlobalContext } from "../../context/Provider";
import { roles } from "../../constants";

interface IFile {
    id: number;
    path: string;
    created_at: Date | string;
    update_at: Date | string;
}

interface IFormValues {
    title: string;
    description: string;
    created_at: Date | string | null;
    ben_users: IUser[];
    autors: IUser[];
}

const MedReportDetails = () => {
    const role = StoreService.getStoreProperty("role");
    const { hasPermission, store } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { toggleModal, activeModal } = useModal();
    const history = useHistory();
    const { params } = useRouteMatch<{ id: string }>();
    const { t } = useTranslation();
    const [report, setReport] = useState<IReport>();
    const [allBenUsers, setAllBenUsers] = useState<IUser[]>([]);
    const [pendingFiles, setPendingFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<IFile[]>([]);
    const [isNew] = useState<boolean>(!params.id);
    const [loader, setLoader] = useState<boolean>(true);
    const [benUsersError, setBenUsersError] = useState<boolean>(false);
    const [fileToDelete, setFileToDelete] = useState<{ file: number; type: string }>({ file: 0, type: "server" });

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
    } = useForm<IFormValues>({ defaultValues: { title: "", description: "", created_at: null, ben_users: [] } });
    const { ben_users } = watch();
    const getBenUsers = () => {
        const token = StoreService.getStoreProperty("token");
        usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
            if (res && res.success) {
                setAllBenUsers(res.data);
            }
        });
    };

    useEffect(() => {
        const getReport = () => {
            medReportsAPI.get(parseInt(params.id), token).then((res) => {
                if (res && res.success) {
                    setReport(res.data);
                    setValue("ben_users", res.data.ben_users);
                    setValue("title", res.data.title);
                    setValue("description", res.data.description);
                    setValue("created_at", res.data.created_at);
                    setValue("autors", res.data.autors);
                    setFiles(res.data.files);
                    setLoader(false);
                }
            });
        };

        if (!isNew) {
            getReport();
        } else {
            setLoader(false);
        }
    }, [isNew, params.id, setValue, token]);

    useEffect(() => {
        getBenUsers();
    }, [ben_users]);

    const handleSave = () => {
        const data: any = {
            title: getValues("title"),
            description: getValues("description"),
            ben_ids: JSON.stringify(getValues("ben_users")?.map((bu) => bu.id)),
        };
        if (getValues("ben_users").length) {
            if (isNew) {
                medReportsAPI.create(data, token).then((res) => {
                    if (res && res.success) {
                        toast.success(t("REPORT_CREATE_SUCCESS"));

                        if (pendingFiles.length) {
                            uploadFiles(res.data.id);
                        } else {
                            redirectToDetails(res.data.id);
                        }
                    } else {
                        toast.error(t("REPORT_CREATE_FAIL"));
                    }
                });
            } else {
                data["id"] = parseInt(params.id);
                medReportsAPI.update(data, token).then((res) => {
                    if (res && res.success) {
                        toast.success(t("REPORT_UPDATE_SUCCESS"));
                        if (pendingFiles.length) {
                            uploadFiles(res.data.id);
                        } else {
                            redirectToDetails(res.data.id);
                        }
                    } else {
                        toast.error(t("REPORT_UPDATE_FAIL"));
                    }
                });
            }
        } else {
            setBenUsersError(true);
        }
    };

    const uploadFiles = (id: number) => {
        const formData = new FormData();
        for (let i = 0; i < pendingFiles.length; i++) {
            formData.append("files[]", pendingFiles[i]);
        }

        formData.append("id", id.toString());
        formData.append("type", "report");
        // TODO OnLoadProgress
        // const config = (p: any) => {
        //     console.log("e");
        //     const progress = p.loaded / p.total;

        //     if (toastId.current === null) {
        //         toastId.current = toast(t("UPLOAD_IN_PROGRESS"), {
        //             progress: progress,
        //         });
        //     } else {
        //         toast.update(toastId.current, {
        //             progress: progress,
        //         });
        //     }
        // };

        filesAPI.upload(formData, token).then((res) => {
            if (res && res.success) {
                redirectToDetails(id);
            }
        });
    };

    const redirectToDetails = (id: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/${id}`);
    };

    const handleDelete = () => {
        medReportsAPI.delete(parseInt(params.id), token).then((res) => {
            if (res && res.success) {
                toast.success(t("REPORT_DELETE_SUCCESS"));
                returnToReportList();
            } else {
                toast.error(t("REPORT_DELETE_FAIL"));
            }
        });
    };

    const returnToReportList = () => {
        history.push(`/medical-reports/1`);
    };

    const addBeneficiary = (ben: IUser) => {
        setValue("ben_users", [...getValues("ben_users"), ben]);
        setBenUsersError(false);
    };

    const removeBenUser = (id: number) => {
        setValue(
            "ben_users",
            getValues("ben_users").filter((ben) => {
                return ben.id !== id;
            }),
        );
    };

    const handleFileChange = (name: string, value: any, drop?: boolean) => {
        setPendingFiles((pendingFiles) => [...pendingFiles, ...UtilService.handleAddFile(name, value, drop)]);
        toggleModal("");
    };

    const deletePendingFile = () => {
        setPendingFiles((pendingFiles) => pendingFiles.filter((file, i) => i !== fileToDelete.file));
        toast.success(t("FILE_DELETE_SUCCESS"));
        toggleModal("");
    };

    const deleteFile = () => {
        filesAPI.delete(fileToDelete.file, token).then((res) => {
            if (res && res.success) {
                toast.success(t("FILE_DELETE_SUCCESS"));
                setFiles((files) => files.filter((file) => file.id !== fileToDelete.file));
                toggleModal("");
            } else {
                toast.error(t("FILE_DELETE_FAIL"));
            }
        });
    };

    const checkDeletePermission = () => {
        const { autors } = watch();
        if (hasPermission("reports", "del")) {
            if (role === roles.MED) {
                return autors.findIndex((a) => a.id === store.data.user.id) !== -1;
            }
            return true;
        }
        return false;
    };

    return (
        <div className="med-report-details details-screen">
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <DetailsHeader
                        title={isNew ? (watch("title") ? watch("title") : t("TITLE")) : `${report?.title}`}
                        handleDelete={
                            checkDeletePermission() && !isNew ? () => toggleModal("deleteConfirm") : undefined
                        }
                        handleSave={hasPermission("reports", "edit") ? () => handleSubmit(handleSave)() : undefined}
                    />
                    <CustomForm handleSubmit={handleSubmit(handleSave)} className="mt-80 d-flex flex-column">
                        <section className="d-flex mb-45">
                            <div className="d-flex flex-column">
                                <CustomInput
                                    inputProps={{ ...register("title", { required: "INPUT_REQUIRED" }) }}
                                    placeholder={t("TITLE")}
                                    label={t("TITLE")}
                                    type="text"
                                    className="mb-45"
                                    error={errors.title?.message}
                                    disabled={!hasPermission("reports", "edit")}
                                ></CustomInput>
                                <CustomTextArea
                                    inputProps={{ ...register("description", { required: "INPUT_REQUIRED" }) }}
                                    placeholder={t("DESCRIPTION")}
                                    label={t("DESCRIPTION")}
                                    id={"description"}
                                    error={errors.description?.message}
                                    disabled={!hasPermission("reports", "edit")}
                                ></CustomTextArea>
                                {!isNew && (
                                    <div className="mt-30 created-at">
                                        <span>{t("CREATED_AT")}:</span>
                                        <span>{report && parseDate(report.created_at)}</span>
                                    </div>
                                )}
                            </div>
                            <div>
                                {!isNew && (
                                    <div className="report-authors mb-30">
                                        <h2>{t("AUTHORS")}:</h2>
                                        <div className="name-list">
                                            {report?.autors.map((a) => (
                                                <span>
                                                    {a.first_name} {a.last_name}
                                                    <span>{", "}</span>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className="report-beneficiaries">
                                    <div className="heading">
                                        <h2>{t("BENEFICIARIES")}:</h2>
                                        {hasPermission("reports", "edit") && (
                                            <span onClick={() => toggleModal("addBenUsers")}>
                                                <PlusIcon width={28} className="plus-icon" />
                                            </span>
                                        )}
                                    </div>

                                    <div className="name-list">
                                        {watch("ben_users")?.map((ben) => (
                                            <span>
                                                {ben.first_name} {ben.last_name}
                                                <CloseIconCircle
                                                    width={15}
                                                    className="close-icon"
                                                    onClick={() => removeBenUser(ben.id)}
                                                />
                                                <span>{", "}</span>
                                            </span>
                                        ))}
                                    </div>
                                    {benUsersError && (
                                        <span className="error f-s-12 is-warning danger">{t("INPUT_REQUIRED")}</span>
                                    )}
                                </div>
                            </div>
                        </section>
                    </CustomForm>
                    <section>
                        <FilesList
                            handleFileChange={handleFileChange}
                            toggleModal={toggleModal}
                            activeModal={activeModal}
                            deleteFile={(id) => {
                                setFileToDelete({ file: id, type: "server" });
                                toggleModal("deleteConfirmFile");
                            }}
                            deletePendingFile={(index) => {
                                setFileToDelete({ file: index, type: "pending" });
                                toggleModal("deleteConfirmFile");
                            }}
                            pendingFiles={pendingFiles}
                            files={files}
                            canEdit={hasPermission("reports", "edit")}
                            downloadFile={() => {
                            }}
                        />
                    </section>
                </>
            )}

            <Modal
                name="addBenUsers"
                toggle={toggleModal}
                className={activeModal === "addBenUsers" ? "visible" : ""}
                wrapperClasses="add-ben-users-modal"
            >
                <div className="add-ben-users">
                    <h2>{t("ADD_BENEFICIARIES")}</h2>
                    <ul>
                        {allBenUsers
                            .filter((ben: IUser) => watch("ben_users")?.findIndex((user) => ben.id === user.id) === -1)
                            .map((b) => {
                                return (
                                    <li>
                                        <span>
                                            {b.first_name} {b.last_name}
                                        </span>
                                        <span onClick={() => addBeneficiary(b)}>
                                            <PlusIcon width={20} className="modal-plus-icon" />
                                        </span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </Modal>

            <Modal
                name="deleteConfirm"
                toggle={toggleModal}
                className={activeModal === "deleteConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirm"
                    toggle={toggleModal}
                    text="CONFIRM"
                    type="warning"
                    title="REPORT_DELETE"
                    deleteItem={handleDelete}
                ></ModalContent>
            </Modal>
            <Modal
                name="deleteConfirmFile"
                toggle={toggleModal}
                className={activeModal === "deleteConfirmFile" ? "visible" : ""}
            >
                <ModalContent
                    name="deleteConfirmFile"
                    toggle={toggleModal}
                    text="FILE_DELETE_CONFIRM"
                    type="warning"
                    title="CONFIRM"
                    deleteItem={fileToDelete.type === "server" ? deleteFile : deletePendingFile}
                ></ModalContent>
            </Modal>
        </div>
    );
};

export default MedReportDetails;
