import axios from "axios";
import { ServerURL } from "../constants";

const url = `${ServerURL}/files`;

export const filesAPI = {
    upload: (formData: FormData, token: string) => {
        return axios({
            url: `${url}/uploadFiles`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}`, "Content-type": "multipart/form-data" },
            data: formData,
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    delete: (id: number, token: string) => {
        return axios({
            url: `${url}/deleteFile`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};
