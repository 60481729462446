import { StoreService } from "../../services/StoreService";
import { GET_ONLINE_USERS } from "../../constants/actionTypes";
import { usersAPI } from "../../api/usersAPI";

const getOnlineUsers = () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const token = StoreService.getStoreProperty("token");

    usersAPI.getOnlineUsers(token).then((res) => {
//console.log(res);
        if (res && res.success) {
            dispatch({
                type: GET_ONLINE_USERS,
                payload: res.data,
            });
        }
    });
};

export default getOnlineUsers;
