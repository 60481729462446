import { IIcon } from ".";

export const SRFlag = ({ className, width, height }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 500 450"
            width={width}
            height={height}
            className={className}
        >
            <path
                fill="#F5F5F5"
                d="M0 311.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 414.896V311.908z"
            ></path>
            <path
                fill="#FF4B55"
                d="M8.828 88.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V97.103a8.828 8.828 0 018.828-8.827z"
            ></path>
            <path fill="#41479B" d="M0 200.09H512V311.9H0z"></path>
            <path
                fill="#FFE15A"
                d="M173.021 200.07L109.462 200.07 106.814 184.18 175.669 184.18z"
            ></path>
            <path
                fill="#FFD350"
                d="M108.8 196.097L109.462 200.07 173.021 200.07 173.683 196.097z"
            ></path>
            <g fill="#FFE15A">
                <path d="M139.92 147.1H142.56799999999998V157.69299999999998H139.92z"></path>
                <path d="M137.27 149.75H145.215V152.398H137.27z"></path>
                <path
                    d="M173.228 189.476a2.648 2.648 0 01-2.49-3.551l5.61-15.481a2.807 2.807 0 00-.18-2.319c-.209-.375-.695-1.035-1.624-1.234-6.839-1.453-20.096-3.897-32.023-3.897h-2.558c-11.927 0-25.184 2.444-32.026 3.9-.926.196-1.414.856-1.622 1.231a2.803 2.803 0 00-.179 2.319l5.608 15.481a2.648 2.648 0 01-1.587 3.393 2.656 2.656 0 01-3.392-1.588l-5.61-15.481a8.104 8.104 0 01.526-6.694c1.097-1.978 2.973-3.38 5.149-3.843 7.047-1.5 20.718-4.017 33.131-4.017h2.558c12.413 0 26.084 2.516 33.128 4.013a7.757 7.757 0 015.152 3.846 8.107 8.107 0 01.527 6.694l-5.611 15.481a2.642 2.642 0 01-2.487 1.747z"></path>
                <path
                    d="M141.251 187.159a2.647 2.647 0 01-2.648-2.648v-21.517a2.647 2.647 0 115.296 0v21.517a2.647 2.647 0 01-2.648 2.648z"></path>
                <path
                    d="M159.559 189.476a2.649 2.649 0 01-2.596-3.186l3.212-15.481c.418-2.017-.236-3.784-.608-4.094-3.672-1.347-11.039-3.722-17.594-3.722h-1.465c-6.554 0-13.921 2.375-17.73 3.791-.235.241-.889 2.007-.472 4.024l3.212 15.481a2.648 2.648 0 01-2.055 3.132c-1.434.277-2.836-.623-3.13-2.056l-3.212-15.481c-.8-3.859.428-8.801 3.808-10.063 5.063-1.882 12.565-4.125 19.578-4.125h1.465c7.014 0 14.516 2.242 19.576 4.122 3.383 1.265 4.611 6.207 3.811 10.066l-3.212 15.481a2.644 2.644 0 01-2.588 2.111z"></path>
            </g>
            <path
                fill="#FFD350"
                d="M107.476 188.152L175.007 188.152 175.669 184.18 106.814 184.18z"
            ></path>
            <path
                fill="#FF4B55"
                d="M90.924 200.07a2.649 2.649 0 00-2.648 2.648v92.486c0 29.365 23.713 53.17 52.966 53.17s52.966-23.805 52.966-53.17v-92.486a2.649 2.649 0 00-2.648-2.648H90.924z"
            ></path>
            <path
                fill="#F5F5F5"
                d="M146.538 287.464l5.297-13.241-10.593 2.648-10.593-2.648 5.297 13.241-13.241 45.021s7.945 5.297 18.538 5.297 18.538-5.297 18.538-5.297l-13.243-45.021z"
            ></path>
            <path
                fill="#FFE15A"
                d="M122.061 298.135L117.388 299.304 127.667 280.803 123.037 278.232 110.552 300.704 98.869 300.704 98.869 306.001 104.559 306.001 97.215 311.876 100.523 316.014 106.762 311.022 104.301 318.404 109.326 320.079 114.163 305.568 123.346 303.272z"
            ></path>
            <path
                fill="#F5F5F5"
                d="M122.703 226.552l-12.089-6.045a5.297 5.297 0 01-2.77-6.022l.684-2.735a3.007 3.007 0 00-5.266-2.607 44.22 44.22 0 00-9.69 27.625v55.991s11.059-2.211 21.048-16.742c1.834-2.668 4.853-4.247 8.072-4.412l-5.286 15.857L128 290.111l9.269-11.03-14.566-52.529z"
            ></path>
            <path
                fill="#FFE15A"
                d="M160.422 298.135L165.095 299.304 154.816 280.803 159.446 278.232 171.93 300.704 183.614 300.704 183.614 306.001 177.923 306.001 185.268 311.876 181.96 316.014 175.721 311.022 178.182 318.404 173.156 320.079 168.32 305.568 159.137 303.272z"
            ></path>
            <path
                fill="#F5F5F5"
                d="M159.779 226.552l12.089-6.045a5.297 5.297 0 002.77-6.022l-.684-2.735a3.007 3.007 0 015.266-2.607 44.22 44.22 0 019.69 27.625v55.991s-11.059-2.211-21.048-16.742c-1.834-2.668-4.853-4.247-8.072-4.412l5.286 15.857-10.593 2.648-9.269-11.03 14.565-52.528z"
            ></path>
            <g fill="#FFE15A">
                <path
                    d="M125.352 208.014L112.11 208.014 116.083 213.311 125.352 215.959 120.055 218.607 128 218.607z"></path>
                <path
                    d="M157.131 208.014L170.372 208.014 166.4 213.311 157.131 215.959 162.428 218.607 154.483 218.607z"></path>
            </g>
            <path
                fill="#F5F5F5"
                d="M158.996 205.938l-12.458 2.076-5.297 10.593-5.297-10.593-12.458-2.076c-1.428-.238-2.524 1.245-1.876 2.54l6.39 12.778-5.297 5.297h37.076l-5.297-5.297 6.389-12.778c.648-1.296-.447-2.779-1.875-2.54z"
            ></path>
            <circle cx="141.24" cy="185.34" r="3.972" fill="#FFBE50"></circle>
            <ellipse
                cx="141.19"
                cy="194.82"
                fill="#41479B"
                rx="3.139"
                ry="2.207"
            ></ellipse>
            <ellipse
                cx="125.52"
                cy="194.82"
                fill="#FF4B55"
                rx="3.139"
                ry="2.207"
            ></ellipse>
            <g fill="#41479B">
                <ellipse cx="109.85" cy="194.82" rx="3.139" ry="2.207"></ellipse>
                <ellipse cx="172.52" cy="194.82" rx="3.139" ry="2.207"></ellipse>
            </g>
            <ellipse
                cx="156.85"
                cy="194.82"
                fill="#FF4B55"
                rx="3.139"
                ry="2.207"
            ></ellipse>
            <g fill="#FFBE50">
                <circle cx="159.65" cy="185.34" r="3.972"></circle>
                <circle cx="174.17" cy="185.34" r="3.972"></circle>
                <circle cx="122.86" cy="185.34" r="3.972"></circle>
                <circle cx="108.33" cy="185.34" r="3.972"></circle>
            </g>
            <path
                fill="#41479B"
                d="M145.803 162.994a5.239 5.239 0 00.735-2.648 5.297 5.297 0 10-10.594 0c0 .97.279 1.867.735 2.648h9.124z"
            ></path>
            <path
                fill="#FF4B55"
                d="M141.241 280.842c-10.953 0-19.862-8.909-19.862-19.862v-35.752h39.724v35.752c0 10.954-8.909 19.862-19.862 19.862z"
            ></path>
            <path
                fill="#A54B50"
                d="M159.779 226.552v34.428c0 10.238-8.3 18.538-18.538 18.538s-18.538-8.3-18.538-18.538v-34.428h37.076m2.649-2.648H120.056v37.076c0 11.682 9.504 21.186 21.186 21.186s21.186-9.504 21.186-21.186v-37.076z"
            ></path>
            <g fill="#F5F5F5">
                <path
                    d="M137.269 279.08c1.281.28 2.607.437 3.972.437s2.691-.158 3.972-.437v-52.529h-7.945l.001 52.529z"></path>
                <path d="M122.7 248.4H159.78V256.345H122.7z"></path>
            </g>
        </svg>
    );
};
