import axios from "axios";
import { ServerURL } from "../constants";
import { paginationLimit } from "../constants";

interface GetAllProps {
    offset: number;
    sorter: string;
    sorter_direction: string;
    search: string;
    from: Date | string | null;
    to: Date | string | null;
    human_id?: number;
}

const sosAPI = {
    create: (token: string) => {
        return axios({
            url: `${ServerURL}/sosSignals/createSosSignal`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    edit: (id: string | number, token: string) => {
        return axios({
            url: `${ServerURL}/sosSignals/createSosSignal`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${ServerURL}/sosSignals/getAllSosSignals`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                limit: paginationLimit,
                ...data,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default sosAPI;
