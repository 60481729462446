import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";

interface IPagination {
    totalItemsCount: number;
    onChange: (pageNumber: any) => void;
    activePage: number;
    perPage: number;
    itemsCountPerPage?: number;
    pageRangeDisplayed?: number;
}

const Paginate = ({ totalItemsCount, onChange, activePage, perPage, pageRangeDisplayed }: IPagination) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex w-100-perc pagination justify-content-center pt-30">
            <div className="d-flex w-40-perc justify-content-center">
                <Pagination
                    totalItemsCount={totalItemsCount}
                    onChange={onChange}
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    pageRangeDisplayed={pageRangeDisplayed}
                    prevPageText={t("PREVIOUS_PAGE")}
                    nextPageText={t("NEXT_PAGE")}
                    activeClass="activePage"
                    itemClassPrev="prevPage"
                    itemClassNext="nextPage"
                    itemClassFirst="firstPage"
                    itemClassLast="lastPage"
                />
            </div>
        </div>
    );
};

export default Paginate;
