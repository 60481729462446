import React, { ReactChildren, useCallback, useEffect, useState } from "react";
import { ShowMoreIcon } from "../../components/icons";
interface IShowMore {
    children: ReactChildren | string | JSX.Element | Array<JSX.Element> | undefined;
    className?: string;
    multiple?: boolean;
    setStatus?: (id: number) => void;
    id?: number;
    status?: boolean;
}
const ShowMore = ({ className, children, multiple, setStatus, id, status }: IShowMore) => {
    const [visible, setVisible] = useState(false);

    const closeOnOuterClick = useCallback(() => {
        if (multiple) {
            if (status) {
                setStatus!(id!);
            }
        } else if (visible) {
            setVisible(false);
        }
    }, [visible, status, id, setStatus, multiple]);

    useEffect(() => {
        document.addEventListener("click", closeOnOuterClick);
        return () => {
            document.removeEventListener("click", closeOnOuterClick);
        };
    }, [closeOnOuterClick]);

    const toggleVisible = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (multiple) {
            setStatus!(id!);
        } else {
            setVisible(!visible);
        }
    };
    return (
        <div
            className={`pointer d-flex align-items-center justify-content-center showMore ${
                className ? className : ""
            }`}
            onClick={toggleVisible}
        >
            <ShowMoreIcon width={30} className="showMoreIcon" />
            <div className={`showMoreItems ${visible ? "visible" : ""} ${status ? "visible" : ""}`}>{children}</div>
        </div>
    );
};
export default ShowMore;
