import React, { useContext, useEffect, useRef, useState } from "react";
import "./App.css";
import "./assets/scss/main.scss";
import Login from "./pages/Login/Login";
import MainLayout from "./layout/MainLayout";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { StoreService } from "./services/StoreService";
import Users from "./pages/Users/Users";
import { GlobalContext } from "./context/Provider";
import Pin from "./pages/Pin/Pin";
import { UtilService } from "./services/UtilService";
import Dashboard from "./pages/Dashboard/Dashboard";
import validateToken from "./context/actions/validateToken";
import lockApp from "./context/actions/lockApp";
import unlockApp from "./context/actions/unlockApp";
import MedicalRecords from "./pages/MedicalRecords/MedicalRecords";
import MedicalRecordDetails from "./pages/MedicalRecords/MedicalRecordDetails";
import MedicalReports from "./pages/MedicalReports/MedicalReports";
import MedReportDetails from "./pages/MedReportDetails/MedicalReportDetails";
import SOS from "./pages/SOS/SOS";
import Reminders from "./pages/Reminders/Reminders";
import ReminderDetails from "./pages/ReminderDetails/ReminderDetails";
import VideoCalls from "./pages/VideoCalls/VideoCalls";
import UserDetails from "./pages/UserDetails/UserDetails";
import GarminRedirect from "./pages/UserDetails/GarminRedirect";
import { FirebaseService } from "./services/FirebaseService";
import VideoCallModal from "./components/VideoCallModal";
import { usersAPI } from "./api/usersAPI";
import getOnlineUsers from "./context/actions/getOnlineUsers";
import { INotification } from "./models/interfaces/Notification";
import { handleDebouncing } from "./functions/utilFunctions/debounceAndThrottle";
import { IUser } from "./models/interfaces/User";
import videoCallsAPI from "./api/videoCallsAPI";
import SOSModal from "./components/SOSModal";
import { LOGOUT_USER } from "./constants/actionTypes";
import { GarminRedirectPath } from "./constants";
import BiometricDashboard from "./pages/Biometric/BiometricDashboard";
import axios from "axios";
import authAPI from "./api/authAPI";
import i18next from "i18next";

// TODO Vracanje vremena na datetime kad se vrati s back-a

export enum CallStates {
    NONE,
    CALLING,
    PENDING,
    ACTIVE,
    REJECTED,
    MISSED,
}

interface ICallParticipant {
    name: string;
    profilePictureUrl: string | null;
    firebaseId: string | null;
}

export interface IWebViewData {
    token: string;
    ben_id: string;
    language: string;
}

const App = () => {
    const { store, storeDispatch } = useContext(GlobalContext);
    const [videoCallModal, setVideoCallModal] = useState<boolean>(false);
    const [callStatus, setCallStatus] = useState<CallStates>(CallStates.NONE);
    const [inCall, setInCall] = useState(false);
    const [channelName, setChannelName] = useState("");
    const [callParticipant, setCallParticipant] = useState<ICallParticipant>({
        name: "",
        profilePictureUrl: "",
        firebaseId: "",
    });
    const [callNotificationId, setCallNotificationId] = useState<string>("");
    const [callId, setCallId] = useState<number>(0);
    const [sosModal, setSosModal] = useState<boolean>(false);
    const [sosUser, setSosUser] = useState<IUser | undefined>();
    const [sosNotificationId, setSosNotificationId] = useState<string>("");
    const user = StoreService.getStoreProperty("user");
    const [webViewData, setWebViewData] = useState<IWebViewData | null>(null);


    const callRef = useRef<{ modal: boolean; callNotifId: string; status: CallStates; callId: number }>({
        modal: false,
        callNotifId: "",
        status: CallStates.NONE,
        callId: 0,
    });
    callRef.current = { modal: videoCallModal, callNotifId: callNotificationId, status: callStatus, callId: callId };

    // Ref je tu zbog stale closure koja se javi kad proslijedimo funkciju iz komponenente u FirebaseService

    useEffect(() => {
        if (StoreService.getStoreProperty("isLoggedIn") && StoreService.getStoreProperty("isPinValid")) {
            validateToken()(storeDispatch);
        }
        StoreService.getStoreProperty("isPinValid") && unlockApp()(storeDispatch);
    }, []);

    useEffect(() => {
        const handleUnOnline = (arr: number[]) => {
            arr.forEach((user) => {
                usersAPI.update({ id: user, is_online: 0 }, StoreService.getStoreProperty("token"));
            });
            // funkcija za micanje online statusa za usere za koje je ostao true prilikom zatvaranja taba
        };
    }, []);

    // PIN logic
    const handleThrottledEvent = () => {
        const isLoggedIn = StoreService.getStoreProperty("isLoggedIn");
        if (isLoggedIn) {
            UtilService.handleThrottle(
                "allEvents",
                () => {
                    //console.log("afk throttle");
                    if (checkIfPinValid()) {
                        StoreService.updateStoreProperty("isPinValid", new Date());
                    } else {
                        if (StoreService.getStoreProperty("isPinValid")) {
                            lockApp()(storeDispatch);
                        }
                    }
                },
                10000,
            );
        }
    };
    useEffect(() => {
        // **** web view biometrics start ****

        let params = new URL(window.location.href).searchParams;
        if (params.has("token")) {
            let token = params.get("token");
            token && authAPI.validateToken(token).then(
                (response) => {
                    if (response && response.success) {
                        // validateToken()(storeDispatch);
                        token && setWebViewData({
                            token: token,
                            ben_id: params.get("ben_id") ?? "0",
                            language: params.get("language") ?? "me",
                        });
                        i18next.changeLanguage(params.get("language") ?? "me");

                    }
                },
            );
            // console.log("Token: ", token);
            // params.has("role") && console.log("Role: ", params.get("role"));
            // params.has("language") && console.log("Language: ", params.get("language"));
            // params.has("ben_id") && console.log("Ben ID: ", params.get("ben_id"));
        }

        // **** web view biometrics end ****

        const listenToAllUserEvents = () => {
            Object.keys(window).forEach((key) => {
                if (/^on(key|mouse)/.test(key)) {
                    window.addEventListener(key.slice(2), handleThrottledEvent);
                }
            });
        };

        const checkIfLogged = () => {
            if (!checkIfPinValid() && StoreService.getStoreProperty("isLoggedIn")) {
                //console.log("!checkIfPinValid()");
                lockApp()(storeDispatch);
            }
        };

        listenToAllUserEvents();
        checkIfLogged();
    }, []);

    const checkIfPinValid = () => {
        const pin = StoreService.getStoreProperty("isPinValid");
        if (!pin) {
            return false;
        }
        const pinTime: any = new Date(pin);
        const currTime: any = new Date();
        const difMS = currTime - pinTime;
        const diffMins = Math.round(((difMS % 86400000) % 3600000) / 60000);
        return diffMins <= 10;
    };

    const handleFirebaseNotifications = (notifications: INotification[]) => {
        // Ovo ispod je samo za brisanje notifikacija  tokom developmenta da se ne bi palio poziv svaki cas
        // notifications.forEach((not) => FirebaseService.deleteNotification(not.id));
        console.log("*** HANDLE NEW NOTIFICATIONS ***", notifications);

        const callNotifications = notifications.filter((n) => n.action === "call");

        if (
            (!callNotifications.length && callRef.current.callNotifId) ||
            (callNotifications.length &&
                callNotifications.find((n) => n.id === callRef.current.callNotifId) &&
                callRef.current.status !== CallStates.ACTIVE)
        ) {
            setVideoCallModal(false);
            setInCall(false);
            setChannelName("");
        }

        if (notifications.length) {
            notifications.forEach((notification: any) => {
                switch (notification.action) {
                    case "call":
                        if (
                            callRef.current.modal &&
                            callNotificationId &&
                            notification.id !== callRef.current.callNotifId
                        ) {
                            FirebaseService.deleteNotification(notification.id);

                            /*console.log(
    "2. Slucaj kada je modal ukljucen i kad brisemo notifikaciju jer smo vec u nekom pozivu/primamo poziv",
    notification.id,
    callNotificationId
);*/
                        } else if (notification.users[0] === user.notification_id && !callRef.current.modal) {
                            //console.log("3. Slucaj kada nema aktivnog poziva i pravimo jedan");
                            triggerIncomingCall(notification.id, notification.item);
                            setCallParticipant({
                                name: notification.user,
                                profilePictureUrl: notification.profilePictureUrl,
                                firebaseId: notification.id,
                            });
                        }
                        break;
                    case "online":
                        handleDebouncing(
                            "get-online-users",
                            () => {
                                getOnlineUsers()(storeDispatch);
                            },
                            500,
                        );
                        break;
                    case "sos":
                        setSosModal(true);
                        setSosNotificationId(notification.id);
                        setSosUser(notification.item);
                        //console.log("SOS NOTIFICATION", notification);
                        break;
                }
            });
        }
    };

    const handleDeletedNotification = (notificationId: string | number) => {
        //console.log("deletedNotification", notificationId);
    };

    useEffect(() => {
        const handleFirebase = async () => {
            if (!globalWindow["firebaseInitialized"]) {
                await FirebaseService.initializeFirebase();
            }
            FirebaseService.authUser(handleDeletedNotification, handleFirebaseNotifications);
        };
        const globalWindow: any = window;

        if (!globalWindow) return;

        if (store && store.isLoggedIn && store.isPinValid) {
            handleFirebase();
        }
    }, [store.isLoggedIn]);

    const toggleVideoCallModal = () => {
        setVideoCallModal(!videoCallModal);
    };

    const changeCallStatus = (status: CallStates) => {
        setCallStatus(status);
    };

    const answerCall = () => {
        const token = StoreService.getStoreProperty("token");

        videoCallsAPI.edit(callId, 2, token).then((res) => setCallId(0));

        setCallStatus(CallStates.ACTIVE);
        setInCall(true);
    };

    // useEffect(() => {
    //     if (callStatus === CallStates.ACTIVE) {
    //         FirebaseService.deleteNotification(callNotificationId);
    //     }
    // }, [callStatus]);

    const endCall = (deleteNotification = true) => {
        deleteNotification && FirebaseService.deleteNotification(callNotificationId);

        // setInCall(false);
        setChannelName("");
        setCallStatus(CallStates.NONE);
        toggleVideoCallModal();
        setCallNotificationId("");
    };

    const triggerIncomingCall = (notificationId: string, channelName: string) => {
        setCallNotificationId(notificationId);
        setChannelName(channelName);
        setCallStatus(CallStates.PENDING);
        toggleVideoCallModal();
    };

    const callUser = async (user: IUser) => {
        const channel = user.id + "-" + user.role;
        const token = StoreService.getStoreProperty("token");
        console.log("Calling user - ", user.notification_id);
        FirebaseService.sendNotification("call", channel, [user.notification_id]).then((res) => {
            console.log(res);
            videoCallsAPI.create(user.id, token).then((res) => {
                if (res && res.success) {
                    setCallId(res.data.id);
                }
            });
            setCallNotificationId(res.id);
            setCallParticipant({
                name: `${user.first_name} ${user.last_name}`,
                profilePictureUrl: user.profile_picture,
                firebaseId: user.notification_id,
            });
            setVideoCallModal(true);
            setInCall(true);
            setCallStatus(CallStates.CALLING);
            setChannelName(channel);
        });
    };

    const renderVideoCallModal = () => {
        if (videoCallModal) {
            return (
                <VideoCallModal
                    callStatus={callStatus}
                    toggle={toggleVideoCallModal}
                    active={videoCallModal}
                    endCall={endCall}
                    onAnswer={answerCall}
                    inCall={inCall}
                    setInCall={setInCall}
                    channelName={inCall && channelName ? channelName : ""}
                    changeCallStatus={changeCallStatus}
                    callParticipant={callParticipant}
                />
            );
        }

        return <div />;
    };

    const confirmSOS = () => {
        FirebaseService.deleteNotification(sosNotificationId);
        setSosModal(false);
    };

    const renderSosModal = () => {
        if (sosModal) {
            return (
                <SOSModal
                    toggle={() => setSosModal(!sosModal)}
                    active={sosModal}
                    sosUser={sosUser}
                    confirmSOS={confirmSOS}
                />
            );
        }

        return <div />;
    };

    useEffect(() => {
        const token = StoreService.getStoreProperty("token");

        if (callStatus === CallStates.CALLING && callNotificationId) {
            let timer = setTimeout(() => {
                if (callStatus === CallStates.CALLING) {
                    videoCallsAPI.edit(callRef.current.callId, 3, token).then((res) => setCallId(0));
                    FirebaseService.deleteNotification(callNotificationId);
                    setCallStatus(CallStates.NONE);
                    setInCall(false);
                    setVideoCallModal(false);
                    setCallNotificationId("");
                    setChannelName("");
                }
            }, 300000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [callStatus, callNotificationId]);

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            //place your reentry code
            console.log("axios 401 interceptor");
            StoreService.updateStoreProperty("isLoggedIn", false);
            StoreService.updateStoreProperty("role", "");
            StoreService.updateStoreProperty("id", "");
            StoreService.updateStoreProperty("isPinValid", false);
            StoreService.updateStoreProperty("user", {});
            StoreService.updateStoreProperty("token", "");

            storeDispatch({
                type: LOGOUT_USER,
            });
        }
        return error;
    });

    return (
        <Router>
            {
                store.isLoggedIn ? (
                        store.isPinValid ? (
                            <MainLayout>
                                {renderVideoCallModal()}
                                {renderSosModal()}
                                <Switch>
                                    <Route exact path="/">
                                        <Dashboard callUser={callUser} />
                                    </Route>
                                    <Route path="/users">
                                        <Switch>
                                            <Route exact path="/users/beneficiaries/:page" key="ben">
                                                <Users callUser={callUser} type={1} />
                                            </Route>
                                            <Route exact path="/users/medical-staff/:page" key="med">
                                                <Users callUser={callUser} type={3} />
                                            </Route>
                                            <Route exact path="/users/friends-and-family/:page" key="fandf">
                                                <Users callUser={callUser} type={2} />
                                            </Route>
                                            <Route exact path="/users/:type/user/new-user" key="new-entry">
                                                <UserDetails />
                                            </Route>
                                            <Route exact path="/users/:type/user/:id">
                                                <UserDetails />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path={"/medical-records"}>
                                        <Switch>
                                            <Route exact path="/medical-records/:page">
                                                <MedicalRecords />
                                            </Route>
                                            <Route exact path="/medical-records/record/new-entry">
                                                <MedicalRecordDetails key={"new-entry"} />
                                            </Route>
                                            <Route exact path="/medical-records/record/:id">
                                                <MedicalRecordDetails />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path={"/biometric"}>
                                        <Switch>
                                            <Route exact path="/biometric">
                                                <BiometricDashboard />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path="/medical-reports">
                                        <Switch>
                                            <Route exact path="/medical-reports/:page">
                                                <MedicalReports />
                                            </Route>

                                            <Route
                                                exact
                                                path={["/medical-reports/report/new-report", "/medical-reports/report/:id"]}
                                            >
                                                <MedReportDetails />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path={"/reminders"}>
                                        <Switch>
                                            <Route exact path="/reminders/:page">
                                                <Reminders />
                                            </Route>
                                            <Route exact path="/reminders/reminder/new-entry">
                                                <ReminderDetails key={"new-reminder-entry"} />
                                            </Route>
                                            <Route exact path="/reminders/reminder/:id">
                                                <ReminderDetails />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path={"/sos"}>
                                        <Switch>
                                            <Route exact path="/sos/:page">
                                                <SOS />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path={"/video-calls"}>
                                        <Switch>
                                            <Route exact path="/video-calls/:page">
                                                <VideoCalls />
                                            </Route>
                                        </Switch>
                                    </Route>
                                    <Route path="/my-profile">
                                        <UserDetails myProfile key="my-profile" />
                                    </Route>
                                    <Route path={`/${GarminRedirectPath}`}>
                                        <GarminRedirect />
                                    </Route>
                                    <Redirect from="/*" to="/" />
                                </Switch>
                            </MainLayout>
                        ) : (
                            // TODO Find better way to handle rerouting on reload or logout, remove path change from logout
                            // <Switch>
                            //     <Route>
                            <Pin />
                        )
                    ) :
                    webViewData ? (
                        // </Route> :
                        //     <Redirect from="/*" to="/pin" />
                        // </Switch>
                        // :
                        // <>
                        //     <Route path ="/login">
                        <div className={"webViewDashboardContainer"}>
                            <BiometricDashboard webViewData={webViewData} />
                        </div>
                    ) : (

                        <Login />
                    )
                // </Route>
                // <Redirect from="/*" to="/login" />
                // </>
            }
        </Router>
    );
};

export default App;
