import axios from "axios";
import { ServerURL } from "../constants";
import { paginationLimit } from "../constants";

interface CreateReport {
    title: string;
    description: string;
    ben_ids: string;
}

export interface UpdateReport extends CreateReport {
    id: number;
}

interface GetAllProps {
    offset: number;
    sorter: string;
    sorter_direction: string;
    search: string;
    search__people: string;
    from: Date | string | null;
    to: Date | string | null;
    human_id?: number;
}

const url = `${ServerURL}/reports`;

const medReports = {
    get: (id: number, token: string) => {
        return axios({
            url: `${url}/getReport`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    create: (data: CreateReport, token: string) => {
        return axios({
            url: `${url}/createReport`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    delete: (id: number, token: string) => {
        return axios({
            url: `${url}/deleteReport`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
                id,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAll: (data: GetAllProps, token: string) => {
        return axios({
            url: `${url}/getAllReports`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: { limit: paginationLimit, ...data },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    update: (data: UpdateReport, token: string) => {
        return axios({
            url: `${url}/editReport`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    exportPDF: (data: GetAllProps, token: string) => {
        return axios({
            url: `${url}/getReportsPDF`,
            method: "POST",
            data: { limit: paginationLimit, ...data },
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default medReports;
