import React, { useState } from "react";
import { ArrowDownIcon } from "../../components/icons";
import { useTranslation } from "react-i18next";

interface ITableHead {
    value: string;
    sortable?: boolean;
    sortableType?: string;
    sortableName?: any;
}

interface ITable {
    theads: Array<ITableHead>;
    children: JSX.Element[] | string;
    className?: string;
    theadsClassname?: string;
    handleSort?: (sortableName: string, direction: boolean, type: string) => void;
    sortName?: string;
    arrayToSort?: Array<any>;
    tableWrapperClassName?: string;
    emptyMessage?: boolean;
}

const Table = ({
    className,
    theads,
    children,
    theadsClassname,
    handleSort,
    tableWrapperClassName,
    emptyMessage = true,
}: ITable) => {
    const [sort, setSortName] = useState(null);
    const [direction, setDirection] = useState(false);

    const { t } = useTranslation();

    const handleSorting = (sortable: boolean | undefined, sortableName: any, type: any) => {
        if (sortable) {
            if (sortableName !== sort || !sort) {
                setSortName(sortableName);
                setDirection(true);
                if (handleSort) {
                    handleSort(sortableName, false, type);
                }
            } else {
                setDirection((prevState) => !prevState);
                if (handleSort) {
                    handleSort(sortableName, direction, type);
                }
            }
        }
    };

    return (
        <div className={`overflow-auto ${tableWrapperClassName ? tableWrapperClassName : ""}`}>
            <table className={`${className} table`}>
                <thead className="thead">
                    <tr className={`th ${theadsClassname}`}>
                        {theads.map((e, index) => {
                            return (
                                <th
                                    className={`f-s-15 text-left text-color-font-l f-w-4 px-10 ${
                                        theadsClassname ? theadsClassname : ""
                                    } ${e.sortable ? "pointer" : ""}`}
                                    key={index}
                                    onClick={() => handleSorting(e.sortable, e.sortableName, e.sortableType)}
                                >
                                    <div className="wrapper d-flex align-items-center justify-content-between">
                                        {t(e.value)}
                                        {e.sortable ? (
                                            <ArrowDownIcon
                                                width={13}
                                                className={`ml-20 transition-1 ${direction ? "rotate-180" : ""} ${
                                                    e.sortableName === sort ? "opacity-1" : "opacity-0"
                                                }`}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="tbody">{children?.length !== 0 && children}</tbody>
            </table>
            {emptyMessage && !children.length && (
                <div className="p-f center-f-h bottom-40 big-text light-4">{t("NO_DATA")}</div>
            )}
        </div>
    );
};

export default Table;
