import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { StoreService } from "../../services/StoreService";
import { paginationLimit } from "../../constants";
import videoCallsAPI from "../../api/videoCallsAPI";
import PageHeader from "../../layout/PageHeader";
import MainLoader from "../../components/MainLoader";
import Table from "../../devkit/Table/Table";
import { videoCallsTHeads } from "../../constants/tableHeads";
import { parseDateForMySQL, parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import Paginate from "../../devkit/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { CallIcon, MissedCallIcon, RejectedCallIcon } from "../../components/icons/callIcons";
import { CustomDatePicker, CustomDropdown, CustomForm, CustomInput } from "../../devkit/Form/Form";
import FilterSidebar from "../../components/FilterSidebar";
import { useForm } from "react-hook-form";
import { UtilService } from "../../services/UtilService";
import { usersAPI } from "../../api/usersAPI";
import { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import useMount from "../../services/CustomHooks/useMount";
import IVideoCall from "../../models/interfaces/VideoCall";
import { GlobalContext } from "../../context/Provider";
import { toast } from "react-toastify";

interface IFormValues {
    filters: {
        search: string;
        human_id: number | undefined;
        from: Date | null;
        to: Date | null;
    };
}

const defaultValues = {
    filters: {
        search: "",
        human_id: undefined,
        from: null,
        to: null,
    },
};

const VideoCalls = () => {
    const isMount = useMount();
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const history = useHistory();
    const { params } = useRouteMatch<{ page: string }>();
    const [videoCalls, setVideoCalls] = useState<IVideoCall[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [users, setUsers] = useState<IData[]>([]);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "created_at",
        direction: "desc",
    });

    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);

    const resetRef = useRef(false);

    const { watch, register, control, setValue, getValues } = useForm<IFormValues>({ defaultValues: defaultValues });

    const { filters } = watch();

    const {
        store: { data },
    } = useContext(GlobalContext);

    useEffect(() => {
        const getUsers = () => {
            usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    const beneficiaries = res.data;
                    usersAPI.getAll({ limit: 100, offset: 0, role: 2 }, token).then((res) => {
                        if (res && res.success) {
                            setUsers([
                                ...UtilService.getUserOptions(res.data),
                                ...UtilService.getUserOptions(beneficiaries),
                            ]);
                        }
                    });
                }
            });
        };
        getUsers();
        getAll();
    }, [params.page]);

    const getDataForFilters = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        const data: any = {
            offset,
            sorter: sorter.name,
            sorter_direction: sorter.direction,
            search: filters.search,
        };

        if (filters.human_id) {
            data["human_id"] = filters.human_id;
        }
        if (filters.from) {
            data["from"] = parseDateForMySQL(filters.from!);
        }
        if (filters.to) {
            data["to"] = parseDateForMySQL(filters.to!);
        }
        return data;
    };
    const getAll = () => {
        videoCallsAPI.getAll(getDataForFilters(), token).then((res) => {
            if (res && res.success) {
                setVideoCalls(res.data);
                setTotal(res.total);
                setLoader(false);
            }
        });
    };

    const onPageChange = (page: number) => {
        history.push(`/video-calls/${page}`);
    };

    // const setActiveContext = (id: number) => {
    //     if (contextId === id) {
    //         setContextId(0);
    //     } else {
    //         setContextId(id);
    //     }
    // };
    // TODO get status return rejected/answered etc
    const getStatusIcon = (state: number) => {
        switch (state) {
            case 1:
                return <MissedCallIcon width={25} className={"ml-15"} color={"#979797"} />;
            case 2:
                return <CallIcon width={25} className={"ml-15"} color={"#6AA84F"} />;
            case 3:
                return <RejectedCallIcon width={25} className={"ml-15"} color={"#CC0000"} />;
        }
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getAll();
        }
    }, [sorter]);

    const closeSidebar = () => {
        setSidebarOpened(false);
    };

    const resetFilters = () => {
        setValue("filters", { search: "", human_id: undefined, from: null, to: null });
        setReRenderSidebar(!reRenderSidebar);
        resetRef.current = true;
    };

    useEffect(() => {
        if (resetRef.current && !isMount) {
            getAll();
        }
    }, [resetRef.current]);

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleExportPDF = () => {
        videoCallsAPI.exportPDF(getDataForFilters(), token).then(
            (res) => {
                if (res) {
                    let file = new Blob([res]);
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    link.download = "report.pdf";
                    document.body.append(link);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);

                    res && toast.success(t("PDF_EXPORT_SUCCESS"));

                }
            },
        ).catch((e) => {
                toast.error(t("PDF_EXPORT_FAILED"));
                console.log(e);
            },
        );
    };

    return (
        <div className="video-calls">
            <PageHeader
                title={t("VIDEO_CALLS")}
                className="mb-30"
                handleRefresh={handleRefresh}
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
                handleExportPDF={handleExportPDF}
            />
            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                forceReRender={reRenderSidebar}
                submitFilters={getAll}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH"
                            label="SEARCH"
                            inputProps={{ ...register("filters.search") }}
                        />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.from" label={t("FROM")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.to" label={t("TO")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`vid-${getValues("filters.human_id")}-id`}

                        />
                    </div>
                </CustomForm>
            </FilterSidebar>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={videoCallsTHeads} handleSort={handleSorters}>
                        {videoCalls.map((vc: IVideoCall) => {
                            return (
                                <tr onClick={() => {
                                }}>
                                    <td
                                        className={`${data?.user?.id === vc.source_user_id ? "bold" : ""} one-line-text`}
                                    >{`${vc.source_user_first_name} ${vc.source_user_last_name}`}</td>
                                    <td
                                        className={`${data?.user?.id === vc.target_user_id ? "bold" : ""} one-line-text`}
                                    >{`${vc.target_user_first_name} ${vc.target_user_last_name}`}</td>
                                    <td className={"one-line-text"}>{parseDateWithoutSeconds(vc.created_at)}</td>
                                    <td className={"one-line-text"}>{getStatusIcon(vc.state)}</td>
                                </tr>
                            );
                        })}
                    </Table>
                    {videoCalls && videoCalls[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default VideoCalls;
