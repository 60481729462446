import React from "react";
import { PulseLoader } from "react-spinners";

const MainLoader = () => {
    return (
        <div className="main-loader-container">
            <PulseLoader color={"rgb(61, 105, 202)"} loading={true} size={20} />
        </div>
    );
};

export default MainLoader;
