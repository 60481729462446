import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import DashboardReminder from "../../components/DashboardReminder";
import { RefreshIcon } from "../../components/icons";
import { CallIcon, MissedCallIcon, RejectedCallIcon } from "../../components/icons/callIcons";
import MainLoader from "../../components/MainLoader";
import OnlineUser from "../../components/OnlineUser";
import { sosTHeads, videoCallsTHeads } from "../../constants/tableHeads";
import { GlobalContext } from "../../context/Provider";
import { CustomButton } from "../../devkit/Form/Form";
import Table from "../../devkit/Table/Table";
import { parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import { IReminder } from "../../models/interfaces/Reminder";
import ISos from "../../models/interfaces/SOS";
import { IUser } from "../../models/interfaces/User";
import IVideoCall from "../../models/interfaces/VideoCall";

// import { FirebaseService } from "../../services/FirebaseService";

interface Props {
    callUser: (user: IUser) => void;
}

const Dashboard = ({ callUser }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        store: { data },
    } = useContext(GlobalContext);
    const [callLimit, setCallLimit] = useState<number>(4);
    const [sosLimit, setSosLimit] = useState<number>(4);
    const [userLimit, setUserLimit] = useState<number>(4);

    const redirectToDetails = (path: string) => {
        history.push(path);
    };

    const getStatusIcon = (state: number) => {
        switch (state) {
            case 1:
                return <div className={"d-flex"}>
                    <MissedCallIcon width={15}
                                    className={"mr-10"}
                                    color={"#979797"} />
                    <div>{t("MISSED_CALL")}</div>


                </div>;
            case 2:
                return <div className={"d-flex"}>
                    <CallIcon width={15}
                              className={"mr-10"}
                              color={"#6AA84F"} />
                    <div>{t("ANSWERED_CALL")}</div>
                </div>;
            case 3:
                return <div className={"d-flex"}>
                    <RejectedCallIcon width={15}
                                      className={"mr-10"}
                                      color={"#CC0000"} />
                    <div>{t("REJECTED_CALL")}</div>
                </div>;
        }
    };

    const redirectToUser = (user: IUser) => {
        const type = user.role === 1 ? "beneficiaries" : user.role === 2 ? "friends-and-family" : "medical-staff";

        history.push(`/users/${type}/user/${user.id}`);
    };

    const redirectToReminder = (reminder: IReminder) => {
        history.push(`/reminders/reminder/${reminder.id}`);
    };

    const expandList = (section: string) => {
        switch (section) {
            case "calls":
                setCallLimit(callLimit + 4);
                break;
            case "sos":
                setSosLimit(sosLimit + 4);
                break;
            case "users":
                setUserLimit(userLimit + 4);
                break;
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    // const sosTest = async () => {
    //     const newNotif = await FirebaseService.sendNotification("sos", true, ["nrjxhOmkg6aW9nl5qWqfH66bvik1"]);
    //     console.log("NEW SOS", newNotif);
    // };
    //
    // const deleteFirebaseProfile = () => {
    //     FirebaseService.deleteUser();
    // };

    if (!data?.dashboard_data) {
        return <MainLoader />;
    } else {
        return (
            <div className="dashboard d-flex flex-column">
                <section className="d-flex mb-50 justify-content-end">
                    <CustomButton onClick={handleRefresh} className="btn btn-refresh">
                        <RefreshIcon width={15} className="btn-icon mr-5" />
                        <span>{t("REFRESH")}</span>
                    </CustomButton>
                    {/*<CustomButton onClick={sosTest} className="btn btn-refresh ml-50">*/}
                    {/*    Sos test*/}
                    {/*</CustomButton>*/}
                    {/*<CustomButton onClick={deleteFirebaseProfile} className="btn btn-refresh ml-50 width-300">*/}
                    {/*    Delete Firebase profile*/}
                    {/*</CustomButton>*/}
                </section>

                <div className="d-flex mb-50">
                    <div className="dashboard-section mr-50">
                        <div
                            className={`dashboard-heading ${
                                !data.dashboard_data.calls.list.length ? "border-bottom" : ""
                            }`}
                        >
                            {t("VIDEO_CALLS")}
                        </div>
                        {data.dashboard_data.calls.list.length ? (
                            <>
                                <Table
                                    theads={videoCallsTHeads}
                                    tableWrapperClassName="list"
                                    emptyMessage={false}
                                    className="dashboard-table"
                                >
                                    {data.dashboard_data.calls.list.slice(0, callLimit).map((vc: IVideoCall) => {
                                        return (
                                            <tr key={vc.id} onClick={() => redirectToDetails("sdfsd")}>
                                                <td
                                                    className={"one-line-text"}
                                                >{`${vc.source_user_first_name} ${vc.source_user_last_name}`}</td>
                                                <td
                                                    className={"one-line-text"}
                                                >{`${vc.target_user_first_name} ${vc.target_user_last_name}`}</td>
                                                <td className={"one-line-text"}>
                                                    {parseDateWithoutSeconds(vc.created_at)}
                                                </td>
                                                <td className={"one-line-text"}>{getStatusIcon(vc.state)}</td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                                {data.dashboard_data.calls.list.length > 4 && (
                                    <div onClick={() => expandList("calls")} className="showmore-button">
                                        {t("SHOW_MORE_ITEMS")}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="empty-message">{t("NO_DATA")}</div>
                        )}
                    </div>
                    <div className="dashboard-section reminders">
                        <div className="dashboard-heading">{t("REMINDERS")}</div>
                        {data.dashboard_data.reminders.length ? (
                            <>
                                <div className="list">
                                    {data.dashboard_data.reminders.slice(0, 4).map((reminder: IReminder) => (
                                        <DashboardReminder
                                            onClick={redirectToReminder}
                                            reminder={reminder}
                                            key={reminder.id}
                                        />
                                    ))}
                                </div>
                                {data.dashboard_data.reminders.length > 4 && (
                                    <div onClick={() => history.push("/reminders/1")} className="showmore-button">
                                        {t("SHOW_ALL")}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="empty-message">{t("NO_REMINDERS_TODAY")}</div>
                        )}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="dashboard-section mr-50">
                        <div
                            className={`dashboard-heading ${
                                !data.dashboard_data.sos.list.length ? "border-bottom" : ""
                            }`}
                        >
                            {t("SOS")}
                        </div>
                        {data.dashboard_data.sos.list.length ? (
                            <>
                                <Table
                                    theads={sosTHeads}
                                    tableWrapperClassName="list"
                                    emptyMessage={false}
                                    className="dashboard-table"
                                >
                                    {data.dashboard_data.sos.list.slice(0, sosLimit).map((s: ISos) => {
                                        return (
                                            <tr key={s.id}>
                                                <td
                                                    className={"one-line-text"}
                                                >{`${s.source_user_first_name} ${s.source_user_last_name}`}</td>
                                                <td className={"one-line-text"}>
                                                    {s.responder_user_first_name
                                                        ? `${s.responder_user_first_name} ${s.responder_user_last_name}`
                                                        : t("NO_RESPONDER")}
                                                </td>
                                                <td className={"one-line-text"}>
                                                    {parseDateWithoutSeconds(s.created_at)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                                {data.dashboard_data.sos.list.lenght > 4 && (
                                    <div onClick={() => expandList("reminders")} className="showmore-button">
                                        {t("SHOW_MORE_ITEMS")}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="empty-message">{t("NO_DATA")}</div>
                        )}
                    </div>
                    <div className="dashboard-section users">
                        <div className="dashboard-heading">{t("ONLINE_USERS")}</div>

                        {data.online_users?.length ? (
                            <>
                                <div className="list">
                                    {data.online_users.slice(0, userLimit).map((onlineUser: IUser) => (
                                        <OnlineUser
                                            key={onlineUser.id}
                                            callUser={callUser}
                                            onClick={redirectToUser}
                                            user={onlineUser}
                                        />
                                    ))}
                                </div>
                                {data.online_users.length > 4 && (
                                    <div onClick={() => expandList("users")} className="showmore-button">
                                        {t("SHOW_MORE_ITEMS")}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="empty-message">{t("NO_ONLINE_USERS")}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default Dashboard;
