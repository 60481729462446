import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../devkit/Table/Table";
import PageHeader from "../../layout/PageHeader";
import { usersTableHeads } from "../../constants/tableHeads";
import ShowMore from "../../devkit/ShowMore/ShowMore";
import { useHistory, useRouteMatch } from "react-router-dom";
import Paginate from "../../devkit/Pagination/Pagination";
import { paginationLimit } from "../../constants";
import { usersAPI } from "../../api/usersAPI";
import useModal from "../../services/CustomHooks/useModal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import Modal from "../../devkit/Modal/Modal";
import { StoreService } from "../../services/StoreService";
import MainLoader from "../../components/MainLoader";
import { toast } from "react-toastify";
import { GlobalContext } from "../../context/Provider";
import { parseDate } from "../../functions/utilFunctions/parsingFunctions";
import { CustomForm, CustomInput } from "../../devkit/Form/Form";
import { useForm } from "react-hook-form";
import ExpandableTableCell from "../../devkit/Table/ExpandableTableCell";
import useMount from "../../services/CustomHooks/useMount";
import { UtilService } from "../../services/UtilService";
import { IUser } from "../../models/interfaces/User";

interface UsersProps {
    type: number;
    callUser: (user: IUser) => void;
}

const Users = ({ type, callUser }: UsersProps) => {
    // TODO sorteri
    const isMount = useMount();
    const { hasPermission, canCall, store } = useContext(GlobalContext);
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const history = useHistory();
    const { params } = useRouteMatch<{ page: string }>();
    const { activeModal, toggleModal } = useModal();
    const [users, setUsers] = useState<any[]>([]);
    const [contextId, setContextId] = useState<number>();
    const [deactivateId, setDeactivateId] = useState<number>();
    const [total, setTotal] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "is_online",
        direction: "desc",
    });

    const [descContext, setDescContext] = useState<{
        id: number | null;
        opened: boolean;
        style: { bottom?: string; top?: string };
    }>({
        id: null,
        opened: false,
        style: {},
    });

    const { watch, register } = useForm({ defaultValues: { search: "" } });
    const { search } = watch();

    useEffect(() => {
        getAll();
    }, [params.page]);

    const getAll = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        usersAPI
            .getAll(
                {
                    limit: paginationLimit,
                    offset,
                    role: type,
                    sorter: sorter.name,
                    sorter_direction: sorter.direction,
                    search,
                },
                token,
            )
            .then((res) => {
                if (res && res.success) {
                    setUsers(res.data);
                    setTotal(res.total);
                    setLoader(false);
                }
            });
    };

    useEffect(() => {
        getAll();
    }, [store.data?.online_users]);

    const redirectToDetails = (id: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/user/${id}`);
    };

    const handleAdd = () => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + "/user/new-user");
    };

    const onPageChange = (page: number) => {
        const pathname = history.location.pathname.split("/");
        pathname.pop();
        history.push(pathname.join("/") + `/${page}`);
    };

    const setActiveContext = (id: number) => {
        if (contextId === id) {
            setContextId(0);
        } else {
            setContextId(id);
        }
    };

    const handleDeactivate = (id?: number) => {
        usersAPI.toggleActivate(id ? id : deactivateId!, token).then((res) => {
            if (res && res.success) {
                if (id) {
                    toast.success(t("USER_ACTIVATE_SUCCESS"));
                } else {
                    toast.success(t("USER_DEACTIVATE_SUCCESS"));
                }

                getAll();
            } else {
                if (id) {
                    toast.success(t("USER_ACTIVATE_FAIL"));
                } else {
                    toast.error(t("USER_DEACTIVATE_FAIL"));
                }
            }
            toggleModal("");
        });
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getAll();
        }
    }, [sorter]);

    const handleRefresh = () => {
        window.location.reload();
    };

    const expandDescription = (e: any, id: number) => {
        e.stopPropagation();
        let style: any = {};
        if (e.pageY > e.view.innerHeight / 2) {
            style.bottom = "5px";
        } else {
            style.top = "5px";
        }

        setDescContext({ id, opened: true, style });
    };

    const closeDescription = (e: any) => {
        e.stopPropagation();
        setDescContext({ id: null, opened: false, style: {} });
    };

    const getTitle = () => {
        return type === 1 ? t("USERS") : type === 2 ? t("FRIENDS_FAMILY") : t("MEDICAL_WORKERS");
    };

    useEffect(() => {
        if (!isMount) {
            UtilService.handleDebouncing(
                "searchdebounce",
                () => {
                    getAll();
                },
                300,
            );
        }
    }, [search]);

    return (
        <div className="users pb-50">
            <PageHeader
                title={getTitle()}
                handleAdd={hasPermission("users", "add") ? handleAdd : undefined}
                handleRefresh={handleRefresh}
            >
                <CustomForm className="min-w-200 max-w-600">
                    <CustomInput
                        type="text"
                        placeholder="SEARCH"
                        label="SEARCH"
                        inputProps={{ ...register("search") }}
                    />
                </CustomForm>
            </PageHeader>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={usersTableHeads} handleSort={handleSorters}>
                        {users.map((user: any) => {
                            const is_online = !!store.data?.online_users?.find((onlineUser: any) => {
                                return (
                                    onlineUser.notification_id === user.notification_id &&
                                    user.notification_id !== null &&
                                    user.notification_id !== "null"
                                );
                            });
                            return (
                                <tr
                                    onClick={() => redirectToDetails(user.id)}
                                    className={`${user.is_deactivate ? "deactivated" : ""}`}
                                    key={user.id}
                                >
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.birth_date}</td>
                                    <td>{parseDate(user.created_at)}</td>
                                    <ExpandableTableCell
                                        description={user.description}
                                        id={user.id}
                                        closeDescription={closeDescription}
                                        expandDescription={expandDescription}
                                        ctxStatus={descContext}
                                    />
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td className={`${is_online ? "online" : "offline"}`}>
                                        {is_online ? t("ONLINE") : t("OFFLINE")}
                                    </td>
                                    <td>
                                        <ShowMore
                                            multiple
                                            setStatus={() => setActiveContext(user.id)}
                                            status={contextId === user.id}
                                        >
                                            {hasPermission("users", "del") && (
                                                <div
                                                    className="delete"
                                                    onClick={() => {
                                                        if (!user.is_deactivate) {
                                                            toggleModal("deactivateConfirm");
                                                            setDeactivateId(user.id);
                                                        } else {
                                                            handleDeactivate(user.id);
                                                        }
                                                    }}
                                                >
                                                    {user.is_deactivate ? t("ACTIVATE") : t("DEACTIVATE")}
                                                </div>
                                            )}
                                            {canCall(user.notification_id) && (
                                                <div onClick={() => callUser(user)}>{t("VIDEO_CALL")}</div>
                                            )}

                                            <div onClick={() => redirectToDetails(user.id)}>{t("DETAILS")}</div>
                                        </ShowMore>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    {users && users[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}

            <Modal
                name="deactivateConfirm"
                toggle={toggleModal}
                className={activeModal === "deactivateConfirm" ? "visible" : ""}
            >
                <ModalContent
                    name="deactivateConfirm"
                    toggle={toggleModal}
                    text="USER_DEACTIVATE_CONFIRM"
                    type="warning"
                    title="USER_DEACTIVATE"
                    deleteItem={handleDeactivate}
                    buttonText="DEACTIVATE"
                />
            </Modal>
        </div>
    );
};

export default Users;
