import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { paginationLimit } from "../../constants";
import sosAPI from "../../api/sosAPI";
import { StoreService } from "../../services/StoreService";
import PageHeader from "../../layout/PageHeader";
import MainLoader from "../../components/MainLoader";
import Table from "../../devkit/Table/Table";
import { sosTHeads } from "../../constants/tableHeads";
import { parseDateForMySQL, parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import Paginate from "../../devkit/Pagination/Pagination";
import CustomDropdown, { IData } from "../../devkit/Form/CustomDropdown/CustomDropdown";
import { useForm } from "react-hook-form";
import { usersAPI } from "../../api/usersAPI";
import { UtilService } from "../../services/UtilService";
import { CustomDatePicker, CustomForm, CustomInput } from "../../devkit/Form/Form";
import FilterSidebar from "../../components/FilterSidebar";
import useMount from "../../services/CustomHooks/useMount";
import ISos from "../../models/interfaces/SOS";

interface IFormValues {
    filters: {
        search: string;
        human_id: number | undefined;
        from: Date | null;
        to: Date | null;
    };
}

const defaultValues = {
    filters: {
        search: "",
        human_id: undefined,
        from: null,
        to: null,
    },
};

const Sos = () => {
    const isMount = useMount();
    const token = StoreService.getStoreProperty("token");
    const { t } = useTranslation();
    const history = useHistory();
    const { params } = useRouteMatch<{ page: string }>();
    const [sos, setSos] = useState<ISos[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
    const [users, setUsers] = useState<IData[]>([]);
    const [sorter, setSorter] = useState<{ name: string; direction: "asc" | "desc" }>({
        name: "created_at",
        direction: "desc",
    });

    const [reRenderSidebar, setReRenderSidebar] = useState<boolean>(false);

    const resetRef = useRef(false);

    const { watch, register, control, setValue, getValues } = useForm<IFormValues>({ defaultValues: defaultValues });

    const { filters } = watch();

    useEffect(() => {
        const getUsers = () => {
            usersAPI.getAll({ limit: 100, offset: 0, role: 1 }, token).then((res) => {
                if (res && res.success) {
                    setUsers([...UtilService.getUserOptions(res.data)]);
                }
            });
        };
        getUsers();
        getAll();
    }, [params.page]);

    const getAll = () => {
        const offset = (parseInt(params.page) - 1) * paginationLimit;

        const data: any = {
            offset,
            sorter: sorter.name,
            sorter_direction: sorter.direction,
            search: filters.search,
        };

        if (filters.human_id) {
            data["human_id"] = filters.human_id;
        }
        if (filters.from) {
            data["from"] = parseDateForMySQL(filters.from!);
        }
        if (filters.to) {
            data["to"] = parseDateForMySQL(filters.to!);
        }

        sosAPI.getAll(data, token).then((res) => {
            if (res && res.success) {
                setSos(res.data);
                setTotal(res.total);
                setLoader(false);
            }
        });
    };

    const onPageChange = (page: number) => {
        history.push(`/medical-records/${page}`);
    };

    const handleSorters = (name: string, direction: boolean) => {
        setSorter({ name, direction: direction ? "asc" : "desc" });
    };

    useEffect(() => {
        if (!isMount) {
            getAll();
        }
    }, [sorter]);

    const closeSidebar = () => {
        setSidebarOpened(false);
    };

    const resetFilters = () => {
        setValue("filters", { search: "", human_id: undefined, from: null, to: null });
        setReRenderSidebar(!reRenderSidebar);
        resetRef.current = true;
    };

    useEffect(() => {
        if (resetRef.current && !isMount) {
            getAll();
        }
    }, [resetRef.current]);

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="sos">
            <PageHeader
                title={t("SOS")}
                className="mb-30"
                handleSidebar={() => setSidebarOpened(!sidebarOpened)}
                handleRefresh={handleRefresh}
            />
            <FilterSidebar
                opened={sidebarOpened}
                onClose={closeSidebar}
                resetFilters={resetFilters}
                forceReRender={reRenderSidebar}
                submitFilters={getAll}
            >
                <CustomForm className="d-flex align-items-center flex-column">
                    <div className="filter-container">
                        <CustomInput
                            type="text"
                            placeholder="SEARCH"
                            label="SEARCH"
                            inputProps={{ ...register("filters.search") }}
                        />
                    </div>

                    <div className="filter-container">
                        <CustomDatePicker name="filters.from" label={t("FROM")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDatePicker name="filters.to" label={t("TO")} control={control} />
                    </div>
                    <div className="filter-container">
                        <CustomDropdown
                            name="filters.human_id"
                            data={users}
                            label="USERS"
                            control={control}
                            rules={{ required: "INPUT_REQUIRED" }}
                            placeholder="SELECT_USER"
                            key={`sos-${getValues("filters.human_id")}-id`}

                        />
                    </div>
                </CustomForm>
            </FilterSidebar>
            {loader ? (
                <MainLoader />
            ) : (
                <>
                    <Table theads={sosTHeads} handleSort={handleSorters}>
                        {sos.map((s: ISos) => {
                            return (
                                <tr onClick={() => {
                                }}>
                                    <td
                                        className={"one-line-text"}
                                    >{`${s.source_user_first_name} ${s.source_user_last_name}`}</td>
                                    <td className={"one-line-text"}>
                                        {s.responder_user_first_name
                                            ? `${s.responder_user_first_name} ${s.responder_user_last_name}`
                                            : t("NO_RESPONDER")}
                                    </td>
                                    <td className={"one-line-text"}>{parseDateWithoutSeconds(s.created_at)}</td>
                                </tr>
                            );
                        })}
                    </Table>
                    {sos && sos[0] && (
                        <Paginate
                            totalItemsCount={total}
                            onChange={onPageChange}
                            activePage={parseInt(params.page)}
                            perPage={paginationLimit}
                            pageRangeDisplayed={5}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Sos;
