import React, { useContext, useEffect, useState } from "react";
import {StoreService} from "../../services/StoreService";
import {getURLparams} from "../../functions/utilFunctions/parsingFunctions";
import MainLoader from "../../components/MainLoader";
import {GlobalContext} from "../../context/Provider";
import biometricAPI from "../../api/biometricAPI";
import {useHistory} from "react-router";

const GarminRedirect = () => {
  const {
    store: { data },
  } = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    const token = StoreService.getStoreProperty("token");
    const garminToken = StoreService.getStoreProperty("garminToken");
    const garminUserId = StoreService.getStoreProperty("garminUserId");
    const garminSecureToken = StoreService.getStoreProperty("garminSecureToken");
    const urlParams = getURLparams(window?.location?.search.replace('?', ''))

    if (garminToken && garminUserId && garminSecureToken && urlParams?.oauth_verifier) {
      biometricAPI.sendVerifier(token, {
        verifier: urlParams.oauth_verifier,
        id: garminUserId,
        request_token: garminToken,
        request_token_secret: garminSecureToken
      }).then((response) => {
        if (response?.success) {
          StoreService.updateStoreProperty("garminToken", '');
          StoreService.updateStoreProperty("garminUserId", '');
          StoreService.updateStoreProperty("garminSecureToken", '');

          history.push(`/users/beneficiaries/user/${garminUserId}`);
        }
      })

    } else {
      //window.open('/', '_self')
    }
  }, [])

  return (<div className="d-flex w-100perc h-100perc justify-content-center align-content-center">
    <MainLoader />
  </div>);
}

export default GarminRedirect;