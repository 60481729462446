import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { CallStates } from "../App";
import { StorageURL } from "../constants";
import Modal from "../devkit/Modal/Modal";
import { StoreService } from "../services/StoreService";
import { Avatar } from "./icons";
import {
    AnswerCallIcon,
    MicrophoneIcon,
    MuteIcon,
    RejectCallIcon,
    TurnOffVideoIcon,
    CameraIcon,
} from "./icons/callIcons";
import Timer from "./Timer";
import { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { ClientConfig, IAgoraRTCRemoteUser, ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { IUser } from "../models/interfaces/User";

const config: ClientConfig = {
    mode: "rtc",
    codec: "vp8",
};

const appId: string = "3f54d7a332b54de682cfb242ade7ee4d";
const token: string | null = null;

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

interface IVideoCallModal {
    toggle: () => void;
    active: boolean;
    callStatus: CallStates;
    callParticipant?: any;
    onAnswer: () => void;
    endCall: () => void;
    inCall: boolean;
    setInCall: React.Dispatch<React.SetStateAction<boolean>>;
    channelName: string;
    changeCallStatus: (status: CallStates) => void;
}

const VideoCallModal = ({
    toggle,
    active,
    callStatus,
    callParticipant,
    onAnswer,
    endCall,
    inCall,
    setInCall,
    channelName,
    changeCallStatus,
}: IVideoCallModal) => {
    const { t } = useTranslation();
    const { ready, tracks } = useMicrophoneAndCameraTracks();
    const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
    const [start, setStart] = useState<boolean>(false);
    // using the hook to get access to the client object
    const client = useClient();
    // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null

    useEffect(() => {
        if (callStatus === CallStates.PENDING) {
            const alarmTrack = new Audio("/call.mp3");

            alarmTrack.loop = true;

            const playAlarm = () => {
                if (active) {
                    alarmTrack.play();
                }
            };

            playAlarm();

            alarmTrack.addEventListener("ended", playAlarm);

            return () => {
                alarmTrack.removeEventListener("ended", playAlarm);
                alarmTrack.pause();
            };
        }
    }, [active, callStatus]);

    const getClass = () => {
        if (callStatus === CallStates.CALLING) {
            return "calling pending";
        } else if (callStatus === CallStates.PENDING) {
            return "pending";
        } else {
            return "active";
        }
    };

    const leaveChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        // we close the tracks to perform cleanup
        if (tracks && tracks[0]) tracks[0].close();
        if (tracks && tracks[1]) tracks[1].close();
        setStart(false);
        setInCall(false);
        endCall();
    };

    useEffect(() => {
        // Kad se pozivu prikljuci drugi korisnik da se updatuje callState na aktivno
        if (users.length === 2) {
            changeCallStatus(CallStates.ACTIVE);
        }
    }, [users]);

    useEffect(() => {
        // function to initialise the SDK
        let init = async (name: string) => {
            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);

                if (mediaType === "video") {
                    console.log("**AGORA - User published video stream**");
                    setUsers(() => {
                        return [user];
                    });
                }
                if (mediaType === "audio") {
                    console.log("**AGORA - User published audio stream**");
                    user.audioTrack?.play();
                }
            });

            client.on("user-unpublished", (user, type) => {
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
            });

            client.on("user-left", (user) => {
                setUsers([]);
                leaveChannel();
            });

            await client.join(appId, name, token, null);

            if (tracks && tracks[0] && tracks[1]) {
                try {
                    await client.publish(tracks);
                } catch (error) {
                    console.error("!!Agora couldn't publish video and/or video track!!");
                    setUsers([]);
                    leaveChannel();
                }
                //await client.publish([tracks[0]]);
            }

            setStart(true);
        };

        if (ready && tracks && channelName) {
            init(channelName);
        }
    }, [channelName, client, ready, tracks]);

    useEffect(() => {
        if (users.length > 1) {
            changeCallStatus(CallStates.ACTIVE);
        }
    }, [users]);
    //console.log('-------------------- USERS --------------------', users)
    //console.log('-------------------- callParticipant --------------------', callParticipant)
    return (
        <Modal
            toggle={toggle}
            className={`${active ? "visible" : ""}`}
            wrapperClasses={`video-call-modal d-flex justify-content-center flex-column ${getClass()}`}
            name="videoCall"
            preventDefaultClosing
        >
            {callStatus === CallStates.MISSED ? (
                <div>{t("NO_ANSWER")}</div>
            ) : (
                <>
                    {callParticipant && (callStatus === CallStates.PENDING || callStatus === CallStates.CALLING) ? (
                        <UserInfo
                            onAnswer={onAnswer}
                            endCall={leaveChannel}
                            callParticipant={callParticipant}
                            callStatus={callStatus}
                        />
                    ) : (
                        <></>
                    )}
                    {inCall ? (
                        <>
                            {/* Ovdje ide video */}
                            <div className="agora-app">
                                <div className="video-call">
                                    {start && tracks && <Videos users={users} tracks={tracks} />}
                                </div>
                                ;
                            </div>

                            {callStatus === CallStates.ACTIVE && (
                                <div className="timer-container">
                                    <Timer />
                                </div>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {ready && tracks ? (
                        <Controls
                            callStatus={callStatus}
                            endCall={leaveChannel}
                            onAnswer={onAnswer}
                            tracks={tracks}
                            setStart={setStart}
                            setInCall={setInCall}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Modal>
    );
};

export default VideoCallModal;

const Videos = (props: { users: IAgoraRTCRemoteUser[]; tracks: [IMicrophoneAudioTrack, ICameraVideoTrack] }) => {
    const { users, tracks } = props;

    return (
        <div>
            <div id="videos">
                {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
                <AgoraVideoPlayer className="my-vid" videoTrack={tracks[1]} />
                {users &&
                    users[0] &&
                    users[0].videoTrack &&
                    users.map((user: any) => {
                        return (
                            <AgoraVideoPlayer
                                // style={{ height: "95%", width: "95%" }}
                                className="vid remote-video"
                                videoTrack={user.videoTrack}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export const Controls = (props: {
    tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
    setStart: React.Dispatch<React.SetStateAction<boolean>>;
    setInCall: React.Dispatch<React.SetStateAction<boolean>>;
    endCall: () => void;
    onAnswer: () => void;
    callStatus: CallStates;
}) => {
    const { t } = useTranslation();
    const client = useClient();
    const { tracks, setStart, setInCall, endCall } = props;
    const [trackState, setTrackState] = useState({ video: true, audio: true });

    const mute = async (type: "audio" | "video") => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };

    const leaveChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        // we close the tracks to perform cleanup
        tracks[0].close();
        tracks[1].close();
        setStart(false);
        setInCall(false);
    };

    return (
        <div className="controls">
            {props.callStatus !== CallStates.PENDING && (
                <>
                    <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
                        {trackState.audio ? (
                            <MuteIcon width={32} className="control-icon" />
                        ) : (
                            <MicrophoneIcon width={32} className="control-icon" />
                        )}
                    </p>
                    <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
                        {trackState.video ? (
                            <TurnOffVideoIcon width={32} className="control-icon" />
                        ) : (
                            <CameraIcon width={32} className="control-icon" />
                        )}
                    </p>
                </>
            )}
            {props.callStatus === CallStates.PENDING && (
                <p
                    className="answer-call-icon-container d-flex justify-content-center align-items-center"
                    onClick={props.onAnswer}
                >
                    <AnswerCallIcon width={35}></AnswerCallIcon>
                </p>
            )}
            <p
                className="reject-call-icon-container d-flex justify-content-center align-items-center"
                onClick={() => {
                    leaveChannel();
                    endCall();
                }}
            >
                <RejectCallIcon width={35}></RejectCallIcon>
            </p>
        </div>
    );
};

const UserInfo = (props: {
    callParticipant: any;
    callStatus: CallStates;
    onAnswer: () => void;
    endCall: () => void;
}) => {
    const { t } = useTranslation();
    const { callParticipant, callStatus } = props;
    console.log(callParticipant);
    return (
        <div className="user-info">
            <div className="calling-text">{callStatus === CallStates.CALLING ? t("CALLING") : t("CALL_FROM")}</div>
            {callParticipant && callParticipant.profilePictureUrl && callParticipant.profilePictureUrl !== "null" ? (
                <img src={`${StorageURL}${callParticipant.profilePictureUrl}`} alt="" />
            ) : (
                <Avatar className="avatar" width={180} />
            )}

            <div className="caller-name">{callParticipant && callParticipant.name}</div>
            <div className="d-flex align-items-center justify-content-center mt-30">
                <BeatLoader color={"rgb(61, 105, 202)"} loading={true} size={12} />
            </div>
        </div>
    );
};
