import { INIT_STORE } from "../../constants/actionTypes";
import { StoreService } from "../../services/StoreService";

const initStore = () => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
        type: INIT_STORE,
        payload: {
            isPinValid: StoreService.getStoreProperty("isPinValid"),
            isLoggedIn: StoreService.getStoreProperty("isLoggedIn"),
        },
    });
};

export default initStore;
